//-------------------------------------------------------------
// CARD
//-------------------------------------------------------------
// Variables

.card-vc {
  display: block;
  width: 100%;
  border-radius: 8px;
  padding: rem(25px);
  margin-bottom: building-units(2);

  .price__info {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  p:last-child:not(:first-child) {
    margin-bottom: 0;
  }

  &--bg-silkwhite {
    background-color: $white-light;
  }
  &--bg-default,
  &--bg-white {
    background-color: $white;
  }

  &--bg-primary,
  &--bg-iceblue {
    background-color: $blue;
  }

  &-login {
    width: 100%;
  }

  //only for living examples cases
  &--bg-secondary {
    background-color: $red;
    color: $white;

    .link {
      color: $white;

      &:hover {
        color: $purple;
      }
    }
  }

  &--outline {
    border: 1px solid $gray-medium-1;
    box-shadow: none;
  }

  &--shadow {
    box-shadow: 0 5px 24px 0 rgba(0,0,0,0.05);
  }

  &--backgroundimage {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    .card_cf__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    //dimmer
    &:after {
      background-color: $white;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: .6;
    }
  }
}
