//-------------------------------------------------------------
// maps_google
//-------------------------------------------------------------
// Variables

.maps_google {

    &__checkbox_wrapper {
        display: flex;
        flex-flow: row;

        @include media-breakpoint-only(xs) {
            flex-flow: column;
        }
    }

    &__map {
        height: 524px;
        width: 100%;

        @include media-breakpoint-only(xs) {
            height: 280px;
        }

        button.gm-ui-hover-effect {
            right: 25px !important;
            top: 17px !important;

            > img {
                width: building-units(2) !important;
                height: building-units(2) !important;
            }
        }
    }

    &__infowindow {
        min-width: 240px;
        font-size: $font-size-default;
        line-height: $line-height-default;
        font-weight: $font-weight-base;
        color: $black;
        padding: 0 building-units(1);
    }

    .margin-top-15 {

      margin-top: building-units(1.5);
    }

    h4 {

      @include pt-sans-bold-16();

      margin: building-units(1) 0 building-units(1) 0!important;
    }

    .text-default {

      @include pt-sans-regular-14();
    }

    &__card {
        margin-top: building-units(1);
    }
}
