// Fonts
// Vacom
$vacom-font-family-sans-serif: "Inter Tight", sans-serif;
$vacom-font-family-serif: "Inter Tight", sans-serif;
$vacom-font-family-base: $vacom-font-family-sans-serif;


// FONT - pt-sans
@mixin pt-sans() {
    font-family: $vacom-font-family-sans-serif;
    font-weight: $font-weight-normal;
    font-size: 18px;
    font-style: normal;
}

@mixin pt-sans-italic() {
    @include pt-sans();
    font-style: italic;
}

// BOLD
@mixin pt-sans-bold() {
    @include pt-sans();
    font-weight: 600;
}

@mixin pt-sans-bold-italic() {
    @include pt-sans();
    font-weight: 600;
    font-style: italic;
}

// REGULAR
@mixin pt-sans-regular {
    @include pt-sans();
}

// mixins - font-size
@mixin pt-sans-14() {
    font-size: 14px;
    line-height: 21px;
}

@mixin pt-sans-16() {
    font-size: 16px;
    line-height: 24px;
}

@mixin pt-sans-18() {
    font-size: 18px;
    line-height: 26px;
}

// mixins - font + font-size

// regular
@mixin pt-sans-regular-14() {

    @include pt-sans-regular();
    @include pt-sans-14();
}

@mixin pt-sans-regular-16 {

    @include pt-sans-regular();
    @include pt-sans-16();
}

@mixin pt-sans-regular-18 {
    @include pt-sans-regular();
    @include pt-sans-18();
}

// bold
@mixin pt-sans-bold-14 {

    @include pt-sans-bold();
    @include pt-sans-14();
}

@mixin pt-sans-bold-16 {

    @include pt-sans-bold();
    @include pt-sans-16();
}

@mixin pt-sans-bold-18 {
    @include pt-sans-bold();
    @include pt-sans-18();
}

// mixins - responsive
@mixin sans-regular-18() {
    @include pt-sans-regular-16();

    @include media-breakpoint-up(lg) {
        @include pt-sans-regular-18();
    }
}

@mixin sans-bold-16() {
    @include pt-sans-bold-14();

    @include media-breakpoint-up(lg) {
        @include pt-sans-bold-16();
    }
}

@mixin sans-bold-18() {
    @include pt-sans-bold-16();

    @include media-breakpoint-up(lg) {
        @include pt-sans-bold-18();
    }
}


// FONT - pt-serif
@mixin pt-serif() {
    font-family: $vacom-font-family-serif;
    font-weight: $font-weight-normal;
    font-size: 20px;
    font-style: normal;
}

@mixin pt-serif-italic() {
    @include pt-serif();
    font-style: italic;
}

// BOLD
@mixin pt-serif-bold {
    @include pt-serif();
    font-weight: 600;
}

@mixin pt-serif-bold-italic {
    @include pt-serif();
    font-weight: 600;
    font-style: italic;
}

// REGULAR
@mixin pt-serif-regular {
    @include pt-serif();
}

// mixins - font-size
@mixin pt-serif-18() {
    font-size: 18px;
    line-height: 26px;
}

@mixin pt-serif-20() {
    font-size: 20px;
    line-height: 21px;
}

@mixin pt-serif-22() {
    font-size: 22px;
    line-height: 30px;
}

@mixin pt-serif-26() {
    font-size: 26px;
    line-height: 36px;
}

@mixin pt-serif-32() {
    font-size: 32px;
    line-height: 48px;
}

@mixin pt-serif-34() {
    font-size: 34px;
    line-height: 46px;
}

@mixin pt-serif-40() {
    font-size: 40px;
    line-height: 50px;
}

// mixins - font + font-size
@mixin pt-serif-regular-18 {
    @include pt-serif-regular();
    @include pt-serif-18();
}

@mixin pt-serif-regular-20 {
    @include pt-serif-regular();
    @include pt-serif-20();
}

@mixin pt-serif-regular-22 {
    @include pt-serif-regular();
    @include pt-serif-22();
}

@mixin pt-serif-regular-26 {
    @include pt-serif-regular();
    @include pt-serif-26();
}

@mixin pt-serif-regular-32 {
    @include pt-serif-regular();
    @include pt-serif-32();
}

@mixin pt-serif-regular-34 {
    @include pt-serif-regular();
    @include pt-serif-34();
}

@mixin pt-serif-regular-40 {
    @include pt-serif-regular();
    @include pt-serif-40();
}

// mixins - responsive
@mixin serif-regular-34() {
    @include pt-serif-regular-26();

    @include media-breakpoint-up(lg) {
        @include pt-serif-regular-34();
    }
}

@mixin serif-regular-20() {
    @include pt-serif-regular-18();

    @include media-breakpoint-up(lg) {
        @include pt-serif-regular-20();
    }
}

@mixin serif-regular-26() {
    @include pt-serif-regular-22();

    @include media-breakpoint-up(lg) {
        @include pt-serif-regular-26();
    }
}

