//-------------------------------------------------------------
// Tooltip
//-------------------------------------------------------------

$tooltip-max-width: 250px;
$tooltip-padding: building-units(2);
$tooltip-border-color: $gray-medium-1;
$tooltip-icon-color: $purple;
$tooltip-arrow-width-height: 5px;
$tooltip-arrow-offset-left-right: building-units(2);
$tooltip-arrow-offset-top-bottom: -4px;

.tooltip {

  @include pt-sans-regular-14();

  display: block;
  position: relative;

  box-shadow: none;
  border: 0 none;
  padding: 0;

  width: 100%;

  @include media-breakpoint-up(md) {

    width: $tooltip-max-width;
    max-width: $tooltip-max-width;
  }

  p {

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear .2s, opacity .2s;

    border: 1px solid transparent;

    margin: 0;
    padding: $tooltip-padding;
  }

  .vacomicon {

    color: $tooltip-icon-color;
    cursor: pointer;

    position: absolute;
    height: 20px;
  }

  .btn {

    position: absolute;
    opacity: 0;

    @include media-breakpoint-up(md) {

      display: none;
    }
  }

  &.tooltip--active {

    &:before,
    &:after {

      content: '';
      position: absolute;
    }

    p {

      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity .2s;

      border: 1px solid $tooltip-border-color;
    }
  }

  &:before {

    z-index: 5;
  }

  &.tooltip--left-top,
  &.tooltip--right-top {

    margin-top: 30px;

    @include media-breakpoint-up(md) {

      margin-top: 0;
    }
  }

  &.tooltip--left-bottom,
  &.tooltip--right-bottom {

    margin-bottom: 30px;

    @include media-breakpoint-up(md) {

      margin-bottom: 0;
    }
  }

  // Pos - LEFT - TOP
  &.tooltip--left-top {

    .vacomicon,
    .btn {

      top: -30px;
      left: 20px;
    }

    &:before {

      @include arrow($white , calc(#{$tooltip-arrow-width-height} - 2px) , top);
      border-bottom: calc(#{$tooltip-arrow-width-height} - 1px) solid $white;

      top: calc(#{$tooltip-arrow-offset-top-bottom} + 1px);
      left: calc(#{$tooltip-arrow-offset-left-right} + 2px);
    }

    &:after {

      @include arrow($tooltip-border-color , $tooltip-arrow-width-height , top);

      top: $tooltip-arrow-offset-top-bottom;
      left: $tooltip-arrow-offset-left-right;
    }
  }

  // Pos - RIGHT - TOP
  &.tooltip--right-top {

    .vacomicon,
    .btn {

      top: -30px;
      right: 15px;
    }

    &:before {

      @include arrow($white , calc(#{$tooltip-arrow-width-height} - 2px) , top);
      border-bottom: calc(#{$tooltip-arrow-width-height} - 1px) solid $white;

      top: calc(#{$tooltip-arrow-offset-top-bottom} + 1px);
      right: calc(#{$tooltip-arrow-offset-left-right} + 2px);
    }

    &:after {

      @include arrow($tooltip-border-color , $tooltip-arrow-width-height ,top);

      top: $tooltip-arrow-offset-top-bottom;
      right: $tooltip-arrow-offset-left-right;
    }
  }

  // Pos - LEFT - BOTTOM
  &.tooltip--left-bottom {

    .vacomicon,
    .btn {

      bottom: -30px;
      left: 20px;
    }

    &:before {

      @include arrow($white , calc(#{$tooltip-arrow-width-height} - 2px) , down);
      border-top: calc(#{$tooltip-arrow-width-height} - 1px) solid $white;

      bottom: calc(#{$tooltip-arrow-offset-top-bottom} + 1px);
      left: calc(#{$tooltip-arrow-offset-left-right} + 2px);
    }

    &:after {

      @include arrow($tooltip-border-color , $tooltip-arrow-width-height , down);

      bottom: $tooltip-arrow-offset-top-bottom;
      left: $tooltip-arrow-offset-left-right;
    }
  }

  // Pos - RIGHT - BOTTOM
  &.tooltip--right-bottom {

    .vacomicon,
    .btn {

      bottom: -30px;
      right: 15px;
    }

    &:before {

      @include arrow($white , calc(#{$tooltip-arrow-width-height} - 2px) , down);
      border-top: calc(#{$tooltip-arrow-width-height} - 1px) solid $white;

      bottom: calc(#{$tooltip-arrow-offset-top-bottom} + 1px);
      right: calc(#{$tooltip-arrow-offset-left-right} + 2px);
    }

    &:after {

      @include arrow($tooltip-border-color , $tooltip-arrow-width-height , down);

      bottom: $tooltip-arrow-offset-top-bottom;
      right: $tooltip-arrow-offset-left-right;
    }
  }
}
