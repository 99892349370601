//-------------------------------------------------------------
// Button text
//-------------------------------------------------------------

// Variables
$button-text-color-transparent: $purple;
$button-bg-color-transparent: transparent;
$button-text-color-transparent-hover: rgba($purple, .8);
$button-text-color-transparent-active: rgba($purple, .8);
$button-text-color-transparent-disabled: $gray-medium-2;

// dark theme
$button-dark-theme-text-color-transparent: $white;
$button-dark-theme-bg-color-transparent: transparent;
$button-dark-theme-text-color-transparent-hover: rgba($purple, .8);
$button-bg-color-hover: white;
$button-dark-theme-text-color-transparent-active: rgba($white, .8);
$button-dark-theme-text-color-transparent-disabled: $gray-medium-2;

.btn--transparent {

  white-space: inherit;
  color: $button-text-color-transparent;
  background-color: $button-bg-color-transparent;
  line-height: building-units(2);

  &:focus-within,
  &:focus {
    color: $button-text-color-transparent-hover;
    background-color: $button-bg-color-transparent;
  }

  //separate because of IE
  &:hover {
    color: $button-text-color-transparent-hover;
    background-color: $button-bg-color-hover;
  }

  &:active {
    color: $button-text-color-transparent-active;
    background-color: $button-bg-color-transparent;
  }

  &[disabled] {
    cursor: not-allowed;
    color: $button-text-color-transparent-disabled;
  }

  // dark theme
  &.btn--theme-dark {

    color: $button-dark-theme-text-color-transparent;
    background-color: $button-dark-theme-bg-color-transparent;

    &:focus-within,
    &:focus {
      color: $button-dark-theme-text-color-transparent-hover;
      background-color: $button-dark-theme-bg-color-transparent;
    }

    //separate because of IE
    &:hover {
      color: $button-dark-theme-text-color-transparent-hover;
      background-color: $button-dark-theme-bg-color-transparent;
    }

    &:active {
      color: $button-dark-theme-text-color-transparent-active;
      background-color: $button-dark-theme-bg-color-transparent;
    }

    &[disabled] {
      color: $button-dark-theme-text-color-transparent-disabled;
    }
  }
}
