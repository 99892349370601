//-------------------------------------------------------------
// datepck
//-------------------------------------------------------------
// Variables

.daterangepicker {
    position: absolute;
    color: inherit;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #ddd;
    width: 278px;
    max-width: none;
    padding: 0;
    margin-top: 7px;
    top: 100px;
    left: 20px;
    z-index: 30;
    display: none;
    font-size: 15px;
    line-height: 1em;

    &__ranges {
        float: none;
        text-align: left;
        margin: 0;

        ul {
            list-style: none;
            margin: 0 auto;
            padding: 0;
            width: 100%;
        }

        li {
            font-size: 12px;
            padding: 8px 12px;
            cursor: pointer;
        }

        li:hover {
            background-color: $white;
            color: $purple;
        }

        li.active {
            background-color: $white;
            color: $gray-56;

            &:hover {
                background-color: $white;
                color: $gray-56 !important;
            }
        }
    }

    &__nav-item {

      flex: 1 1 35%;

      &:nth-child(n+2) {

        flex: 1 1 65%;
        text-align: right;
      }
    }

    &__buttons {
        clear: both;
        padding: 7px 24px;
        border-top: 1px solid #ddd;
        display: none;
        line-height: 12px;
        vertical-align: middle;

        .applyBtn {

          margin-left: 10px;
        }
    }

    &__calendar {
        display: none;
        max-width: 270px;

        &--single {
            .calendar-table {
                border: none;
            }
        }

        &--right {
            padding: 24px 24px 20px 5px;
        }

        &--left {
            padding: 24px 5px 20px 24px;

        }
    }

    &__calendar-table {
        border: 1px solid #fff;
        border-radius: building-units(1.5);
        background-color: #fff;

        table {
            width: 100%;
            margin: 0;
            border-spacing: 0;
            border-collapse: collapse;
        }

        th,
        td {
            white-space: nowrap;
            text-align: center;
            vertical-align: middle;
            min-width: 32px;
            width: 32px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            border-radius: building-units(1.5);
            border: 1px solid transparent;
            cursor: pointer;
        }

        .next span,
        .prev span {
            color: #fff;
            border: solid black;
            border-width: 0 2px 2px 0;
            border-radius: 0;
            display: inline-block;
            padding: 3px;
        }

        .next span {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }

        .prev span {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }
    }

    &__calendar-time {
        text-align: center;

        select {
            border-radius: building-units(1.5);
            background-color: $white;
            border: 1px solid $gray-117;

            &:hover {

            }
        }
    }

    &--single {

    }

    &--show-ranges {

        .daterangepicker__ranges {
            width: 100%;
        }

        .daterangepicker-calendar.daterangepicker-calendar--left {
            border-left: 1px solid #ddd;
        }
    }

    &--show-calendar {

        .daterangepicker__calendar,
        .daterangepicker__buttons {
            display: block;
        }

        .daterangepicker__buttons {
            display: flex;
            flex-wrap: wrap;
        }

        .daterangepicker__ranges {
            margin-top: 8px;
        }

        &.daterangepicker.daterangepicker--show-ranges .daterangepicker__ranges {
            width: 140px !important;
        }
    }

    &--auto-apply {
        .daterangepicker__buttons {
            display: none;
        }
    }

    &--selected {
        font-size: $font-size-text-small;
        line-height: 35px;
    }

    &--opens-center {
        &:before {
            left: 0;
            right: 0;
            width: 0;
            margin-left: auto;
            margin-right: auto;
        }

        &:after {
            left: 0;
            right: 0;
            width: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &--opens-left,
    &--opens-right {

        &:before {
            right: 9px
        }

        &:after {
            right: 10px;
        }
    }

    &--drops-up {
        margin-top: -7px;

        &:before {
            top: initial;
            bottom: -7px;
            border-bottom: initial;
            border-top: 7px solid #ccc;
        }

        &:after {
            top: initial;
            bottom: -6px;
            border-bottom: initial;
            border-top: 6px solid #fff;
        }
    }

    &:before,
    &:after {
        position: absolute;
        display: inline-block;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }

    &:before {
        top: -7px;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #ccc;
    }

    &:after {
        top: -6px;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
    }
}


.daterangepicker.daterangepicker--single .daterangepicker .daterangepicker__ranges, .daterangepicker.daterangepicker--single .daterangepicker__calendar {
    float: none;
}

.daterangepicker td, .daterangepicker th {
    color: $black;
    height: 30px;
    width: 30px;
    margin: 0;
    font-size: $font-size-text-small;
    line-height: $font-size-text-small;
}

.daterangepicker__calendar-table {

  .month {
    font-weight: normal;
  }
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: $purple;
    border-color: transparent;
    color: $white;

    span {

      border-color: $white;
    }
}

.daterangepicker td.in-range:hover {
  border-radius: 0 building-units(1.5) building-units(1.5) 0;
}

.daterangepicker td.week, .daterangepicker th.week {
    font-size: 80%;
    color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
    background-color: $white;
    border-color: transparent;
    color: $gray-56;
}

.daterangepicker td.in-range {
    background-color: $purple-light;
    border-color: transparent;
    color: $black;
    border-radius: 0;
}

.daterangepicker td.start-date {
    border-radius: building-units(1.5) 0 0 building-units(1.5);
}

.daterangepicker td.end-date {
    border-radius: 0 building-units(1.5) building-units(1.5) 0;
}

.daterangepicker td.start-date.end-date {
    border-radius: building-units(1.5);
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $purple;
    border-color: transparent;
    color: $white;
}

.daterangepicker th.month {
    width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
    color: #999;
    cursor: not-allowed;
    text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;
}

.daterangepicker select.monthselect {
    margin-right: 2%;
    width: 56%;
}

.daterangepicker select.yearselect {
    width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
    width: 50px;
    margin: 0 auto;
    padding: 2px;
    font-size: 12px;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
    .daterangepicker {
        width: auto; }
    .daterangepicker.daterangepicker--single .daterangepicker__ranges ul {
        width: 100%; }
    .daterangepicker.daterangepicker--single .daterangepicker__calendar.daterangepicker__calendar--left {
        clear: none; }
    .daterangepicker.daterangepicker--single.ltr .daterangepicker__ranges, .daterangepicker.daterangepicker--single.ltr .daterangepicker__calendar {
        float: left; }
    .daterangepicker.daterangepicker--single.rtl .daterangepicker__ranges, .daterangepicker.daterangepicker--single.rtl .daterangepicker__calendar {
        float: right; }
    .daterangepicker.ltr {
        direction: ltr;
        text-align: left; }
    .daterangepicker.ltr .daterangepicker__calendar.daterangepicker__calendar--left {
        clear: left;
        margin-right: 0; }
    .daterangepicker.ltr .daterangepicker__calendar.daterangepicker__calendar--left .daterangepicker__calendar-table {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .daterangepicker.ltr .daterangepicker__calendar.daterangepicker__calendar--right {
        margin-left: 0; }
    .daterangepicker.ltr .daterangepicker__calendar.daterangepicker__calendar--right .daterangepicker__calendar-table {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
    .daterangepicker.ltr .daterangepicker__calendar.daterangepicker__calendar--left .daterangepicker__calendar-table {
        padding-right: 24px; }
    .daterangepicker.ltr .daterangepicker__ranges, .daterangepicker.ltr .daterangepicker__calendar {
        float: left; }
    .daterangepicker.rtl {
        direction: rtl;
        text-align: right; }
    .daterangepicker.rtl .daterangepicker__calendar.daterangepicker__calendar--left {
        clear: right;
        margin-left: 0; }
    .daterangepicker.rtl .daterangepicker__calendar.daterangepicker__calendar--left .daterangepicker__calendar-table {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
    .daterangepicker.rtl .daterangepicker__calendar.daterangepicker__calendar--right {
        margin-right: 0; }
    .daterangepicker.rtl .daterangepicker__calendar.daterangepicker__calendar--right .daterangepicker__calendar-table {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .daterangepicker.rtl .daterangepicker__calendar.daterangepicker__calendar--left .daterangepicker__calendar-table {
        padding-left: 12px; }
    .daterangepicker.rtl .daterangepicker__ranges, .daterangepicker.rtl .daterangepicker__calendar {
        text-align: right;
        float: right; } }
@media (min-width: 730px) {
    .daterangepicker .daterangepicker__ranges {
        width: auto; }
    .daterangepicker.ltr .daterangepicker__ranges {
        float: left; }
    .daterangepicker.rtl .daterangepicker__ranges {
        float: right; }
    .daterangepicker .daterangepicker__calendar.daterangepicker__calendar--left {
        clear: none !important; } }

.ui-datepicker {
  background-color: #fff;
  border: 1px solid #ddd;
  font-size: 15px;
  line-height: 1em;
  border-radius: 0;
  padding: 20px;
  margin-top: 7px;

  &:before,
  &:after {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }

  &:before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
  }

  &:after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
  }

  .ui-datepicker-today {
    background-color: #9c0a7d;
    border-color: transparent;
    color: #fff;
    border-radius: 15px;
  }


  table {
    width: 100%;
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;


    th {
      color: #000;
      height: 30px;
      width: 30px;
      margin: 0;
      font-size: 14px;
      line-height: 14px;
    }

    td {
      height: 30px;
      width: 30px;
      margin: 0;
      font-size: 14px;
      line-height: 14px;
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      min-width: 32px;
      border-radius: 15px;
      border: 1px solid transparent;

    }
  }
}



.ui-datepicker-header {
  display: flex;
  padding: 5px;
  text-align: center;
}

.ui-datepicker-prev, .ui-datepicker-next {
  flex: 1;
  display: inline-block;
  padding: 5px;
  text-decoration: none;
  cursor: pointer;
}

.ui-datepicker-title {
  flex: 2;
  font-weight: bold;
  margin: 5px;
}


.ui-datepicker-next {
  order: 3;
}


.ui-datepicker-unselectable {
  color: $gray-medium-2;
}

.ui-datepicker-selectable {
  background-color: $purple-light;
}
