//-------------------------------------------------------------
// Arrow
//-------------------------------------------------------------

@mixin arrow($color , $size , $orientation) {

    width: 0;
    height: 0;

    @if $orientation == top {

        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;

    } @else if $orientation == right {

        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;

    } @else if $orientation == down {

        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;

    } @else {

        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
    }
}
