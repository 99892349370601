//
// Utilities for common `display` values
//

@each $breakpoint in map-keys($vacom-grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $vacom-grid-breakpoints);

        .d#{$infix}-none         { display: none !important; }
        .d#{$infix}-inline       { display: inline !important; }
        .d#{$infix}-inline-block { display: inline-block !important; }
        .d#{$infix}-block        { display: block !important; }
        .d#{$infix}-table        { display: table !important; }
        .d#{$infix}-table-row    { display: table-row !important; }
        .d#{$infix}-table-cell   { display: table-cell !important; }
        .d#{$infix}-flex         { display: flex !important; flex: 1 1 auto; flex-direction: row; }
        .d#{$infix}-inline-flex  { display: inline-flex !important; }

        .margin#{$infix}-0 { margin: 0 !important; }
        .margin-top#{$infix}-0   { margin-top: 0 !important; }
        .margin-right#{$infix}-0 { margin-right: 0 !important; }
        .margin-bottom#{$infix}-0 { margin-bottom: 0 !important; }
        .margin-left#{$infix}-0 { margin-left: 0 !important; }

        .margin#{$infix}-half { margin: #{$vacom-grid-gutter-width/4} !important; }
        .margin-top#{$infix}-half { margin-top: #{$vacom-grid-gutter-width/4} !important; }
        .margin-right#{$infix}-half { margin-right: #{$vacom-grid-gutter-width/4} !important; }
        .margin-bottom#{$infix}-half { margin-bottom: #{$vacom-grid-gutter-width/4} !important; }
        .margin-left#{$infix}-half { margin-left: #{$vacom-grid-gutter-width/4} !important; }

        .margin#{$infix}-10 { margin: #{$vacom-grid-gutter-width/3} !important; }
        .margin-top#{$infix}-10 { margin-top: #{$vacom-grid-gutter-width/3} !important; }
        .margin-right#{$infix}-10 { margin-right: #{$vacom-grid-gutter-width/3} !important; }
        .margin-bottom#{$infix}-10 { margin-bottom: #{$vacom-grid-gutter-width/3} !important; }
        .margin-left#{$infix}-10 { margin-left: #{$vacom-grid-gutter-width/3} !important; }

        .margin#{$infix}-15 { margin: #{$vacom-grid-gutter-width/2} !important; }
        .margin-top#{$infix}-15 { margin-top: #{$vacom-grid-gutter-width/2} !important; }
        .margin-right#{$infix}-15 { margin-right: #{$vacom-grid-gutter-width/2} !important; }
        .margin-bottom#{$infix}-15 { margin-bottom: #{$vacom-grid-gutter-width/2} !important; }
        .margin-left#{$infix}-15 { margin-left: #{$vacom-grid-gutter-width/2} !important; }

        .margin#{$infix}-20 { margin: #{($vacom-grid-gutter-width/3)*2} !important; }
        .margin-top#{$infix}-20 { margin-top: #{($vacom-grid-gutter-width/3)*2} !important; }
        .margin-right#{$infix}-20 { margin-right: #{($vacom-grid-gutter-width/3)*2} !important; }
        .margin-bottom#{$infix}-20 { margin-bottom: #{($vacom-grid-gutter-width/3)*2} !important; }
        .margin-left#{$infix}-20 { margin-left: #{($vacom-grid-gutter-width/3)*2} !important; }

        .margin#{$infix}-30 { margin: #{$vacom-grid-gutter-width} !important; }
        .margin-top#{$infix}-30 { margin-top: #{$vacom-grid-gutter-width} !important; }
        .margin-right#{$infix}-30 { margin-right: #{$vacom-grid-gutter-width} !important; }
        .margin-bottom#{$infix}-30 { margin-bottom: #{$vacom-grid-gutter-width} !important; }
        .margin-left#{$infix}-30 { margin-left: #{$vacom-grid-gutter-width} !important; }

        .margin#{$infix}-60 { margin: #{$vacom-grid-gutter-width*2} !important; }
        .margin-top#{$infix}-60 { margin-top: #{$vacom-grid-gutter-width*2} !important; }
        .margin-right#{$infix}-60 { margin-right: #{$vacom-grid-gutter-width*2} !important; }
        .margin-bottom#{$infix}-60 { margin-bottom: #{$vacom-grid-gutter-width*2} !important; }
        .margin-left#{$infix}-60 { margin-left: #{$vacom-grid-gutter-width*2} !important; }

        .padding#{$infix}-0 { padding: 0 !important; }
        .padding-top#{$infix}-0   { padding-top: 0 !important; }
        .padding-right#{$infix}-0 { padding-right: 0 !important; }
        .padding-bottom#{$infix}-0 { padding-bottom: 0 !important; }
        .padding-left#{$infix}-0 { padding-left: 0 !important; }

        .padding#{$infix}-15 { padding: #{$vacom-grid-gutter-width/2} !important; }
        .padding-top#{$infix}-15 { padding-top: #{$vacom-grid-gutter-width/2} !important; }
        .padding-right#{$infix}-15 { padding-right: #{$vacom-grid-gutter-width/2} !important; }
        .padding-bottom#{$infix}-15 { padding-bottom: #{$vacom-grid-gutter-width/2} !important; }
        .padding-left#{$infix}-15 { padding-left: #{$vacom-grid-gutter-width/2} !important; }

        .padding#{$infix}-30 { padding: #{$vacom-grid-gutter-width} !important; }
        .padding-top#{$infix}-30 { padding-top: #{$vacom-grid-gutter-width} !important; }
        .padding-right#{$infix}-30 { padding-right: #{$vacom-grid-gutter-width} !important; }
        .padding-bottom#{$infix}-30 { padding-bottom: #{$vacom-grid-gutter-width} !important; }
        .padding-left#{$infix}-30 { padding-left: #{$vacom-grid-gutter-width} !important; }
    }
}

.visually-hidden {
    visibility: hidden;
}

.hidden {
    display: none;
}

.show {
    display: block;
}

// extend inside the loop above when needed
// for now no use case to develop full range of responsive negative margin classes
.margin-top-n-60 {
    margin-top: -60px !important;
}
