body.sales-quote-view,
body.sales-order-view,
body.sales-order-print {

    table#my-orders-table {
        .col.price,
        .col.qty,
        .col.subtotal,
        tfoot th,
        tfoot td {
            @include media-breakpoint-up(sm) {
                text-align: right;
            }
        }

        caption {
            display: none;
        }
    }

    .order-status {
        border-radius: 8px;
        padding-left: building-units(1);
        padding-right: building-units(1);
    }

    .order-actions-toolbar {
        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .order-links {
        .item {
            strong {
                border: none !important;
                @extend %h4;
                margin-bottom: 0;
                padding-left: 0;
            }
        }
    }

    .order-details-items {
        padding-top: 1px;
        padding-left: 1px;
        padding-bottom: 1px;
        border: none !important;

        .order-title {
            display: none;
        }

        .table-order-items {
            thead {
                th {
                    overflow-wrap: normal;
                }
            }
            tbody {
                tr {

                    td {
                        font-size: $font-size-default !important;
                        font-weight: $font-weight-base !important;

                        span, strong {
                            font-size: $font-size-default !important;
                            font-weight: $font-weight-base !important;
                        }

                        strong {
                            font-weight: $font-weight-bold !important;
                        }
                    }
                }
            }
            tfoot {
                position: relative;
                top: 30px;
            }
        }
        .item-options {
            padding-left: 0;

            dt {
                display: inline-block;
                font-weight: $font-weight-base;
                list-style-type: none;

                &:after {
                    content: ":";
                }
            }

            dd {
                display: inline-block;
                margin-bottom: 0;
            }
        }
    }

    .block-order-details-view {
        .payment-method {
            padding-left: 0;
            margin: 0;
            .title {
                font-weight: $font-weight-base;
                font-size:   rem($font-size-default);
                line-height: rem($line-height-default);
            }
        }

        address {
            font-style: normal;
        }
    }

    .table-wrapper.order-items {
        border-bottom: none;

        table {
            tfoot {
                tr {
                    font-weight: $font-weight-bold;

                    td {
                        background: none;
                        padding-right: 15px;
                    }
                }
            }
        }
    }
}

body.sales-order-print {
    .hidden-print {
        display: none;
    }
}

body.customer-account-index,
body.customer-address-index {
    .card_cf {
        address {
            font-style: normal;
        }
    }
}

body.account {
    .columns {
        @extend .row;

        > .main {
            @extend .col-12;
            @extend .col-lg-9;
            @extend .order-2;
        }

        > .sidebar-main {
            @extend .col-12;
            @extend .col-lg-3;
            @extend .order-1;

            height: 100%;
            margin-right: 0;
            margin-top: 42px;
        }
    }
}

body.wishlist-index-index .product-tile-wrapper .product-tile__wishlist > a {
    color: $black !important;
}

body.account .table-caption {
    display: none;
}

body.account table a.link {
    color: $purple;

    &:hover {
        text-decoration: underline;
    }
}

body.account .account--area {
    margin-top: 30px;
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
        margin-top: 40px;
    }
}

body.sales-order-history {
    .order-products-toolbar {
        div.limiter {
            justify-content: flex-end;
        }
    }
}

body.customer-address-form {
    .field-name-position,
    .field-name-industry {
        display: none;
    }
}