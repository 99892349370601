//-------------------------------------------------------------
// Input Validation Styling
//-------------------------------------------------------------

$input-error-bg-color:       $red;
$input-error-bg-color-hover: $red;
$input-error-color:          $red;

$input-success-bg-color:       $green;
$input-success-bg-color-hover: $green;
$input-success-color:          $green;

// Error Styling
.has-error,
.field-error {
    @include set-input-validation-styling($input-error-bg-color, $input-error-bg-color-hover, $input-error-color);
}

// Success Styling
.has-success {
    @include set-input-validation-styling($input-success-bg-color, $input-success-bg-color-hover, $input-success-color);
}
