//-------------------------------------------------------------
// Tiles
//-------------------------------------------------------------

// Variables
//$tile-min-height: 165px;

// // General
.design-token-inline {
  display: inline-block;

  // to overwrite typography inline styles
  font-size: inherit !important;
  line-height: inherit !important;
  color: inherit !important;
  font-weight: 500;

  &--has-tooltip {
    cursor: help;
  }

  &__icon {
    display: inline-block;
    vertical-align: middle;
    transform: translate(0, -2px);
    height: 1em !important;
    width: 1em !important;
    background: $white;
    border: 1px solid transparent;
    margin-right: rem(6px);

    &[class*="icon-"] {
      width: auto !important;
    }

    &[style*="background"] {
      outline: 1px solid #efefef;
    }
  }

  &__text {
    text-decoration: underline;

    // &:focus {
    //   outline: none;
    // }
  }
}
