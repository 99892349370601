//-------------------------------------------------------------
//Product Tiles Slider
//-------------------------------------------------------------

// Variables

// General
.swiper-slide .product-tile-container--list .product-tile-wrapper {
    max-width: 100% !important;
    flex: 0 0 100% !important;
}
