//-------------------------------------------------------------
// Footer Social
//-------------------------------------------------------------

.page-footer__social {

  .social {

    &__list {

      @extend %reset-ul;
    }

    &__list-item {

      padding: 0;
      margin: 0;

      .vacomicon {

        color: $gray-medium-1;
        font-size: 15px;

        padding: 0;
        margin-right: 10px;
      }
    }

    &__list-link {

      @include page-footer-link();
    }
  }
}
