// Variables
$pagination-text-color:                     $black;
$pagination-item-hover-text-color:          $purple;
$pagination-item-active-text-color:         $purple;
$pagination-item-disabled-text-color:       $gray-medium-1;

.pagination {
    margin-top: 10px;

    &--center {
        text-align: center;
    }

    &--right {
        text-align: right
    }

    &__list {
        list-style: none;
        display: inline-flex;
        flex-flow: row wrap;
        text-align: center;
        padding-left: 1px;
        margin: 0 building-units(2);
    }

    &__item {
      cursor: pointer;
      border: none;
      margin-bottom: 0;

        @include media-breakpoint-up(md) {
            display: block;
        }

        &:hover,
        &:focus-within {
            position: relative;
            z-index: 1;
            background: none;
            .pagination__link {
              text-decoration: underline;
              color: $pagination-item-active-text-color;
            }
        }

        &.active {
            .pagination__link {
                @include pt-sans-bold-18();
                color: $pagination-item-active-text-color;
                cursor: default;
            }

            &:hover {
              .pagination__link {
                text-decoration: none;
                cursor: default;
              }
            }
            background: none;
            z-index: 2
        }

        > .pagination__link {
            cursor: pointer;
            @include pt-sans-regular-18();
        }

        &.disabled > .pagination__link {
            cursor: no-drop;
            color: $pagination-item-disabled-text-color;
        }
    }

    .vacomicon {
        @extend %vacomicon;
        color: $pagination-text-color;
        font-size: building-units(0.8);

        &:hover {
            color: $pagination-item-hover-text-color;
        }
    }



    &__link,
    &__ellipsis {
        display: block;
        @include pt-sans-regular-18();
        padding: 0 building-units(1);
        color: $gray-medium-2;
        text-decoration: none;
        @include reset-hover();

        &:hover {
            color: $black;

            @include media-breakpoint-down(md) {
                background-color: $white;
            }

        }
    }

    &__ellipsis {
        color:          $gray-medium-2;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

@include media-breakpoint-only(xs) {
    .pagination {
        &__item {
            display: none;
            &.active,
            &.pagination__prev,
            &:last-of-type {
                display: initial;
            }
        }
    }
}
