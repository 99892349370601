//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables
$top-navigation-border-color: $gray-light;
$top-navigation-link-hover: $purple;

.top-navigation {
    max-height: 60px;
    height: 100%;
    display: flex;
    transition: height 0.3s, padding-bottom 0.3s;

    @include media-breakpoint-down(md) {
        max-height: 30px;
    }

    &__left,
    &__right {
        display: flex;
        flex-flow: row;
        width: 100%;
    }

    &__left {
        justify-content: flex-start;
        align-items: center;
    }

    &__right {
        align-items: center;
        justify-content: flex-end;
    }

    &__item {
        // flyout pos left fix
        position: relative;

        &.country-switcher-simple,
        &.language-switcher {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        // flyout item width
        &:not(:last-child) {
            padding-right: building-units(1.5);

            @include media-breakpoint-up(lg) {
                padding-right: building-units(4);
            }
        }

        .action__text {
            .top-navigation__right & {
                display: none;
                @include media-breakpoint-up(lg) {
                    display: inline;
                }
            }

            @include pt-sans-regular-14();
            color: inherit;
            margin: 0;
            padding: 0;
        }

        .link {
            display: block;
            color: $black;
            margin: 0;
            padding: 0;
            text-decoration: none;

            .vacomicon {
                color: inherit;
            }

            &:focus-within,
            &:focus {
                color: $top-navigation-link-hover;
            }

            //separate because of IE
            &:hover {
                color: $top-navigation-link-hover;
            }

            &:active {
                color: $top-navigation-link-hover;
            }
        }

        //general overwrites for flyout in top navigation
        .flyout {
            position: absolute;
            // flyout left fix
            left: 0 !important;
            z-index: 103;
            // flyout left fix additional width
            width: calc(100% + 35px);

            @include media-breakpoint-up(md) {
                width: calc(100% + 20px);
            }

            &__headline {
                .icon-globe {
                    margin-right: building-units(1);
                    line-height: 19px;
                }
            }

            &--open {
                background-color: $white;
            }
        }

        /***********************************
         * COUNTRY-SWITCHER
         ***********************************/
        &.country-switcher-simple,
        &.language-switcher,
        &.measurement-switcher-dropdown {
            margin-top: 6px;
        }

        &.country-switcher .flyout {
            .top-navigation__list {
                @extend %text-small;
                padding: building-units(2);
            }

            .top-navigation__listitem {
                margin: 0;
                padding: building-units(1) 0 0 0;
            }

            .top-navigation__listitem:nth-child(1) {
                padding: 0;
                border-bottom: 1px solid $gray-medium-1;

                .acc_content2__title {
                    color: $black;
                }
            }

            .acc_content2--open .acc_content2__headline {
                border-left: 1px solid $white-light;
            }

            .acc_content2__toggle {
                display: none;
            }

            .acc_content2__headline {
                border-bottom-color: transparent;
            }

            .acc_content2__title {
                display: block;
                color: $gray-medium-2;
                padding-bottom: building-units(1);
            }

            .acc_content2__content {
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }

                ul li {
                    margin: 0;
                    padding: 0;

                    &:last-child {
                        a {
                            padding-bottom: 0;
                        }
                    }
                }

                ul li a {
                    @extend %text-small;

                    display: block;
                    color: $black;
                    padding-left: building-units(2);
                    padding-bottom: building-units(1);

                    &:hover {
                        color: $purple;
                        text-decoration: underline;
                    }
                }
            }
        }

        /***********************************
         * LANGUAGE-SWITCHER
         ***********************************/
        &.switcher .flyout {

            .top-navigation__list {
                padding: building-units(2);
                margin: 0;
            }

            .top-navigation__listitem {
                padding: 0 0 building-units(1) 0;
                margin: 0;

                &:last-child {
                    padding-bottom: 0;
                }
            }

            .top-navigation__text {
                @extend %text-small;

                display: block;
                color: $black;
                padding: 0;
                margin: 0;

                &:hover {
                    color: $purple;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    //-------------------------------------------------------------
    // List within flyouts
    //-------------------------------------------------------------
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__listitem {
        > a {
            display: flex;
            flex-flow: row;
            box-sizing: border-box;
        }

        &:last-child {
            border-bottom: none;
        }

        /**
         * accordion
         */
        &:first-child {
            .acc_content2__headline {
                border-top-color: transparent;
            }
        }

        .acc_content2:not(.acc_content2--open) .acc_content2__headline {
            border-bottom-color: transparent;
        }
    }

    &__icon {
        padding-top: 8px;
        padding-left: building-units(1);
        margin-right: -5px;
        align-self: flex-start;

        > .vacomicon {
            top: 0;
            width: 18px;
            height: 18px;
            font-size: 18px;
            color: $blue;
        }
    }

    &__text {
        display: block;
        width: 100%;

        &:hover {
            cursor: pointer;
        }
    }
}

