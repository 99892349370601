//-------------------------------------------------------------
//Contact Box
//-------------------------------------------------------------

// Variables
%shadow--purple {
  box-shadow: 0 building-units(1) building-units(1) rgba(0,0,0,0.05);
}

// General
.contact_box {
    &__label {
        @extend %text-small;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $gray-medium-1;
        margin-bottom: building-units(0.666);
    }

    &__wrapper {
        display: flex;
        flex-flow: column;
        position: relative;
    }

    &__image {
        min-width: building-units(21);
        width: building-units(21);
        border-radius: 0;
        box-shadow: building-units(1) building-units(1) 0px 0px $purple;
        margin: 0 building-units(1) building-units(1) 0;
        position: absolute;
        top: building-units(-10);

        @include media-breakpoint-between(xs, sm) {
            min-width: building-units(16);
            width: building-units(16);
            top: building-units(-7.5);
        }
    }

    &__content {
      margin-top: building-units(12);

        .contact_box__name {
            @include pt-serif-regular-26();
            color: $purple;
            margin: building-units(2) 0 0 0;

          @include media-breakpoint-between(xs, sm) {
            @include pt-serif-regular-22();
            color: $purple;
            margin-top: building-units(9);
          }
        }

        @include media-breakpoint-between(xs, sm) {
          margin-top: building-units(3);
        }

        .contact_box__position {
          @include pt-sans-14();
          color: $black;
          margin: building-units(1) 0 building-units(2);
        }

    }

    &__link {
        @include pt-sans-14();
        margin-bottom: building-units(1);

        a {
            color: $black;

            .vacomicon {
              font-size: 14px;
              color: $purple;
            }

            &:hover {
              text-decoration: underline !important;
            }
        }
    }

    &__link:last-child {
      margin-bottom: 0;
    }

    &__subheading {
        @extend %text-small;
        color: $gray-medium-1;
        margin-bottom: building-units(1);
        font-weight: $font-weight-bold;
    }

    .img_content-box {
        margin-bottom: 0;
    }

    &--outline {
        border: 1px solid $gray-medium-1;
        padding: building-units(3);
        border-radius: 0;
        margin-top: building-units(10);

        @include media-breakpoint-between(xs, sm) {
          padding: building-units(2);
          margin-top: building-units(8.5);
        }
    }

    //Contact box in footer
    &--footer {
        .card_cf--outline {
            padding-top: 1.7rem;
        }
        p{
            margin-bottom: 5px;
        }
        .contact_box {
            &__subheading {
                text-transform: uppercase;
            }
        }
        .img_content-box {
            width: 90px;
            height: 90px;
            float: left;
            top: 0;
            left: 0;
            margin: 10px 15px 15px;
        }
    }
}
