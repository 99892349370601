//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------


.swiper {
  position: relative;

  &-container {
    margin: 0 auto;
    width: calc(100% - 188px);
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }

  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    transform: translate3d(0px, 0, 0);
  }

  &-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }

  &-slide__image {
    overflow: hidden;
    max-height: 452px;
    display: inline-block;
    width: 100%;

    > img {
      width: 100%;
    }
  }

  &-slide__content {
    position: relative;
    padding: 12px;
    margin-left:auto;
    margin-right: auto;
    margin-bottom: 36px;
    margin-top: calc(((225px * 0.48) + 36px) * -1);
    overflow: hidden;
    width: 60%;
    max-width: 760px;
    min-height: 201px;
    max-height: 249px;
    align-self: flex-end;
    border: 1px solid $slider-content-border;
    background-color: $slider-content-background-color;
    z-index: 9;

    > h1 {
      font-weight: 200;
      margin: 24px 0 15px 0;
    }

    > button {
      margin: 24px 0 36px 0;
    }
  }

  &-button-prev, &-button-next {
    position: absolute;
    top: 50%;
    border-radius: 8px;
    width: 82px;
    height: 235px;
    margin-top: calc((235px / 2) * -1);
    z-index: 10;
    cursor: pointer;
    background-size: 82px 235px;
    background-position: center;
    background-repeat: no-repeat;

    @extend .teleicon;
    font-size: $font-size-h1-l;

    &:after {
      position: relative;
      top: calc((235px / 2) - (48px / 2));
      left: calc((82px / 2) - (24px / 2));
    }

    &:hover {
        background-color: $slider-button-prev-next-hover-background-color;
    }
  }

  &-button-prev {
    left: 0;
    &:after {
      content: "\25"
    }
  }

  &-button-next {
    right: 0;
    &:after {
      content: "\a7"
    }
  }

  &-pagination {
    order: 3;
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
  }

  &-pagination-bullets {
    bottom: 10px;
    left: 0;
    width: 100%;
  }

  &-pagination-bullet {
    width: 8px;
    height: 8px;
    margin-right: 10px;
    display: inline-block;
    border-radius: 100%;
    background: $gray-237;

    &:hover {
      background: $slider-bullet-hover;
    }
  }

  &-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }

  &-pagination-bullet-active {
    opacity: 1;
    background: $magenta;
  }

  &--scheme-light {
    color: $scheme-dark;

    .swiper-slide__content {
      background-color: rgba($white, 0.9);
    }
  }

  &--scheme-dark {
    color: $slider-scheme-dark-text-color;

    .swiper-slide__content {
      background-color: rgba($scheme-dark, 0.9);
      border-color: $slider-scheme-dark-swiper-slide-content-border-color;
    }
  }

  &-bg--white {
    .swiper-slide__image {
      background-color: $slider-bg-white-swiper-slide-image-background-color;
    }
  }

  &-bg--primary {
    .swiper-slide__image {
      background-color: $slider-bg-magenta-swiper-slide-image-background-color;
    }
  }

  &-bg--secondary {
    .swiper-slide__image {
      background-color: $slider-bg-secondary-swiper-slide-image-background-color;
    }
  }
}


@include media-breakpoint-down(lg) {
  .swiper-slide__image {
    max-height: 350px;
  }
}

//viewport sm
@include media-breakpoint-down(md) {

  .swiper-container {
    width: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 60px;
    height: 140px;
    margin-top: calc((140px / 2) * -1);

    &:after {
      position: relative;
      top: calc((140px / 2) - (48px / 2));
      left: calc((60px / 2) - (24px / 2));
      color: $slider-swiper-button-prev-next-after-color;
      text-shadow: 0 0 4px $slider-swiper-button-prev-next-after-text-shadow-color;
    }

    &:hover {
      background-color: $slider-swiper-button-prev-next-hover-background-color;
    }
  }

  .swiper-button-prev {
    left: 12px;
    right: auto;

    &:after {
      content: "\25"
    }
  }

  .swiper-button-next {
    right: 12px;
    left: auto;

    &:after {
      content: "\a7"
    }
  }

  .swiper-slide__image {
    max-height: 222px;
  }

  .swiper-slide__content {
    position: inherit;
    border: none;
    width: 100%;
    min-width: 100%;
    left: 0;
    bottom: 0;
    margin: 0;
    min-height: auto;
    max-height: 100%;
  }

  .swiper--scheme-dark {
    color: $text-color;

    .swiper-slide__content {
      background-color: rgba($white, 0.9);
    }
  }
}

@include media-breakpoint-down(xs) {
  .swiper-container {
    width: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 49px;
    height: 73px;
    margin-top: calc((73px / 2) * -1);

    &:after {
      position: relative;
      top: calc((73px / 2) - (48px / 2));
      left: calc((49px / 2) - (24px / 2));
    }
  }

  .swiper-slide__image {
    max-height: 113px;
  }
}