body.checkout-cart-index {
    /**
     * hide second error message
     */
    .messages .infobox--error:not(:first-child) {
        display: none;
    }

    table th.qty {
        min-width: 170px;
    }

    #cart-totals {
        width: 100%;
    }

    .data.table.totals {
        display: table !important;
    }

    .cart.actions {
        padding: building-units(2) building-units(1) !important;
        text-align: inherit !important;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: space-around;
        align-items: center;
        justify-content: space-between;
        width: auto;

        .form-input-set {
            width: auto;
        }

        input {
            width: auto;
        }

        @include media-breakpoint-only(xs) {
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;

            button[name=update_cart_action] {
                margin-top: building-units(1);
                white-space: nowrap;
            }
        }
    }

  .cart-container.row{
    margin-right: 0;
    margin-left: 0;
  }

  //@todo VACOM-2173 remove margin
  .page.messages {
    .messages {
      margin-bottom: -50px;
    }
  }
}
