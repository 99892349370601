//-------------------------------------------------------------
// Page Layout Styles
//-------------------------------------------------------------

// Layout of pages and content wrappers

.page-header {
    @media (max-width: 1024px) {
        box-shadow: 0 0 20px rgba(0, 0, 0, .3);
        z-index: 1;
    }
}

.content {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
}

.content-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;

    &--right-column {
        .main {
            max-width: 100%;
        }
    }

    &--sidebar {
        @media (max-width: 1024px) {
            flex-flow: column nowrap;
        }
    }
}

main.main {
    flex: 1 1 auto;
    max-width: 100%;
    display: block;
    padding: 42px 0 75px;
}

.centered-main {
    align-self: center;
    margin: 0 auto;
}

.sidebar {
    flex: 0 0 300px;
    margin-right: 24px;

    @media (min-width: 1024px) {
        &.is-open + .main {
            flex: 0 0 calc(100% - 324px);
            max-width: calc(100% - 324px)
        }
    }
}

.right-column {
    flex: 0 0 100%;
    margin-left: 0;
    margin-bottom: building-units(2);
}

.checkout-payment-method {
    border: none !important;
}

.payment-method._active {
    border: none !important;
    background-color: transparent;
}

.checkout-payment-method .payment-method:last-child .payment-method-title {
    border-bottom: none !important;
}

.checkout-payment-method .payment-method-content {
    padding: 0 !important;
}

.payment-titlelogo-wrapper {
    display: block;
    margin: 0;
}

@media (min-width: 1024px) {
    .content-wrapper {
        display: flex;
        flex-flow: row nowrap;

        &--right-column {
            .main {
                flex: 0 0 calc(100% - 324px);
                max-width: calc(100% - 324px);
            }
        }
    }

    .right-column {
        flex: 0 0 300px;
        margin-left: 24px;
    }
}

/********************************
 * PROJECT SPECIFIC STYLES
 ********************************/
main.main {
    > div:last-child div.banners,
    > div.section:not(.section--bg-white):last-child,
    > div:last-child section.section,
    > div:last-child > div.frame-type-gridelements_pi1:last-child > section.section {
        margin-bottom: -75px;
    }
}

// parent swiper behavior
.swiper {
    + .container {
        .main {
            @include media-breakpoint-up(md) {
                padding: 30px 0 0;
            }
        }
    }
}

div.top_banner,
stage {
    + .main {
        padding: 0;
    }
}

.sidebar {
    flex: 0 0 300px;
    margin-right: 24px;

    @media (min-width: 1024px) {
        &.is-open + .main {
            flex: 0 0 calc(100% - 324px);
            max-width: calc(100% - 324px)
        }
    }
}

@media (min-width: 1024px) {
    .content-wrapper {
        display: flex;
        flex-flow: row nowrap;

        &--right-column {
            .main {
                flex: 0 0 calc(100% - 324px);
                max-width: calc(100% - 324px);
            }
        }
    }

    .right-column {
        flex: 0 0 300px;
        margin-left: 24px;
    }
}

.customer-account-login .content .container .columns .main .section__content {
    padding: 0 12.5%;
}

.customer-account-create .content .container .columns .main .col-12 .section_vc__content .form-create-account .row .col-md-6 .address .country .control .country-select__dropdown select[name="country_id"] {
    width: 100%;
    height: 50px;
}

.vertical-align-unset {
    vertical-align: unset;
}

.display-inline-flex {
    display: inline-flex !important;
}

/**
 minicart
 */
.minicart-wrapper .block-minicart {
    width: 390px;
    font-size: 14px;
    font-family: "Inter Tight", sans-serif;
}

.block-minicart .subtotal {
    margin: 0 10px 10px;
    display: flex;
    flex-flow: row;
}

.block-minicart .subtotal > .label,
.block-minicart .subtotal > .price-container{
    display: block;
    width: 50%;
    text-align: right;
}

.block-minicart .amount .price-wrapper:first-child .price {
    font-weight: 700
}

.block-minicart .subtitle {
    display: none
}

.block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: 30px 0 20px;
    text-align: center
}

.block-minicart dl.product.options.list {
    display: inline-block;
    vertical-align: top
}

.block-minicart .text.empty {
    text-align: center
}

.block-minicart .block-content > .actions {
    display: none
}

.block-minicart .block-category-link, .block-minicart .block-product-link, .block-minicart .block-cms-link, .block-minicart .block-banners {
    margin: 15px 0 0;
    text-align: center
}

.minicart-wrapper .action.showcart {
    cursor: pointer;
    text-decoration: none
}

.minicart-wrapper .block-minicart {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 100%;
    width: 280px;
    z-index: 1010;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    right: -10px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15)
}

.minicart-wrapper .block-minicart li {
    margin: 0
}

.minicart-wrapper .block-minicart li:hover {
    cursor: pointer
}

.minicart-wrapper .block-minicart:before, .minicart-wrapper .block-minicart:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0
}

.minicart-wrapper .block-minicart:before {
    border: 6px solid;
    border-color: transparent transparent #fff transparent;
    z-index: 99
}

.minicart-wrapper .block-minicart:after {
    border: 7px solid;
    border-color: transparent transparent #bbb transparent;
    z-index: 98
}

.minicart-wrapper .block-minicart:before {
    right: 31px;
    top: -12px
}

.minicart-wrapper .block-minicart:after {
    right: 30px;
    top: -14px
}

@include media-breakpoint-up(md) {
    .minicart-wrapper .block-minicart:before,
    .minicart-wrapper .block-minicart:after {
        right: 20%;
    }
}

.minicart-wrapper.active {
    overflow: visible
}

.minicart-wrapper.active .block-minicart {
    display: block
}

.minicart-wrapper .block-minicart {
    padding: 25px 20px
}

.minicart-wrapper .product-item {
    border-bottom: 1px solid $gray-220;
    padding-bottom: 10px;
}

.minicart-wrapper .product-item:last-child {
    border-bottom: none;
}

.minicart-wrapper .product .actions {
    text-align: right;
    margin: 0
}

.minicart-wrapper .product .actions > .primary, .minicart-wrapper .product .actions > .secondary {
    display: inline
}

.minicart-wrapper .product .actions > .primary:not(:last-child), .minicart-wrapper .product .actions > .secondary:not(:last-child) {
    margin-right: 15px
}


.minicart-wrapper .action.showcart .counter.qty.empty {
    display: none
}

.minicart-wrapper .action.showcart .counter.qty .loader > img {
    max-width: 24px
}

.minicart-wrapper .action.showcart .counter-number {
    text-shadow: 0 0 7px #000
}

.minicart-wrapper .minicart-widgets {
    margin-top: 15px
}

.minicart-items-wrapper {
    border: none;
    margin: 0 -20px;
    border-left: 0;
    border-right: 0;
    padding: 15px;
    overflow: auto;
}

.minicart-items {
    margin: 0;
    padding: 0;
    list-style: none none
}

.minicart-items .product-item:first-child {
    padding-top: 0
}

.minicart-items .product-item-pricing .label {
    display: inline-block
}

.minicart-items .price-minicart {
    margin-bottom: 5px
}

.minicart-items .product-item-name {
    color: $purple;
}

.minicart-items .product-item-name a {
    color: $purple
}

.minicart-items .product-item-details {
    padding-left: 88px
}

.minicart-items .product-item-details .price-including-tax, .minicart-items .product-item-details .price-excluding-tax {
    margin: 5px 0
}

.minicart-items .product > .product-item-photo, .minicart-items .product > .product-image-container {
    float: left
}

.minicart-items .product .toggle {
    border: 0;
    padding: 0 40px 5px 0
}

.minicart-items .product .toggle:after {
    color: #00345F;
    position: absolute;
    top: 0;
    right: 0;
}

.minicart-items .product .active > .toggle:after {
    content: '\e921'
}

.minicart-items .product.pricing {
    margin-top: 3px
}

.minicart-items .product.options .toggle.tooltip {
    display: inline-block;
    text-decoration: none
}

.minicart-items .product.options .toggle.tooltip > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}


.minicart-items .product.options .details {
    display: none
}

.minicart-items .subtitle {
    display: none
}

.minicart-items .action.edit, .minicart-items .action.delete {
    display: inline-block;
    text-decoration: none
}

.minicart-items .action.edit > span, .minicart-items .action.delete > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.minicart-items .action.edit:before, .minicart-items .action.delete:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
    content: '\e906';
    font-family: 'VacomIcon';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center
}

.minicart-items .action.edit:hover:before, .minicart-items .action.delete:hover:before {
    color: #333
}

.minicart-items .action.edit:active:before, .minicart-items .action.delete:active:before {
    color: #757575
}

.minicart-items .action.delete:before {
    content: '\e902'
}

.product.media .swiper {
    display: none;
}

.product-details-top .product-details-icon {
    display: none;
}

.choose-image .img_content-box__img {
    max-width: 150px !important;
    max-height: 130px !important;
}

@media (max-width: 639px) {
    .product.media .gallery,
    .product.media .thmubnails {
        display: none !important;
    }

    .product.media .swiper {
        display: block;
        margin-bottom: 50px;
    }

    .product-details-top .swiper-slide__image {
        overflow: inherit;
    }

    .product-details-top .pds-items-cart {
        font-size: 0;
    }
}

@media (max-width: 1401px) {
    .product-details-top .product-details-icon {
        display: inline-flex;
    }
}

/**
 * CHECKOUT CART
 */

#shopping-cart-table .product-item-photo > img {
  max-width: 150px;
}


/**
 * DETAILSPAGE
 */

.fotorama__img {
    max-width: 90px;
}

.fieldset>.field.required>.label:after,
.fieldset>.fields>.field.required>.label:after,
.fieldset>.field._required>.label:after,
.fieldset>.fields>.field._required>.label:after {
    content: '*';
    color: $black;
}

body.customer-logged-in {
    .country-switcher-simple,
    .push-menu__list--language-menu > li:first-child {
        display: none;
    }
}

/**
 * WISHLIST
 */
.towishlist:hover,
.towishlist:active {
    .vacomicon:before {
        content: "\e93f";
    }
}

.top_banner {
    .section__content {
        position: relative;
        height: 100%;
    }

    h3 {
        position: absolute;
        bottom: 10px;
        margin-bottom: 0;
    }
}


.joboffers {
    a.link.c-purple:hover {
        text-decoration: underline;
    }
}

body.account .top-navigation__item.my-account .vacomicon {
    color: $purple;
}

.checkout-index-index {
    header.page-header {
        box-shadow: none;
    }
    a.logo {
        text-align: center;
        margin-bottom: -42px;
        img {
            width: 85px;
        }
    }
}

table {
    th.total,
    td.total,
    th.price,
    td.price,
    th.subtotal,
    td.subtotal {
        text-align: right !important;
    }
}

.frame-type-mmsmagentoconnect_product_widget .product-tile-wrapper {
    @include media-breakpoint-between(sm, md) {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
}

.ce-textpic {
    .ce-gallery {
        .col {
            width: 100% !important;
        }
    }
}

.downloads {
    .frame-type-mmsvacomccintegration_text-teaser {
        height: 100%;
        > a.no-underline.w-100 {
            height: 100%;

            > .teaser {
                height: calc(100% - 30px);
            }
        }
    }
}