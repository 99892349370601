//-------------------------------------------------------------
// Footer Scroll top
//-------------------------------------------------------------

.scroll-top {
    background: white !important;

    .page-footer-container-top & {
        position: absolute;
        right: 15px;
        top: -90px;
        border-radius: 50%;
        width: 50px;

        @include media-breakpoint-up(md) {
            top: -105px;
        }

        .vacomicon {
            font-size: 10px;
        }
    }
}
