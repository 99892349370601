.infobox {
    border: none;
    border-radius: 0;
    padding: building-units(2) building-units(3);
    margin-bottom: building-units(3);
    color: $black;
    background-color: $white;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;

    &--info,
    &--notice {
        border-left: building-units(0.5) solid $purple;

        .vacomicon {
            color: $purple;
        }

        &.infobox--emphasized {
            background-color: rgba($gray-medium-2, 0.10);
        }
    }

    &--warning {
        border-left: building-units(0.5) solid $red;

        .vacomicon {
            color: $red;
        }

        &.infobox--emphasized {
            background-color: rgba($red, 0.10);
        }
    }


    &--error,
    &--alert {
        border-left: building-units(0.5) solid $orange;

        .vacomicon {
            color: $orange;
        }

        &.infobox--emphasized {
            background-color: rgba($orange, 0.10);
        }
    }

    &--success {
        border-left: building-units(0.5) solid $green;

        .vacomicon {
            color: $green;
        }

        &.infobox--emphasized {
            background-color: rgba($green, 0.10);
        }
    }

    .vacomicon {
        margin-right: building-units(1);
        top: 4px;
        bottom: 0;
    }

    &__wrapper {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
    }

    &__title {
        @include sans-bold-18();
        display: block;
    }

    &__text {
        @include sans-regular-18();

        a {
          color: $black;
          text-decoration: underline;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }
}
