//-------------------------------------------------------------
// banner
//-------------------------------------------------------------
// Variables

.banners-content {
    position: relative;
    width: 100%;
    height: 400px;
    background-color: #f5f5f5;

    margin-bottom: building-units(2);
    overflow: hidden;

    &__image {
        width: 100%;
        height: 100%;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        @include make-col(12, 12);
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        padding: building-units(2);
        background-color: rgba($purple, 1);

        @include media-breakpoint-up(sm) {
            @include make-col(6, 12);
            padding: building-units(3);
            background-color: rgba($purple, 0.8);
            height: 100%;
        }

        @include media-breakpoint-up(lg) {
            @include make-col(5, 12);
        }
    }

    &__subline {
        @include pt-sans-regular-14();
        margin-bottom: building-units(0.5);
        color: $white;
        display: block;

        @include media-breakpoint-up(md) {
            margin-bottom: building-units(1);
        }
    }

    &__headline {
        @include serif-regular-26();
        margin: 0 0 building-units(0.5) 0;
        color: $white;

        @include media-breakpoint-up(md) {
            margin: 0 0 building-units(1) 0;
        }
    }

    &__introduction {
        @include sans-regular-18();
        margin-bottom: building-units(2);
        color: $white;

        @include media-breakpoint-up(md) {
            margin-bottom: building-units(4);
        }
    }

    @include media-breakpoint-down(xs) {
        height: auto;

        .banners-content__image {
            height: 400px;
            background-size: cover;
            background-position: top center;

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                /* IE10+ CSS styles go here */
                position: relative;
                top: -50px;
            }
        }

        .banners-content__content {
            position: inherit !important;
            @include make-col(12, 12);
        }
    }

    &--small {
        height: 300px;

        @include media-breakpoint-down(xs) {
            height: auto;

            .banners-content__image {
                height: 300px;
            }
        }
    }
}
