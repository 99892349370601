
.pagination {
    &--center {
        text-align: center;
    }

    &--right {
        text-align: right
    }

    &__list {
        list-style: none;
        margin: rem(12px) 0;
        display: inline-flex;
        flex-flow: row wrap;
        text-align: center;
        padding-left: 1px;
    }
  
    &__item {
        border: 1px solid $pagination-item-border-color;
        margin-left: -1px;
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
        
        &:hover,
        &:focus-within {
            background: $pagination-item-hover-background-color;
            border-color: $pagination-item-hover-border-color;
            position: relative;
            z-index: 1;
        }

        &.active {
            background: $pagination-item-active-background-color;
            //border-color: $pagination-item-active-border-color;
            .pagination__link {
                color: $pagination-item-active-text-color;
                cursor: default;
            }
            z-index: 2
        }

        &.disabled {
            background: $pagination-item-disabled-background-color;
            //border-color: $pagination-item-disabled-border-color;
            .pagination__link {
                color: $pagination-item-disabled-text-color;
            }
    
        }
    }

    &__prev {
        border-radius: rem(4px) 0 0 rem(4px);
        display: block;
    }
    
    &__next {
        border-radius: 0 rem(4px) rem(4px) 0;
        display: block;
    }

    .teleicon {
        font-size: rem(15px);
    }

    &__link {
        display: block;
        padding: rem(6px) rem(12px);
        color: $pagination-text-color;
        @include reset-hover();

    }
}


