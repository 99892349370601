//-------------------------------------------------------------
// Input
//-------------------------------------------------------------
// Variables
$input-bg-color: $white;
$input-br-color: $gray-medium-2;
$input-color: $black;

$input-br-color-state: $black;
$input-bg-color-active: $white;
$input-box-shadow: $form-elements-box-shadow;
$input-box-shadow-color-focus: $blue-rgba-50;
$input-placeholder-color: $gray-medium-2;
$input-icon-color: $black;

$input-br-radius: 0;
$input-height: building-units(5);
$input-padding: building-units(1.5);
$input-elements-padding: building-units(0.5);
$input-icon-size: building-units(2);
$input-margin: building-units(1.5);

$file-upload-default: $purple;
$file-upload-br-default: $gray-medium-1;
$file-upload-hover: $purple;
$file-upload-active: $black;
$file-upload-error: $red;
$file-upload-bg-error: rgba($color-error, .05);
$file-upload-disabled: $white;
$file-upload-icon-disabled: $gray-medium-1;

.field-error {
    @include pt-sans-regular-14();

    display:     block;
    padding-top: $input-elements-padding;
    color:       $file-upload-error;
}

// Global Styles
.form-input-set {
    @include sans-regular-18();

    width: 100%;
    margin-bottom: building-units(1.34);

    input.form-input {
        height: $input-height;
    }

    input::Placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      @include sans-regular-18();
      color: $input-placeholder-color !important;
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      @include sans-regular-18();
      color: $input-placeholder-color !important;
    }

    // hide spinner in webkit browsers
    [type=number] {
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    %icon-lock {
        cursor: pointer;
        position: absolute;
        bottom: 15px;
        left: 15px;
        margin-right: 10px;
    }

    /************************************
     * INPUT GROUP // WRAPPER
     ************************************/
    .input-group {
        display: block;
        margin-bottom: $input-margin;
    }

    .input-group.has-error {
        position: relative;
    }

    /************************************
     * STANDARD INPUT
     ************************************/
    .form-input {
        display: block;
        width: 100%;
        padding: building-units(1) $input-padding;
        background-color: $input-bg-color;
        border-radius: $input-br-radius;
        border: 1px solid $input-br-color;
        @include sans-regular-18();
        color: $input-color;

        &:hover {
            border-color: $input-br-color-state;
            background-color: $input-bg-color-active;
        }

        &:focus {
            outline: 0;
            border-color: $input-br-color-state;
            box-shadow: none;
        }

        &:active {
            color: $black;
            background-color: $input-bg-color-active;
            border-color: $input-br-color-state;
            box-shadow: none;

            &:hover {
                border-color: $input-color;
            }
        }

        &:disabled {
            color: $input-placeholder-color;
            border-color: $gray-medium-1;
            background-color: rgba( $gray-medium-1, .1 );
            cursor: not-allowed;

            &:hover {
                background-color: rgba( $gray-medium-1, .1 );
                border-color: $gray-medium-1;
                cursor: not-allowed;
            }

            &:active {
                background-color: rgba( $gray-medium-1, .1 );
            }
        }
    }

    /************************************
     * ICON INPUT - LSG
     ************************************/
    .icon-input {
        position: relative;

        .form-input {
            padding-right: building-units(3);

            &:disabled {
                ~ .vacomicon {
                    opacity: .3;
                    cursor:  default;
                }
            }
        }

        .vacomicon {
            cursor:   pointer;
            position: absolute;
            bottom:   building-units(1.5);
            right:    building-units(1.5);

            &::before {
                font-size: $input-icon-size;
                color:     $input-icon-color;
            }

            &.icon-search::before {
                font-weight: $font-weight-normal;
                color:     $gray-medium-2;
            }
        }
    }

     /************************************
     * FILE INPUT
     ************************************/
    .file-upload {
        position: relative;

        //hidden input field
        &__input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
            cursor: pointer;
        }

        //label, which is now the "placeholder"
        &__label {
            display: flex;
            flex-flow: row;
            height: building-units(5);
            cursor: pointer;

            &:hover,
            &:focus,
            &.has-focus {
                border-color: $file-upload-hover;

                .file-upload__button {
                    background-color: $file-upload-hover;
                }
            }

            &:active {
                border-color: $file-upload-active;

                .file-upload__button {
                    background-color: $file-upload-active;
                }
            }
        }

        &__button {
            width: 86px;
            height: 50px;
            border: 1px solid $file-upload-default;
            color: $white;
            background-color: $file-upload-default;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;

            display: inline-flex;
            flex-flow: row;
            justify-content: center;
            align-items: center;

            > .icon-upload {
                font-size: 24px;
            }
        }

        .validation-message {
            display: none;
        }

        &__placeholder {
            color: $gray-medium-1;
            text-transform: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            vertical-align: top;
            font-weight: 400;
            padding: 10.5px 15px;

            width: calc(100% - 91px);
            height: 100%;
            border: 1px solid $file-upload-br-default;
            border-radius: 10px 0 0 10px;
        }

        /// Error
        &.has-error {
            .file-upload__placeholder {
                color: $file-upload-error;
                border: 1px solid $file-upload-error;
                background-color: $file-upload-bg-error;
                padding-right: building-units(2);

                &:hover,
                &:focus,
                &.has-focus {
                    border-color: $file-upload-error;
                }

                &:active {
                    border-color: $file-upload-error;
                }

                .icon-upload {
                    background-color: $file-upload-error;
                }
            }

            .file-upload__button {
                background-color: $red;
                border-color: $red;

                > .icon:before {
                    color: $white;
                }
            }

            .validation-message {
                display: block;
            }

            &:after {
                top: 38px !important;
                margin-right: 90px;
            }
        }

        /// Disabled
        &--disabled {
            .file-upload__label {
                cursor: not-allowed;

                &:hover,
                &:focus,
                &.has-focus,
                &:active {
                    border-color: $file-upload-disabled;
                }

                .icon-upload {
                    color: $file-upload-icon-disabled;
                }
            }

            .file-upload__placeholder {
                border-color: $file-upload-disabled;
            }

            .file-upload__button {
                border-color: $file-upload-disabled;
                background-color: $file-upload-disabled !important;
            }

            .validation-message {
                display: none;
            }
        }
    }

    /************************************
     * ERROR VALIDATION
     ************************************/
    .validation-message {
        display: none;
    }

    .has-error,
    ._error {
        .validation-message {
            display: block;
        }

        .vacomicon {
            color: $color-error;
        }

        label.label {
            color: $color-error;
        }

        .form-input {
            background-color: rgba($color-error, .05);
            color: $color-error;

            &:active:focus:hover,
            &:focus,
            &:hover,
            &:active,
            &:visited {
                border-color: $color-error;
                background-color: rgba($color-error, .05);
                color: $color-error;

                ~ .icon-lock {
                    color: $color-error;
                }
            }
        }

        &.input-group {
            position: relative;

            &:after {
                @extend %vacomicon;
                content: '\e936';
                position: absolute !important;
                top: 48px;
                right: building-units(3.5);
                height: 18px;
                width: 18px;
                color: $color-error;
                @include media-breakpoint-down(lg) {
                  top: 45px;
                }
            }

            &.select:after {
                right: -23px;
            }
        }
    }
}

fieldset .input-group._error:after {
    top: 13px !important;
}

input::-ms-reveal {
    display: none;
}

@import "input-validation-vc";
@import "input-addon-vc";
@import "input-link-vc";
