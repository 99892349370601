.expand-block {
  &__switcher {
    margin-top: 1rem;
    display: inline-block;

    .switch {
      margin: 0;
    }
  }
}
