//-------------------------------------------------------------
// Input Validation Styling
//-------------------------------------------------------------

// Error Styling
.has-error {
    @include set-input-validation-styling($input-error-background-color, $input-error-hover-background-color, $input-error-color);
}

// Success Styling
.has-success {
    @include set-input-validation-styling($input-success-background-color, $input-success-hover-background-color, $input-success-color);
}
