//-------------------------------------------------------------
// Selectbox
//-------------------------------------------------------------

// Selectbox stylings
.selectbox {
    position: relative;
    display: inline-block;

    .form-input-search {
        border-bottom: 1px solid $optionlist-border-color;
        padding:       building-units(1 / 6) 0;
        margin-bottom: building-units(1);

        &::before {
            display: none;
        }

        .form-input {
            border:        0;
            padding:       0 building-units(.5);
            height:        building-units(8 / 3);
            width:         100%;
            margin-bottom: 0;
        }
    }

    .empty-result-entry {
        color:   $optionlist-color;
        padding: building-units(1);
    }

    &-label {
      color: $optionlist-color;
      vertical-align: middle;
      display: inline-flex;
      padding: rem(6px) rem(12px);
      background-color: $button-secondary-background-color;
      border: 1px solid $button-secondary-border-color;
      border-radius: rem(4px);
      white-space: nowrap;
      line-height: $line-height-base;

      @include reset-hover();

      &:hover,
      &:focus,
      &:focus-within {
        background-color: $gray-220;
      }

      &::after {
        @extend %teleicon;
        @extend .icon-navigation-down::before;

        font-size:    16px;
        padding-left: 6px;
        margin-left: auto;
        align-self: center;
      }
    }

    &-optionlist {
      list-style-type: none;
      margin:          0;
      padding:         0;
      display:         none;
      position:        absolute;
      top:             calc(100% + #{building-units(.5)});
      left:            0;
      min-width:       building-units(15);
      background:      $optionlist-background;
      border-radius:   building-units(.5);
      z-index:         5;
      border:          1px solid $optionlist-border-color;
      font-size: inherit;
      overflow: hidden;

      &.optionlist-open {
        display: block;
      }

      a {
        color:           $optionlist-color;
        text-decoration: none;
        padding:         building-units(1);
        display:         block;

        &:hover,
        &:focus,
        &:focus-within {
          background: $optionlist-hover-background-color;
        }

        &.selectbox-selected {
          background: $optionlist-select-background-color;

          &:hover,
          &:focus,
          &:focus-within {
            background: $optionlist-select-hover-background-color;
          }
        }

        &.option-inactive {
          color: $optionlist-inactive-text-color;
          cursor: no-drop;
        }
      }
    }

    &--inactive {
      .selectbox-label {
        color: $optionlist-selectbox-label-inactive-text-color;
        background-color: $optionlist-selectbox-label-inactive-background-color;
        border-color: $optionlist-selectbox-label-inactive-border-color;
        cursor: no-drop;
      }
    }

    &--alert {
      .selectbox-label {
        background-color: $optionlist-selectbox-label-alert-background-color;
        border-color: $optionlist-selectbox-label-alert-border-color;

        &:hover,
        &:focus,
        &:focus-within {
          background-color: $optionlist-selectbox-label-alert-focus-background-color;
        }
      }
    }

    &--open-left {
      .selectbox-optionlist {
        left: auto;
        right: 0
      }
    }

    &--open-center {
      .selectbox-optionlist {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-hidden {
      display: none;
    }

    &--dark {
      .selectbox-label {
        color: $optionlist-selectbox-label-dark-text-color;
      }
    }
}