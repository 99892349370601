//-------------------------------------------------------------
// Accordion
//-------------------------------------------------------------
// Variables

.acc_content2 {

    .modal & {

      display: block;
      overflow: hidden;
      width: 100%;

      &__headline {
        display:          flex;
        flex-direction:   row;
        flex-wrap:        nowrap;
        justify-content:  flex-start;
        align-items:      center;

        width:            100%;
        cursor:           pointer;
        text-decoration:  none;
        min-height:       building-units(2);
        padding:          building-units(1) 0;

        border-top:    1px solid $gray-medium-1;
        border-bottom:    1px solid $gray-medium-1;

        margin-top: -1px;

        @include reset-hover();

        > .vacomicon {
          line-height: $line-height-h3;
          margin-right: building-units(0.5);
        }

        .vacomicon {

          font-size: 8px;
        }

        .icon-arrow-down {

          transform: rotate(180deg);
        }
      }

      &__title {

        display:        block;
        color:          $black;
        letter-spacing: 0;
        margin-bottom:  0;
        padding:        0;

        &:hover  {
          color: $purple;
        }
      }

      &__subline {
        margin-top: building-units(0.5);
      }

      &__toggle {
        width:              64px;
        text-decoration:    none;
        color:              $black;
        margin-left:        auto;
        align-content:      center;
        display:            flex;
        justify-content:    center;
        align-items:        center;
        flex:               0 16px;
        min-width:          16px;
        line-height:        rem(20px);

        .icon-small-arrow-up {
          display: none
        }

        .icon-arrow-down {
          display: inline-block
        }
      }

      &__content {
        font-size:          $font-size-default;
        padding:            0 building-units(1);
        max-height:         0;
        overflow:           hidden;

        p:last-child {
          margin-bottom: 0;
        }

        @include media-breakpoint-down(sm) {
          font-size: $font-size-text-small;
        }
      }
    }

    &--open {

        .modal & {

          > .acc_content2__headline {
            border-bottom-width: 1px !important;
          }

          .acc_content2__title {
            color: $purple;
          }

          > .acc_content2__content {
            padding: building-units(1) building-units(1);
            max-height: 99999px;
          }

          > .acc_content2__headline > .acc_content2__toggle {

            > .icon-small-arrow-up {
              display: inline-block;
            }

            > .icon-arrow-down {
              display: none;
            }
          }

          ul {

            list-style-type: none;
          }

          ul, li {
            padding: 0;
            margin: 0;
          }

          li {

            &:not(:last-child) {

              a {

                margin-bottom: building-units(1);
              }
            }
          }

          a {

            display: block;
            font-size: $font-size-text-small;

            &:hover {

              text-decoration: underline;
            }
          }
        }
    }

    &--inactive {
        color: $gray-medium-2;

        .acc_content2__headline,
        .acc_content2__title,
        .acc_content2__toggle,
        .acc_content2__subline {
            color: $gray-medium-2;
        }

        .acc_content2__headline,
        .acc_content2__toggle:hover {
            cursor: no-drop;
            color: $gray-medium-2;

            .vacomicon, .acc_content2__title {
                color: $gray-medium-2;
            }
        }
    }

    &:not(:last-child) .acc_content2__headline {
        border-bottom-width: 0;
    }
}

.modal--open {

  .top-navigation__listitem {

    &:first-child {

      @include pt-sans-regular-14();
    }

    &:not(:first-child) {

      .acc_content2__title {

        @include pt-sans-bold-14();
      }
    }
  }
}
