.horizontal-scroller-container {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.horizontal-scroller-wrapper {
  flex: 1 100%;
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}
  
.horizontal-scroller__inner {
    width: min-content;
    min-width: 100%;
    // Above doesn't work in IE. We fake it with float. 
    float: left;
}

.horizontal-scroller {
  &__nav {
    @extend %horizontal-nav;

    font-size: inherit;
  }

  &__nav-item {
    padding: 0 12px;
  }

  &__link {
    @include reset-hover;

    &--active {
        font-weight: bold;
    }
  }
}

$horizontal-scroller-btn-width:        building-units(2.5);
$horizontal-scroller-btn-height:       100%;


.horizontal-scroller-btn {
  position: relative;
  background: $horizontal-scroller-btn-background-color;
  border: 0;
  cursor: not-allowed;
  width: $horizontal-scroller-btn-width;
  flex: 1 $horizontal-scroller-btn-width;
  z-index:    2;
  color:      rgba($horizontal-scroller-btn-color, 0.3);
  transition: color .2s ease;

  &:focus,
  &:active {
    border:  0;
    outline: 0;
  }

  &:hover {
    transition: color .2s ease;
  }

  &.active {
    color:      rgba($horizontal-scroller-btn-color, 1);
    cursor:     pointer;
  }
}

.horizontal-scroller-btn-prev,
.horizontal-scroller-btn-next {
  display: none;

  .show-controls & {
    display: block;
  }
}
