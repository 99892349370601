//-------------------------------------------------------------
// General
//-------------------------------------------------------------

* {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing:  antialiased;
}

// Set the rem baseline to 1 Building Unit
html {
    @include rem-baseline;
}

body {
    @extend %text-default;

    font-family: $font-family-base;
    color: $text-color;
    background: $site-background-color;
    font-size: rem($font-size-base);
    line-height: rem($line-height-base);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

p {
    margin: 0 0 rem(15px)
}
