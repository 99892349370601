//-------------------------------------------------------------
// Tabbar-Dropdown
//-------------------------------------------------------------

.tabbar-dropdown-container {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.tabbar-dropdown {
  width: 100%;
  padding-top: rem(12px);

  .selectbox-label, .selectbox-optionlist {
    width: 100%;
  }

  
}