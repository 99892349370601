//-------------------------------------------------------------
// IMAGE HERO
//-------------------------------------------------------------

// Variables

.hero_img {
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: right;
    max-width: unset;
    min-height: 50%;
    margin: 0 auto;
    width: 1024px;
    left: -30px;

    @include media-breakpoint-up(sm) {
        min-height: 350px;
    }

    @media (max-width: 1023px) {
        width: 100%;
        left: unset;
    }

    @media (min-width: 1024px) {
        width: 1024px;
    }

    @media (min-width: 1400px) {
        width: 1400px;
    }

    @media (min-width: 1600px) {
        width: 1600px;
    }

    &__small {
        @extend .hero_img;
        min-height: 105px;
    }

    /**
     * Elements
     */
    &__wrapper {
        @extend .container;

        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;

        @include media-breakpoint-down(md) {
            flex-flow: wrap;
            padding: 0 15px;
        }

        @include media-breakpoint-up(md) {
            padding: 0 building-units(3);
        }
    }

    &__content {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: stretch;

        @include media-breakpoint-up(sm) {
            height: 350px;
        }
        padding: building-units(4) 0;

        @include media-breakpoint-only(xl) {
            width: percentage(5 / 12);
        }

        @include media-breakpoint-only(lg) {
            width: percentage(6 / 12);
        }

        @include media-breakpoint-only(md) {
            width: percentage(8 / 12);
        }

        @include media-breakpoint-only(sm) {
            width: percentage(8 / 12);
        }

        @include media-breakpoint-only(xs) {
            width: percentage(12 / 12);
        }
    }

    &__company {
        margin: 0 0 building-units(3) 0;
        display: flex;
        align-items: center;
    }

    &__company-logo {
        max-width: building-units(8);
        max-height: building-units(4);
        height: auto;
        margin: 0 building-units(2) 0 0;
    }

    &__company-label {
        align-items: center;
        display: flex;
        // extends small text
        font-size: $font-size-default;
        line-height: $line-height-default;

        @include media-breakpoint-only('xs') {
            font-size: $font-size-text-small;
            line-height: $line-height-text-small;
        }
    }

    &__company-label-img {
        width: 32px;
        height: 32px;
        font-size: 32px;
        display: inline-block;
        margin-right: 10px;
        color: $blue;

        @include media-breakpoint-only('xs') {
            width: 24px;
            height: 24px;
            font-size: 24px;
        }
    }

    &__title {

        @include serif-regular-26();

        color: $white;
        margin: 0;
        text-transform: none;

        .hero_img--dimmer-light & {

            color: $purple;
        }
    }

    &__text {

        @include sans-regular-18();

        color: $white;
        margin-top: building-units(1);

        .hero_img--dimmer-light & {
            color: $black;
        }
    }

    &__card {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;

        padding: building-units(4) building-units(1);
        min-width: percentage(4 / 12);
        max-width: percentage(5 / 12);

        @include media-breakpoint-down(md) {
            height: auto;
            margin-top: building-units(1);
            padding: 0 0 building-units(2);
            min-width: percentage(8 / 12);
            max-width: percentage(8 / 12);
        }

        @include media-breakpoint-only(sm) {
            min-width: percentage(10 / 12);
            max-width: percentage(10 / 12);
        }

        @include media-breakpoint-only(xs) {
            min-width: percentage(12 / 12);
            max-width: percentage(12 / 12);
        }

        .card-vc {
            margin-bottom: 0;
            color: $blue;
        }
    }

    /**
     * MODIFIER
     */
    &--dimmer-light:after,
    &--dimmer-light-gradient:after,
    &--dimmer-dark:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .6;
    }

    &--dimmer-light:after {
        background-color: rgba($white, .8);
    }

    &--dimmer-dark {
        color: $white;

        &:after {
            background: rgb(0, 0, 0);
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
        }
    }

    &--dimmer-light-gradient:after {
        /*background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);*/
    }


    &--small {
        min-height: 120px;
        background-size: cover;

        .hero_img__content {
            height: 120px;
        }

        .hero_img_text {
            display: none;
        }
    }

    &--large {
        background-size: cover;

        .hero_img__content {
            height: 800px;
        }

        @include media-breakpoint-down(lg) {
            height: 600px;
            margin-bottom: 320px;

            .hero_img__content {
                height: 600px;
            }
        }

        @include media-breakpoint-only(xs) {
            height: 350px;
            margin-bottom: 350px;

            .hero_img__content {
                height: 350px;
            }
        }
    }
}
