//-------------------------------------------------------------
// PushMenu
//-------------------------------------------------------------

@mixin main-nav-active() {

    @include pt-sans-bold-14();

    &:after {

        content: "";

        position: absolute;
        bottom: -12px;
        left: 0;
        z-index: 1;

        border-bottom: 1px solid $black;

        height: 1px;
        width: 100%;
    }
}

.push-menu {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #fff;
    height: 100%;
    width: 100%;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.35s;
    overflow-y: auto;
    overflow-x: hidden;

    &__wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
    }

    &__header {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding: 10px building-units(1);
        background: white;

        position: relative;

        &:after {

            content: "";
            border-bottom: 1px solid #e6e6e6;

            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;

            margin: auto;

            width: calc(100% - 20px);
            height: 1px;
        }

        .vacomicon {

            color: $black;

            &:hover {
                color: $white-light;
            }
        }
    }

    &__list {
        background: white;
    }

    .vacomicon {

        color: $black;

        &:hover {

            color: $black;
        }
    }

    .navbar-mobile-menu__close {

        align-self: initial;
        color: inherit;
        margin: 0 5px 0 0;


    }

    &__close {
        color: $black;
    }

    /**
     * QUICKLINKS
     */
    &__directlinks {

        .push-menu__list & {
            border-bottom: none;
            max-height: initial;
            padding: 0;
            margin: building-units(3) 0 0 0;
        }
    }

    &__quicklinks {

        font-size: 0;

        h6 {

            @include pt-sans-regular-14();

            margin: 0 0 building-units(1.5) 0;
            padding: 0;

            color: $gray-medium-2;
        }
    }

    &__quicklinks-link {
        @extend %text-small;

        color: $black;

        display: inline-block;
        margin-right: building-units(3);

        &:hover {

            color: $purple;
        }
    }

    /************************************
    * Main Nav
    ************************************/
    .main-nav {

        list-style-type: none;
        margin: 0;
        padding: 0;

        font-size: 0;

        width: 100%;

        &__list-item {

            @include pt-sans-regular-14();
            line-height: 30px;

            display: inline-block;
            padding: 0;
            margin: 0;

            cursor: pointer;

            position: relative;

            &:not(:last-child) {
                margin: 0 building-units(2) 0 0;
            }

            @media (min-width: 1024px) {

                &:hover {

                    @include main-nav-active();
                    line-height: 31px;
                }
            }
        }

        &__list-item--active {

            @include main-nav-active();
            line-height: 30px;
        }
    }

    /************************************
    * Language Menu
    ************************************/

    &__list--language-menu,
    &__list--navigation-menu {

        display: none;
        height: 100%;

        a {
            @include pt-sans-regular-14();
            text-transform: initial;
        }

        li a {

            &:focus,
            &.active,
            &:focus-within {
                color: $purple;
            }

            //separate because of IE
            &:hover {
                color: $purple;
            }
        }

        .push-menu__toggle {

            cursor: inherit;

            color: $white;

            &:focus,
            &.active,
            &:focus-within {
                color: $white;
            }

            &:hover {
                color: $white;
            }
        }

        .push-menu__link--active {

            .vacomicon {

                color: $purple;
                font-size: 10px;

                margin-left: building-units(1);
                bottom: -4px;
            }
        }
    }

    &__list--language-menu {
        .push-menu__label > label.label {
            display: inline-block;
            margin-bottom: 0;
        }
    }

    &__list--language-menu-open {

        display: block;
    }

    &__list--navigation-menu-open {

        display: block;
    }

    /************************************
     * TEASER
     ************************************/
    &__teaser {
        display: flex;
        flex-flow: row;
        border-bottom: 1px solid $gray-medium-1;
        width: 100%;
        padding: 18px 7.5px;

        @include media-breakpoint-down(sm) {
            flex-flow: column;
        }

        &:hover {
            color: $blue; //1066
            .push-menu__teaser-description {
                //  color: $blue;
            }
        }
    }

    &__teaser-image {
        border-radius: 8px;
        width: 100%;
        max-width: 304px;
        margin-right: building-units(1);

        @include media-breakpoint-down(sm) {
            margin-bottom: building-units(1);
        }

        @include media-breakpoint-only(xs) {
            max-width: 100%;
        }
    }

    &__teaser-content {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        max-width: 50%;
    }

    &__teaser-title {
        font-weight: 400;
        font-size: $font-size-text-small;
        color: $blue;
        letter-spacing: 0;
        line-height: 1;
        display: block;
        margin-bottom: building-units(1);
    }

    &__teaser-description {
        @include media-breakpoint-down(md) {
            font-size: 13px;
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    /************************************
     * REST
     ************************************/
    &__toggle {

        @include pt-sans-bold-14();
        line-height: 30px;
        text-transform: uppercase;

        width: 70%;
        height: 30px;
        display: block;
        margin-bottom: 0;
        text-align: center;

        color: $black;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        cursor: pointer;

        &:hover {
            color: $black;
        }
    }

    &__list {
        list-style: none;
        padding: 0 building-units(1);
    }

    &__listitem {
        display: flex;
        flex-flow: row;
        align-items: center;

        border-bottom: 1px solid #e6e6e6;

        max-height: 50px;
        padding: 14px 0;
        margin: 0;
    }

    &__listitem--teaser {
        flex-flow: column;
    }

    &__label {
        width: 100%;
        margin-bottom: 0;
        justify-content: space-between;

        > a, label.label {
            width: 100%;
        }
    }

    &__icon {
        position: inherit;
        cursor: pointer;
        font-size: 15px;
        margin-bottom: 0;

        &:hover {
            color: $white-light;
        }

        &:before {
            top: 3px;
            right: 5px;
            position: relative;
        }
    }

    &__link,
    &__label {
        @include pt-sans-bold-14();
        display: flex;
        text-align: left;
        padding: 0;
        text-decoration: none;
        text-transform: none;

        width: 100%;
        height: 100%;
        cursor: pointer;

        &:hover {
            color: $white-light;
        }
    }

    &__checkbox {
        display: none;
    }

    /* fade in checked menu */
    &__checkbox:checked + .push-menu {
        transform: translate3d(0, 0, 0);
    }
}

/**
 * level 1
 */
.push-menu .push-menu__label {

    @include pt-sans-bold-14();
    text-transform: uppercase;

    .icon-small-arrow-top {

        margin-right: 3px;
    }
}

/**
 * level 2
 */
.push-menu .push-menu .push-menu__label {

    @include pt-sans-regular-14();

    text-transform: none;
    font-weight: normal;

    .icon-small-arrow-top {

        margin-right: 9px;
    }
}

/**
 * level 3
 */
.push-menu .push-menu .push-menu .push-menu__label {

    @include pt-sans-regular-14();
    font-weight: normal;
}

.push-menu .push-menu .push-menu a {

    @include pt-sans-regular-14();
    font-weight: normal;

    &:hover {

        color: $purple;
    }
}
