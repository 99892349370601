//-------------------------------------------------------------
// Search Product List
//-------------------------------------------------------------

$product-list-image-width-height: 100px;
$product-list-image-width-height-lg: 120px;
$product-list-image-border: $gray-light;
$product-list-order-color: $gray-medium-2;
$product-list-headline-color: $purple;
$product-list-show-more-color: $purple;

.product-list {
    display: flex;
    flex: 1 1 100%;
    margin-bottom: building-units(1);

    @include media-breakpoint-up(md) {
        flex: 1 1 50%;
        margin-bottom: building-units(2);
    }

    &:nth-child(n+5) {
        display: none;
    }

    &-container {
        display: flex;
        flex-wrap: wrap;
        line-height: 1;

        .search-suggestion--no-result & {
            display: none;

            + .product-list__show-more {
                display: none;
            }
        }
    }

    &__headline {
        @include serif-regular-20();
        color: $product-list-headline-color;
        text-transform: uppercase;
        margin-bottom: 0px;
        max-width: 320px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__subheadline {
        @include pt-sans-regular-14();

        max-width: 320px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__order-nr {
        @include pt-sans-regular-14();
        color: $product-list-order-color;
    }

    &__image {
        max-width: calc(#{$product-list-image-width-height} - 10px);
        height: auto;

        @include media-breakpoint-up(md) {
            max-width: calc(#{$product-list-image-width-height-lg} - 10px);
        }
    }

    &__image-container {
        display: inline-block;
        width: $product-list-image-width-height;
        height: $product-list-image-width-height;
        border: 1px solid $product-list-image-border;
        margin-right: building-units(1);

        @include media-breakpoint-up(md) {
            width: $product-list-image-width-height-lg;
            height: $product-list-image-width-height-lg;
        }
    }

    &__image-inner-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__detail-container {
        display: inline-block;
        vertical-align: top;
        line-height: 1;
    }

    &__show-more {
        @include sans-regular-18();
        color: $product-list-show-more-color;
        cursor: pointer;
        display: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__show-more--visible {
        display: inline-block;
    }
}


