//-------------------------------------------------------------
// Tabbar_2
//-------------------------------------------------------------

$tabbar-container-height: auto;
$tabbar-container-br-color: $gray-56;

.tabbar_new {
    .dropdown {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &-container {
        display: inline-block;
        height: auto;
        width: 100%;
    }

    .tabbar {
        &-list {
            width: 100%;

            li {

                &:before {
                    content: none;
                    border: none !important;
                }

                &.active {
                    a {
                        @include pt-sans-bold-18();
                        color: $black;
                    }

                    border-bottom: 1px solid $black;

                    &:before {
                        content: none;
                        border-bottom: none !important;
                    }
                }

                &.inactive {
                    a {
                        color: $gray-medium-2;
                    }

                    &:hover {
                        a {
                            color: $gray-medium-2 !important;
                        }

                        border-bottom: 1px solid $gray-medium-1;
                    }
                }

                &:hover {
                    a {
                        color: $black !important;
                    }

                    border-bottom: 1px solid $black;
                }
            }
        }

        &__tab {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid $gray-medium-1;
            background-color: $white;
        }

        &-link {
            @include pt-sans-regular-18();
            padding: 0 building-units(2);
            color: $black;

            &:hover {
                color: $black;
            }

            &.dropdown__link {
                text-align: left;
                align-items: flex-start;
                justify-content: center;
            }

            &.dropdown__toggle {
                border: none;

                &:after {
                    content: '';
                    display: none;
                }
            }
        }
    }
}

.tabbar-container {
    display: inline-block;
    height: $tabbar-container-height;

    &:before {
        display: block;
        content: "";
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: 3px solid $tabbar-container-br-color;
    }
}

@import "tabbar-list-vc";
@import "tabbar-link-vc";
@import "tabbar-content-vc";
@import "tabbar-dropdown-vc";
