//-------------------------------------------------------------
// VacomIcon
//-------------------------------------------------------------

@font-face {
  font-family: 'VacomIcon';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$icon-font-path}/vacomicon.eot');
  src: url('#{$icon-font-path}/vacomicon.eot?#iefix') format('embedded-opentype'),
  url('#{$icon-font-path}/vacomicon.woff') format('woff'),
  url('#{$icon-font-path}/vacomicon.ttf') format('truetype'),
  url('#{$icon-font-path}/vacomicon.svg#vacomicon-outline') format('svg');
}

%vacomicon {
  position:                relative;
  bottom:                  rem(1px);
  vertical-align:          middle;
  display:                 inline-block;
  font-family:             'VacomIcon';
  font-size:               rem(15px);
  font-style:              normal;
  font-weight:             400;
  line-height:             1;
  speak:                   none;
  text-transform:          none !important; // if icon is used in uppercase context
  -webkit-font-smoothing:  antialiased; // reduce font weight
  -moz-osx-font-smoothing: grayscale; // reduce font weight
}

.vacomicon,
a.link .vacomicon {
    @extend %vacomicon;

    @each $size in $icon-sizes {
        &.icon-#{$size} {
            font-size: rem($size + px);
        }
    }

    &.purple {
      color: $purple;

      &:hover {
        color: $black;
      }
    }
}

// Icons
.vacomicon.icon-benefits:before {
  content: "\e941";
}
.vacomicon.icon-color:before {
  content: "\e942";
}
.vacomicon.icon-contact-2:before {
  content: "\e943";
}
.vacomicon.icon-control:before {
  content: "\e944";
}
.vacomicon.icon-corporate-design:before {
  content: "\e945";
}
.vacomicon.icon-doc:before {
  content: "\e946";
}
.vacomicon.icon-emergency:before {
  content: "\e947";
}
.vacomicon.icon-eqms:before {
  content: "\e948";
}
.vacomicon.icon-fair:before {
  content: "\e949";
}
.vacomicon.icon-faq:before {
  content: "\e94a";
}
.vacomicon.icon-font:before {
  content: "\e94b";
}
.vacomicon.icon-graph:before {
  content: "\e94c";
}
.vacomicon.icon-group:before {
  content: "\e94d";
}
.vacomicon.icon-individual:before {
  content: "\e94e";
}
.vacomicon.icon-internal-job:before {
  content: "\e94f";
}
.vacomicon.icon-locations:before {
  content: "\e950";
}
.vacomicon.icon-merch:before {
  content: "\e951";
}
.vacomicon.icon-money:before {
  content: "\e952";
}
.vacomicon.icon-on-offboarding:before {
  content: "\e953";
}
.vacomicon.icon-operation:before {
  content: "\e954";
}
.vacomicon.icon-repair:before {
  content: "\e955";
}
.vacomicon.icon-safety:before {
  content: "\e956";
}
.vacomicon.icon-social-media:before {
  content: "\e957";
}
.vacomicon.icon-special-components-2:before {
  content: "\e958";
}
.vacomicon.icon-square:before {
  content: "\e959";
}
.vacomicon.icon-support-2:before {
  content: "\e95a";
}
.vacomicon.icon-support-question:before {
  content: "\e95b";
}
.vacomicon.icon-support-response:before {
  content: "\e95c";
}
.vacomicon.icon-temperature:before {
  content: "\e95d";
}
.vacomicon.icon-tiktok:before {
  content: "\e95e";
}
.vacomicon.icon-tools:before {
  content: "\e95f";
}
.vacomicon.icon-trainee-firm:before {
  content: "\e960";
}
.vacomicon.icon-training:before {
  content: "\e961";
}
.vacomicon.icon-vacom:before {
  content: "\e962";
}
.vacomicon.icon-website-be:before {
  content: "\e963";
}
.vacomicon.icon-website-usa:before {
  content: "\e964";
}
.vacomicon.icon-website:before {
  content: "\e965";
}
.vacomicon.icon-marker-international1:before {
  content: "\55";
}
.vacomicon.icon-small-arrow-up:before {
  content: "\e900";
}
.vacomicon.icon-menu:before {
  content: "\e901";
}
.vacomicon.icon-close:before {
  content: "\e902";
}
.vacomicon.icon-cart-empty:before {
  content: "\e903";
}
.vacomicon.icon-globe:before {
  content: "\e904";
}
.vacomicon.icon-heart-outline:before {
  content: "\e905";
}
.vacomicon.icon-confirm:before {
  content: "\e906";
}
.vacomicon.icon-search:before {
  content: "\e907";
}
.vacomicon.icon-user:before {
  content: "\e908";
}
.vacomicon.icon-heart:before {
  content: "\e909";
}
.vacomicon.icon-download:before {
  content: "\e90a";
}
.vacomicon.icon-print:before {
  content: "\e90b";
}
.vacomicon.icon-settings:before {
  content: "\e90c";
}
.vacomicon.icon-success:before {
  content: "\e90d";
}
.vacomicon.icon-filter:before {
  content: "\e90e";
}
.vacomicon.icon-activities:before {
  content: "\e90f";
}
.vacomicon.icon-phone-1:before {
  content: "\e910";
}
.vacomicon.icon-message-info:before {
  content: "\e911";
}
.vacomicon.icon-jobticket:before {
  content: "\e912";
}
.vacomicon.icon-tile:before {
  content: "\e913";
}
.vacomicon.icon-grid:before {
  content: "\e913";
}
.vacomicon.icon-calendar:before {
  content: "\e914";
}
.vacomicon.icon-kitchen:before {
  content: "\e915";
}
.vacomicon.icon-kita:before {
  content: "\e916";
}
.vacomicon.icon-contact:before {
  content: "\e917";
}
.vacomicon.icon-customer-experience:before {
  content: "\e918";
}
.vacomicon.icon-watchlist:before {
  content: "\e919";
}
.vacomicon.icon-list:before {
  content: "\e919";
}
.vacomicon.icon-mail:before {
  content: "\e91a";
}
.vacomicon.icon-marker-international:before {
  content: "\e91b";
}
.vacomicon.icon-fruits:before {
  content: "\e91c";
}
.vacomicon.icon-doc-pdf:before {
  content: "\e91d";
}
.vacomicon.icon-arrow-left-outline:before {
  content: "\e91e";
}
.vacomicon.icon-arrow-top-outline:before {
  content: "\e91f";
}
.vacomicon.icon-arrow-right-outline:before {
  content: "\e920";
}
.vacomicon.icon-arrow-down-outline:before {
  content: "\e921";
}
.vacomicon.icon-arrow-left:before {
  content: "\e922";
}
.vacomicon.icon-arrow-right:before {
  content: "\e923";
}
.vacomicon.icon-arrow-down:before {
  content: "\e924";
}
.vacomicon.icon-production:before {
  content: "\e925";
}
.vacomicon.icon-sale:before {
  content: "\e926";
}
.vacomicon.icon-facebook:before {
  content: "\e927";
}
.vacomicon.icon-instagram:before {
  content: "\e928";
}
.vacomicon.icon-twitter:before {
  content: "\e929";
}
.vacomicon.icon-vimeo:before {
  content: "\e92a";
}
.vacomicon.icon-xing:before {
  content: "\e92b";
}
.vacomicon.icon-linkedin1:before {
  content: "\e92c";
}
.vacomicon.icon-youtube:before {
  content: "\e92d";
}
.vacomicon.icon-clean:before {
  content: "\e92e";
}
.vacomicon.icon-languages:before {
  content: "\e92f";
}
.vacomicon.icon-remember:before {
  content: "\e930";
}
.vacomicon.icon-support:before {
  content: "\e931";
}
.vacomicon.icon-share:before {
  content: "\e932";
}
.vacomicon.icon-phone:before {
  content: "\e933";
}
.vacomicon.icon-upload:before {
  content: "\e934";
}
.vacomicon.icon-cart:before {
  content: "\e935";
}
.vacomicon.icon-warning:before {
  content: "\e936";
}
.vacomicon.icon-zoom-in:before {
  content: "\e937";
}
.vacomicon.icon-zoom-out:before {
  content: "\e938";
}
.vacomicon.icon-education:before {
  content: "\e939";
}
.vacomicon.icon-standard-components:before {
  content: "\e93a";
}
.vacomicon.icon-standard-near-components:before {
  content: "\e93b";
}
.vacomicon.icon-special-components:before {
  content: "\e93c";
}
.vacomicon.icon-linkedin:before {
  content: "\e93d";
}
.vacomicon.icon-star-outline:before {
  content: "\e93e";
}
.vacomicon.icon-star:before {
  content: "\e93f";
}
.vacomicon.icon-auto:before {
  content: "\e940";
}
.vacomicon.icon-star-outline:hover:before {
    content: "\e93f";
    color: $purple;
}
.vacomicon.icon-lock {
    font-family: 'TeleIcon', serif;
}
.vacomicon.icon-lock::before { content: '\4c'; }

// TODO once the back arrow is added as its own glyph in the iconfont remove this
.vacomicon.icon-navigation-arrow-back {
  transform: scale(-1); // Flip Arrow

  // because of transform if this icon is used in a button we need to flip paddings
  .btn>&.icon--right, .cc-dismiss>&.icon--right {
    padding-left: 0;
    padding-right: rem(5px);
  }
}

.fs-24 {
    font-size: 24px;
}

// Badges
@mixin badge-variant($color) {
  background-color: $color;
}
// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.
$badge-padding-y: 1px;
$badge-padding-x: 3px;
$badge-default-bg: $purple;
$badge-color: $white;
$badge-link-hover-color: $white;
$badge-pill-border-radius: 40px;

.badge {
  left: -10px !important;
  top: 10px !important;
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: rem(13px);
  line-height: rem(15px);
  height: 18px;
  color: $badge-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: $badge-pill-border-radius;
  background-color: $badge-default-bg;

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// scss-lint:enable QualifyingElement

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: 6px;
  padding-left: 6px;

}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

.badge-default {
  @include badge-variant($badge-default-bg);
}

.badge-primary {
  @include badge-variant($color-brand);
}

.badge-success {
  @include badge-variant($color-success);
}

.badge-info {
  @include badge-variant($color-info);
}

.badge-warning {
  @include badge-variant($color-warning);
}

.badge-danger {
  @include badge-variant($color-error);
}
