//-------------------------------------------------------------
// Accordion
//-------------------------------------------------------------
// Variables

.acc_content {
    display: block;
    border-top: 1px solid $gray-medium-1;
    border-bottom: 1px solid $gray-medium-1;

    &.acc_content_no_border.acc_content--open {
        > .acc_content__headline {
            border-bottom: none !important;
        }
    }

    &.acc_content_outer {
        margin-bottom: building-units(3);

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }

        border: none !important;

        &:hover {
            border: none !important;
        }

        > .acc_content__headline {
            border-bottom: 1px solid $gray-medium-1;

            .acc_content__toggle-icon {
                color: $gray-medium-1;
            }
        }

        .acc_content {
            &:first-child {
                border-top: 1px solid transparent;

                &:hover {
                    border-top: 1px solid $black;
                }
            }

            &:last-child {
                border-bottom: 1px solid $gray-medium-1;

                &:hover {
                    border-bottom: 1px solid $black;
                }
            }
        }
    }

    &:not(.acc_content--inactive) {
        &:hover {
            border-top: 1px solid $black;
            //border-bottom: 1px solid $black;

            + .acc_content {
                border-top: 1px solid $black;
            }
        }
    }

    &__stack {
        padding: 0;

        .acc_content {
            border-bottom: 0;
        }

        .acc_content--open {
            border-bottom: 1px !important;
        }
    }

    &__headline {
        @include flexbox();
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        cursor: pointer;
        text-decoration: none;
        height: auto;
        min-height: building-units(5);

        //@include reset-hover();

        //wrapper for header and subline
        > div {
            @include flexbox();
            justify-content: center;
            align-items: center;
            height: auto;
            min-height: building-units(5);
        }

        .acc_content__toggle {
            text-align: right;

            .acc_content__toggle-icon {
                width: building-units(5);
                color: $black;

                &:before {
                    margin-right: 0;
                    font-size: building-units(1);
                }
            }
        }

        &:hover, &:active {
            cursor: pointer;

            .acc_content__toggle {
                background-color: $white;
            }

            .vacomicon, .acc_content__title {
                color: $black;
            }
        }

        > .vacomicon {
            font-size: 14px;
            line-height: building-units(5);
            margin-right: building-units(1);
        }
    }

    &__title {
        display: block;
        color: $black;
        font-size: $font-size-default;
        line-height: $line-height-default;
        font-weight: $font-weight-normal;
        letter-spacing: 0;
        padding: building-units(1.5) 0;
    }

    &__subline {
        margin-top: building-units(0.5);
    }

    &__toggle {
        flex: 0 0 building-units(5);
        width: building-units(5);
        height: auto;
        min-height: building-units(5);
        text-decoration: none;
        color: $purple;
        background-color: $white;
        margin-left: auto;
        align-content: center;

        @include flexbox();
        justify-content: center;
        align-items: start;
        padding-top: 18px;

        .icon-small-arrow-up,
        > .icon-minus {
            display: none
        }

        .icon-arrow-down,
        > .icon-plus {
            display: inline-block
        }
    }

    &__content {
        font-size: $font-size-default;
        padding: 0;
        max-height: 0;
        overflow: hidden;

        @extend %accordionOpen;

        p:last-child {
            margin-bottom: 0;
        }
    }

    &--open {
        > .acc_content__headline {
            background-color: $white;
            color: $black;
        }

        .acc_content__title {
            color: $black;
            font-weight: $font-weight-bold;
        }

        .acc_content__toggle {
            background-color: $white;
        }

        > .acc_content__content {
            @extend %accordionOpen;

            background-color: $white;
            padding: building-units(1.5) 0;
            max-height: 9999px;
        }

        > .acc_content__headline > .acc_content__toggle {

            > .icon-small-arrow-up,
            > .icon-minus {
                display: inline-block;
                width: building-units(5);
            }

            > .icon-arrow-down,
            > .icon-plus {
                display: none;
                width: building-units(5);
            }
        }
    }

    &--inactive {
        color: $gray-184;

        .acc_content__headline,
        .acc_content__title,
        .acc_content__toggle,
        .acc_content__subline {
            color: $gray-184;
        }

        .acc_content__headline,
        .acc_content__toggle:hover {
            cursor: no-drop;
            color: $gray-184;

            .vacomicon, .acc_content__title {
                color: $gray-184;
            }
        }

        .acc_content__headline:hover {
            .acc_content__toggle {
                background-color: $white;
            }
        }
    }
}

aside.filter-sidebar {
    a.link {
        @include pt-sans-regular-14();
        text-decoration: none;
        color: $black;

        &:hover {
            text-decoration: underline;
            color: $purple;
        }
    }

    .acc_content {
        &.filterContent {
            .acc_content__headline {
                .acc_content__title {
                    @include pt-sans-regular-14();
                }
            }
        }
    }

    .acc_content.acc_content--open {
        &.filterContent {
            .acc_content__headline {
                .acc_content__title {
                    @include pt-sans-bold-14();
                }
            }

            .acc_content__content {
                padding: 0 0 building-units(2) 0;
            }
        }

        .acc_content__stack {
            padding: 0 !important;
        }

        &__content {
            ul.list {
                li {
                    @include pt_sans_regular-14();
                }
            }
        }

        &__headline {
            .acc_content__toggle {
                .acc_content__toggle-icon {
                    &:before {
                        font-size: 10px;
                    }
                }
            }
        }

        :not(.acc_content__stack) {
            &.acc_content--open {
                .acc_content__headline {
                    //border-bottom: 1px solid $gray-medium-1;
                }
            }
        }
    }

    .acc_content_no_bottom_border {
        border-bottom: none;
    }

    .filter-actions {
        margin-bottom: building-units(1);

        .filter-clear {

            margin: 0;

            span:not(.vacomicon) {
                @include pt-sans-regular-14();
            }

            .vacomicon {

                bottom: 4px;

                &:before {

                    font-size: 11px;
                }

                + span {

                    color: $black;

                    &:hover {

                        color: $purple;
                    }
                }
            }
        }
    }

    .acc_content--open {
        > .acc_content__content {
            //padding-top: 0;

            &.acc_content__stack {
                padding-bottom: none;
            }
        }
    }

    .acc_content__title {
        @include sans-bold-18();
    }
}

.filter-wrapper {
    display: flex!important;
}

.filter-text {
    flex-grow: 1;
    align-items: flex-start;
    display: flex;
}

.filter-close {
    align-items: flex-start;
    display: flex;
}
