//-------------------------------------------------------------
// Button primary
//-------------------------------------------------------------

// Variables
$button-text-color-primary: $white;
$button-bg-color-primary: $purple;
$button-border-color-primary: $purple;

$button-text-color-primary-hover: $white;
$button-bg-color-primary-hover: rgba($purple, .8);
$button-border-color-primary-hover: $purple;

$button-text-color-primary-active: $white;
$button-bg-color-primary-active: $purple;
$button-border-color-primary-active: $purple;

$button-text-color-primary-disabled: $gray-medium-2;
$button-bg-color-primary-disabled: $gray-light;
$button-border-color-primary-disabled: $gray-medium-2;

// dark theme
$button-text-color-theme-dark-primary: $purple;
$button-bg-color-theme-dark-primary: $white;

$button-text-color-theme-dark-primary-hover: $purple;
$button-bg-color-theme-dark-primary-hover: rgba($purple, .8);
$button-border-theme-dark-primary-hover: $purple;
$button-text-color-theme-dark-primary-active: $purple;
$button-bg-color-theme-dark-primary-active: $white;

$button-text-color-theme-dark-primary-disabled: $gray-medium-2;
$button-bg-color-theme-dark-primary-disabled: $gray-light;

.btn--primary {
  color: $button-text-color-primary;
  background-color: $button-bg-color-primary;

  &:focus-within,
  &:focus {
    color: $button-text-color-primary-hover;
    background-color: $button-bg-color-primary-hover;
    border-color: $button-bg-color-primary-hover;
  }

  //separate because of IE
  &:hover {
    background-color: $button-bg-color-primary-hover;
    border-color: $button-bg-color-primary-hover;
  }

  &:active {
    color: $button-text-color-primary-active;
    background-color: $button-bg-color-primary-active;
  }

  &[disabled] {
    cursor: not-allowed;
    color: $button-text-color-primary-disabled;
    background: $button-bg-color-primary-disabled;
    border-color: $button-bg-color-primary-disabled;
  }

  &.btn--large,
  &.btn--medium,
  &.btn--small {

    &.btn--theme-dark {

      color: $button-text-color-theme-dark-primary;
      background-color: $button-bg-color-theme-dark-primary;

      &:focus-within,
      &:focus {
        color: $button-text-color-theme-dark-primary-hover;
        background-color: $button-bg-color-theme-dark-primary-hover;
        border-color: $button-bg-color-theme-dark-primary-hover;
      }

      //separate because of IE
      &:hover {
        background-color: $button-bg-color-theme-dark-primary-hover;
        border-color: $button-border-theme-dark-primary-hover;
      }

      &:active {
        color: $button-text-color-theme-dark-primary-active;
        background-color: $button-bg-color-theme-dark-primary-active;
      }

      &[disabled] {
        cursor: not-allowed;
        color: $button-text-color-theme-dark-primary-disabled;
        background: $button-bg-color-theme-dark-primary-disabled;
        border-color: $button-bg-color-theme-dark-primary-disabled;
      }
    }
  }
}
