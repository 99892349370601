//-------------------------------------------------------------
// Textarea
//-------------------------------------------------------------

// Variables
$textarea-minheight: building-units(20);
$textarea-height: building-units(20);

// Textarea Styling
.form-textarea {
    @extend .form-input;

    overflow: auto;
    min-height: $textarea-minheight;
    height: $textarea-height;
    resize: vertical;

    &:disabled {
        resize: none;
    }
}

.textarea-group {
    @extend .input-group;

    &.has-error:after {
        top: inherit !important;
        bottom: 55px !important;
    }
}

textarea {
    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        @include sans-regular-18();
        margin-bottom: 20px;
        color: $gray-medium-2 !important;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        @include sans-regular-18();
        margin-bottom: 20px;
        color: $gray-medium-2 !important;
    }
}

textarea:disabled,
input:disabled {
    cursor: not-allowed;
}
