.price {
    color: $black;
    margin-bottom: building-units(1);

    &__number {
        @include pt-serif-regular-20();
    }

    &__discount {
        margin-left: building-units(1);
        color: $green;
    }

    &__top {
        display: inline-block;
        margin-left: building-units(1);

        sup {
            @include pt-sans-regular-14();
            color: $gray-medium-1;
            text-transform: none;
            margin-bottom: 0;
        }
    }

    &__bottom {
        display: flex;
        flex-flow: row;
    }

    &__crossed-out {
        position: relative;
        top: 2px;

        &:before {
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            right: 0;
            border-top: 1px solid;
            border-color: inherit;
        }
    }

    &__label {
        text-transform: none;
        margin-right: building-units(0.5);
    }

    &__tax {
        @extend .text-x-small;
        text-transform: none;
        margin-bottom: 0;
        letter-spacing: 0;

        a.link {
            text-decoration: none;
            color: $purple;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &--discount {
        color: $green;
    }
}

.availability {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: building-units(3);
    height: building-units(1);
    align-self: self-start;

    &__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: stretch;
    }

    &__text {
        @include pt-sans-regular-14();

        flex: 0 1 auto;
        margin-right: building-units(1);
    }

    &:before,
    &:after {
        content: "";
        display: inline-block;
        position: absolute;
    }

    &:before {
        width: building-units(1);
        height: building-units(1);
        border: 1px solid #fff;
        background-color: transparent;
        right: 9px;
        top: 5px;
        z-index: 1;
    }

    &:after {
        width: building-units(3);
        min-width: building-units(3);
        height: building-units(1);
        border-radius: 5px;
        background-color: $green;
        right: 0;
        top: 6px;
        margin: 0;
    }


    &--yellow:after {
        background-color: $yellow !important;
    }

    &--red:after {
        background-color: $red !important;
    }

    &--orange:after {
        background-color: $orange !important;
    }
}
