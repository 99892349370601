.flex {
    &-row {
        flex-direction: row !important;
    }

    &-column {
        flex-direction: column !important;
    }

    &-wrap {
        flex-wrap: wrap !important;
    }

    &-nowrap {
        flex-wrap: nowrap !important;
    }

    &-grow-0 {
        flex-grow: 0 !important;
    }

    &-grow-1 {
        flex-grow: 1 !important;
    }

    &-shrink-0 {
        flex-shrink: 0 !important;
    }

    &-shrink-1 {
        flex-shrink: 1 !important;
    }
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-end {
    justify-content: flex-end;
}

.align-content-start {
    align-content: flex-start;
}

.align-content-center {
    align-content: center;
}

.align-content-end {
    align-content: flex-end;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.align-self-center {
    align-self: center;
}

.align-self-end {
    text-align: right;
    align-self: flex-end;
}

@each $breakpoint in map-keys($vacom-grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $vacom-grid-breakpoints);

        .justify-content-start#{$infix} {
            justify-content: flex-start;
        }

        .justify-content-center#{$infix} {
            justify-content: center;
        }

        .justify-content-space-between#{$infix} {
            justify-content: space-between;
        }

        .justify-content-end#{$infix} {
            justify-content: flex-end;
        }


        .align-content-start#{$infix} {
            align-content: flex-start;
        }

        .align-content-center#{$infix} {
            align-content: center;
        }

        .align-content-end#{$infix} {
            align-content: flex-end;
        }

        .align-items-center#{$infix} {
            align-items: center;
        }

        .align-items-baseline#{$infix} {
            align-items: baseline;
        }

        .align-self-center#{$infix} {
            align-self: center;
        }

        .align-self-end#{$infix} {
            text-align: right;
            align-self: flex-end;
        }

        .flex {
            &-row#{$infix} {
                flex-direction: row;
            }

            &-column#{$infix} {
                flex-direction: column;
            }
        }
    }
}
