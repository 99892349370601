#CybotCookiebotDialogBody {
  max-width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  vertical-align: top !important;
  padding-right: building-units(3) !important;
  padding-left: building-units(3) !important;
}

div#CybotCookiebotDialogPoweredbyLink {
  margin-top: building-units(3.5) !important;
}

h2#CybotCookiebotDialogBodyContentTitle {
  font-size:   rem($font-size-h2-sm) !important;
  font-weight: $font-weight-light !important;
  line-height: $line-height-h2-sm !important;
  @extend %h1-h4-color;
  @extend %heading-margin;

  @include media-breakpoint-up(md)  {
    font-size:     rem($font-size-h2-l) !important;
    line-height:   $line-height-h2-l !important;
  }
}

div#CybotCookiebotDialogBodyContentText {
  font-family: "Inter Tight" !important;
  font-size: 18px !important;
}

div#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
  margin: building-units(2) 0 !important;
}

a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: $purple !important;
  color: $white !important;
  &:hover {
    background-color: rgba($purple, .8) !important;
  }
}

a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
  background-color: $white !important;
  color: $purple !important;
  &:hover {
    border: 1px solid rgba($purple, .8) !important;
    color: rgba($purple, .8) !important;
  }
}

a.CybotCookiebotDialogBodyButton {
  display: inline-block !important;
  padding: building-units(1) !important;
  margin-top: building-units(2);
  border: 1px solid $purple !important;
  border-radius: building-units(2.5) !important;
  font-family: "Inter Tight" !important;
  color: $purple !important;
  background-color: $white !important;
  height: building-units(5) !important;
  max-height: building-units(5) !important;
  padding: 0 building-units(3) !important;
  font-size: 18px !important;
  line-height: building-units(5) !important;
  width: auto !important;
  min-width: building-units(15) !important;
}
 div#CybotCookiebotDialogBodyLevelButtons {
   float: right !important;
 }
