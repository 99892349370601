//-------------------------------------------------------------
// Input with Addon
//-------------------------------------------------------------

// Variables
$addon-padding:        building-units(.5) building-units(1);
$addon-height:         building-units(5);
$addon-border-radius:  0;
$addon-input-bg-color: $purple;
$addon-input-color:    $white;
$addon-br-color:       $purple;

// Addon Styling
.form-input-set {
    .addon-wr {
        display: flex;
        clear:   both;
        width: 100%;

        .input-addon {
            position:         relative; /* enable absolute positioning */
            height:           $addon-height;
            min-width:        45px;
            border-radius:    0;
            display:          flex;
            justify-content:  center;
            align-items:      center;
            padding:          0 15px;

            background-color: $purple;
            color: $white;

            &--small {
                max-width: 45px;
            }

            &:hover {
                background-color: $white;
                color: $purple;
            }
        }

        &.addon-left {
            .input-addon {
                @include reset-border-radius-direction("right");
                border-right: 0;
            }

            .form-input {
                @include reset-border-radius-direction("left");
            }
        }

        &.addon-right {
            .input-addon {
                @include reset-border-radius-direction("left");
                border-left: 0;
            }

            .form-input {
                @include reset-border-radius-direction("right");
            }
        }
    }

    /* style icon */
    .inner-addon {
        position: relative;
    }

    .inner-addon .vacomicon {
        font-size:  rem(16px);
        position:   absolute;
        top:        18px;
        right:      18px!important;
        padding:    0;
        pointer-events: none;

        &.icon-calendar {
            font-size: rem(18px);
        }
    }

    /* align icon */
    .left-addon .vacomicon  { left:  building-units(1);}
    .right-addon .vacomicon { right: building-units(1);}

    /* add padding  */
    .left-addon input  { padding-left:  building-units(3); }
    .right-addon input { padding-left: building-units(1.5); }
}
