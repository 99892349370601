//-------------------------------------------------------------
// General
//-------------------------------------------------------------

* {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing:  antialiased;
}

// Set the rem baseline to 1 Building Unit
html {
    @include rem-baseline;
}

body {
    font-family: "Inter Tight", sans-serif;
    text-rendering: optimizeLegibility;
    color: $text-color;
    font-size: rem($font-size-base);
    line-height: rem($line-height-base);
    background: $site-background-color;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
    margin-bottom: 0!important;

    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;

    @include media-breakpoint-up(md) {
      font-size:   rem($font-size-default-l);
      line-height: $line-height-default-l;
    }
}

p {
    margin: 0 0 building-units(1) 0;
}
