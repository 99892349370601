//-------------------------------------------------------------
// Radiobutton
//-------------------------------------------------------------

// Variables
$radiobutton-size: building-units(2);
$radiobutton-br-radius: 100%;
$radiobutton-icon-size: building-units(1.5);
$radiobutton-shadow: $form-elements-box-shadow;

// General styles
.form-radiobutton-set {
    line-height:  $radiobutton-size;
    margin:       building-units(1) 0;
    min-height:   $radiobutton-size;
    padding-left: $radiobutton-size;
    position:     relative;

    &.has-error {
        .validation-message {
            padding-left: building-units(1);
        }
    }
}

.form-radiobutton {
    position: absolute;
    top:      0;
    left:     0;
    z-index:  1;
    opacity:  0;
    height:   $radiobutton-size;
    width:    $radiobutton-size;

    + label {
        @extend %text-default;

        display:        inline-block;
        min-height:     0;
        padding-left:   building-units(1);
        position:       relative;
        vertical-align: middle;

        &::before,
        &::after {
            @extend %teleicon;

            position:      absolute;
            top:           0;
            left:          0;
            height:        calc(#{$radiobutton-size} - 2px);
            width:         calc(#{$radiobutton-size} - 2px);
            border:        1px solid $radiobutton-border-color;
            border-radius: $radiobutton-br-radius;
            background:    $radiobutton-background-color;
            font-size:     $radiobutton-icon-size;
            color:         $radiobutton-icon-color;
            text-align:    center;
            margin-left:   -#{$radiobutton-size};
            transition:    opacity .2s ease-in-out;
        }

        &::before {
            content: "";
        }

        &::after {
            content:     "\3b";
            font-weight: $icon-solid-font-weight;
            opacity:     0;
            line-height: calc(#{$radiobutton-size} - 1px);
        }

        &:hover {
            cursor: pointer;
        }
    }

    &:checked {
        + label {
            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    &:hover {
        cursor: pointer;

        + label {
            &::before,
            &::after {
                background: $radiobutton-hover-background-color;
            }
        }
    }

    &:active {
        outline: 0;

        + label {
            &::before,
            &::after {
                background: $radiobutton-active-background-color;
            }
        }
    }

    &:focus:not(:active) {
        outline: 0;

        + label {
            &::before,
            &::after {
                border-color: $radiobutton-focus-border-color;
                box-shadow:   $radiobutton-shadow $radiobutton-focus-shadow-color;
            }
        }
    }

    //
    // Disabled

    &:disabled {
        cursor: not-allowed;

        + label {
            cursor: not-allowed;

            &::before,
            &::after {
                border-color: $radiobutton-disabled-border-color;
                background:   $radiobutton-disabled-background-color;
                color:        $radiobutton-disabled-icon-color;
            }
        }
    }

    // Error

    .has-error & {
        + label {
            &::before,
            &::after {
                border-color: $radiobutton-error-border-color;
                background:   $radiobutton-error-background-color;
                color:        $radiobutton-error-icon-color;
            }
        }

        &:hover {
            + label {
                &::before,
                &::after {
                    background: $radiobutton-error-hover-background-color;
                }
            }
        }

        &:focus:not(:active) {
            outline: 0;

            + label {
                &::before,
                &::after {
                    border-color: $radiobutton-error-focus-border-color;
                    box-shadow:   $radiobutton-shadow $radiobutton-error-focus-shadow-color;
                }
            }
        }
    }
}
