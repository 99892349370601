//-------------------------------------------------------------
// Headings
//-------------------------------------------------------------

// Mixins and Extend Classes

%heading-margin {
    margin: rem($margin-headings)
}

%h1 {
    font-size:   rem($font-size-h1-sm);
    font-weight: $font-weight-light;
    line-height: $line-height-h1-sm;
    @extend %heading-margin;
    
    @include media-breakpoint-up(md) {
        font-size:     rem($font-size-h1-l);
        line-height:   $line-height-h1-l;
    }
}

%h2 {
    font-size:   rem($font-size-h2-sm);
    font-weight: $font-weight-light;
    line-height: $line-height-h2-sm;
    @extend %heading-margin;

    @include media-breakpoint-up(md)  {
        font-size:     rem($font-size-h2-l);
        line-height:   $line-height-h2-l;
    }
}

%h3 {
    font-size:   rem($font-size-h3);
    font-weight: $font-weight-ultra;
    line-height: $line-height-h3;
    @extend %heading-margin;
}

%h4 {
    font-size:   rem($font-size-h4);
    font-weight: $font-weight-ultra;
    line-height: $line-height-h4;
    @extend %heading-margin;
}

%h5 {
    font-size:   rem($font-size-h5);
    font-weight: $font-weight-ultra;
    line-height: $line-height-h5;
    @extend %heading-margin;
}

%h6 {
    font-size:   rem($font-size-h6);
    font-weight: $font-weight-ultra;
    line-height: $line-height-h6;
    @extend %heading-margin;
}

%display {
    font-size:   rem($font-size-display);
    font-weight: $font-weight-light;
    @extend %heading-margin;
}

// Styles 

h1,
.h1 {
    @extend %h1;
}

h2,
.h2 {
    @extend %h2;
}

h3,
.h3 {
    @extend %h3;
}

h4,
.h4 {
    @extend %h4;
}

h5,
.h5 {
    @extend %h5;
}

h6,
.h6 {
    @extend %h6;
}

.display {
    @extend %display;
}
