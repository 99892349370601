// Override Theme Variables Here
// See utilities/variables for a list of all available variables
//
//-------------------------------------------------------------
// Color system
//-------------------------------------------------------------

// Basic Colors
$white: #fff;
$gray-248: #f8f8f8;
$gray-237: #ededed;
$gray-220: #dcdcdc;
$gray-208: #d0d0d0;
$gray-199: #c7c7c7;
$gray-194: #c2c2c2;
$gray-184: #b8b8b8;
$gray-178: #b2b2b2;
$gray-164: #a4a4a4;
$gray-151: #979797;
$gray-117: #757575;
$gray-56: #383838;
$gray-38: #262626;
$black: #000;

$magenta: #e20074;
$magenta-227: #e3bacf;
$magenta-208: #d0006b;
$magenta-188: #bc0060;
$magenta-160: #a00053;
$magenta-140: #8c0048;

$blue: #00a0de;
$blue-175: #007faf;
$blue-121: #005879;

$blue-rgba-50: rgba($blue, 0.5);

// Function Colors
$red: #d90000;
$red-208: #d0021b;
$red-253: #fdf2f2;
$red-251: #fbe6e6;
$green: #babc59;
$green-240: #f0f7e9;
$green-248: #f8fbf4;

$green-rgba-50: rgba($green, 0.5);
$magenta-rgba-50: rgba($magenta, 0.5);

//-------------------------------------------------------------
// Theming
//-------------------------------------------------------------
// Mandant
$vacom: #9c0a7d;

// Global Color Definition
$color-brand: $vacom;
// $color-text: $gray-56;
// $color-subtext: #6b6b6b;
// $color-area: $gray-237;
// $color-background: $gray-38;
// $color-area-hover: $gray-220;
// $color-link: #00739f;
$color-success: #89BA2D;
$color-error: #e2282a;
$color-alert: #e2282a;
$color-warning: #ff983b;
$color-hint: #89ba2d;
$color-info: $color-brand;
$color-tip: #89ba2d;



// Global
$text-color: $color-text;
$site-background-color: $white;
$link-color: $blue-175;
$link-hover-color: $link-color;

// Components

// Accordion
// $accordion-border-color: $gray-220;
// $accordion-headline-color: $text-color;
// $accordion-headline-background-color: transparent;
$accordion-headline-hover-color: $color-brand;
$accordion-headline-hover-background-color: $white;
// $accordion-toggle-color: $gray-56;
$accordion-toggle-hover-color: $color-brand;
$accordion-headline-open-color: $black;
$accordion-headline-open-background-color: $white;
$accordion-toggle-open-color: $black;
// $accordion-inactive-color: $gray-184;

// Blockquote
// $blockquote-text-link-color: $gray-56;
// $blockquote-text-link-hover-color: $blue-175;
// $blockquote-card-text-color: $white;

// Brand Bar
$brandbar-background-color: $color-brand;
$brandbar-text-color: $white;

// Breadcrumbs
$breadcrumb-current-link-color: $black;
$breadcrumb-link-color: #3c3c3b;

// Briefing Components
// $briefing-background-color: #f4f6f8;
// $briefing-subline-text-color: #eca859;
// $briefing-component-border-color: $gray-194;
// $briefing-component-content-light-background-color: $white;
// $briefing-component-content-dark-background-color: $gray-56;
// $briefing-component-content-dark-text-color: $white;
// $briefing-component-image-link-color: $gray-56;
// $briefing-component-image-link-dark-color: $white;
$briefing-component-image-link-hover-color: $color-brand;

// Theme Switcher
// $theme-switcher-checkbox-border: #ccc;
// $theme-switcher-light-background: $white;
// $theme-switcher-dark-background: $gray-56;
// $theme-switcher-dark-color: $gray-56;

// Buttons
$button-primary-color: $white;
$button-primary-background-color: $color-brand;
$button-primary-border-color: transparent;
// $button-primary-hover-color: $white !default;
$button-primary-hover-background-color: $color-brand;
$button-primary-hover-border-color: $color-brand;
// $button-primary-active-color: $white !default;
$button-primary-active-background-color: $color-brand;
$button-primary-active-border-color: $color-brand;
// $button-primary-disabled-color: $gray-184 !default;
// $button-primary-disabled-background-color: $gray-248 !default;
// $button-primary-disabled-border-color: $gray-220 !default;
// $button-primary-ghost-border-color: $white !default;
// $button-primary-ghost-text-color: $white !default;
// $button-primary-ghost-focus-color: $white !default;

// $button-secondary-color: $gray-56;
// $button-secondary-background-color: $gray-237;
// $button-secondary-border-color: $gray-178;
// $button-secondary-hover-color: $gray-56;
// $button-secondary-hover-background-color: #dbdbdb;
// $button-secondary-hover-border-color: $gray-178;
// $button-secondary-active-color: $gray-56;
// $button-secondary-active-background-color: $gray-199;
// $button-secondary-active-border-color: $gray-117;
// $button-secondary-disabled-color: $gray-184;
// $button-secondary-disabled-background-color: $gray-248;
// $button-secondary-disabled-border-color: $gray-220;

$button-outline-color: $color-brand;
$button-outline-background-color: #fff;
$button-outline-border-color: $color-brand;
// $button-outline-hover-color: $gray-56;
// $button-outline-hover-background-color: $gray-237;
// $button-outline-hover-border-color: $gray-178;
// $button-outline-active-color: $gray-56;
// $button-outline-active-background-color: $gray-220;
// $button-outline-active-border-color: $gray-117;
// $button-outline-disabled-color: $gray-184;
// $button-outline-disabled-background-color: $white;
// $button-outline-disabled-border-color: $gray-220;

// $button-link-color: $text-color;
// $button-link-background-color: transparent;
// $button-link-border-color: $gray-178;
$button-link-hover-color: $color-brand;
// $button-link-hover-background-color: $gray-237;
// $button-link-hover-border-color: $gray-178;
// $button-link-active-color: $gray-56;
// $button-link-active-background-color: $gray-220;
// $button-link-active-border-color: $gray-117;
// $button-link-disabled-color: $gray-184;
// $button-link-disabled-background-color: $white;
// $button-link-disabled-border-color: $gray-220;

// Cards
$card-primary-background-color: rgba(#999999, 0.1);
$card-primary-text-color: $black;
$card-primary-border-color: transparent;
// $card-primary-teleicon-color: $white;

$card-kicker-text-color: $black;
$card-title-text-color: $color-brand;
$card-default-background-color: #fff;
$card-default-text-color: $black;
$card-default-border-color: #c5c5c5;

$card-dark-grey-text-color: #fff;
$card-dark-grey-background-color: $color-brand;
$card-dark-grey-border-color: none;

// Checkbox
$checkbox-background-color: #fff;
$checkbox-icon-color: #fff;
$checkbox-border-color: $black;
$checkbox-selected-icon-color: $black;
$checkbox-active-background-color: $color-brand;

$cookie-banner-dark-background-color: $color-brand;

// Footer
$footer-background-color: #3c3c3b;
$footer-text-color: $white;
$footer-link-color: #c5c5c5;
$footer-link-hover-color: $white;

// Hero Image
$hero-image-background-color: $color-brand;

// Horizontal Scroller
$horizontal-scroller-btn-hover-color: $color-brand;

// Images
$images-caption-color: $black;

// Info Hints
$info-hint-info-color: $color-info;
$info-hint-warning-color: $color-warning;
$info-hint-alert-color: $color-alert;
$info-hint-hint-color: $color-hint;
$info-hint-tip-color: $color-tip;

// Links
$link-color: $color-brand;
$link-hover-color: $color-brand;

// Navbar
$navbar-link-hover-color: $color-brand;
$navbar-link-active-color: $color-brand;
$navbar-item-hover-border-color: $color-brand;
$megamenu-submenu-link-active-color: $color-brand;
$navbar-close-button-hover-color: $color-brand;
$navbar-mobile-menu-title-color: $color-brand;

// Pagination
$pagination-text-color: $black;
$radiobutton-icon-color: $color-brand;
$radiobutton-disabled-icon-color: $color-brand;
$radio-button-selected-background-color: $color-brand;

// Section Menu
$section-menu-link-color: $color-brand;
$section-menu-link-hover-color: $color-brand;

// Sections
$section-primary-border-color: $color-brand;
$section-primary-background-color: $color-brand;
$section-primary-text-color: $white;
$section-secondary-background-color: #e3e3e3;
$section-secondary-text-color: $black;

// Sidebar
$sidebar-link-headline-color: $color-brand;
$sidebar-link-hover-text-color: $color-brand;
$sidebar-link-hover-icon-color: $color-brand;
$sidebar-collapse-button-hover-color: $color-brand;

// Slider
$slider-bg-magenta-swiper-slide-image-background-color: $color-brand;

// Table
$table-border-color: #c5c5c5;

// Tabs
$tabs-border-color: #c5c5c5;
$tabbar-list-active-color: $color-brand;
$tabbar-list-active-border-color: $black;

// Teaser
$teaser-primary-header-color: $white; // new
$teaser-primary-text-color: $white;
$teaser-primary-background-color: $color-brand;
$teaser-primary-hover-background-color: #7d0764;
$teaser-grey-header-color: $white; // new
$teaser-grey-text-color: $white;
$teaser-grey-background-color: #999;
$teaser-grey-hover-background-color: #3c3c3b;
$teaser-magenta-background-color: rgba($color-brand, 0.7);

//-------------------------------------------------------------
// Fonts
//-------------------------------------------------------------

// General
$font-family-sans-serif: "Inter Tight", "Helvetica Neue", Arial, sans-serif;
$font-family-base: $font-family-sans-serif;

//-------------------------------------------------------------
// Copy Text
//-------------------------------------------------------------

$font-size-default: 16px;
$line-height-default: 24px;

$font-size-text-large: 18px;
$line-height-text-large: 27px;

$font-size-text-small: 14px;
$line-height-text-small: 21px;

//-------------------------------------------------------------
// Headings
//-------------------------------------------------------------

$font-size-h1-l: 40px;
$line-height-h1-l: 50px;
$font-size-h1-sm: 32px;
$line-height-h1-sm: 42px;

$font-size-h2-l: 34px;
$line-height-h2-l: 46px;
$font-size-h2-sm: 26px;
$line-height-h2-sm: 36px;

$font-size-h3: 26px;
$line-height-h3: 36px;

$font-size-h4: 20px;
$line-height-h4: 30px;

$font-size-h5: 18px;
$line-height-h5: 27px;

$font-size-h6: 18px;
$line-height-h6: 27px;

$font-size-display: 40px;
$line-height-display: 50px;

$margin-headings: 0px 0 10px;
