//-------------------------------------------------------------
// Search Suggestion
//-------------------------------------------------------------

$search-result-show-more-color: $purple;
$search-result-border-color: $gray-light;

.search-suggestion {
    &-container {
        display: none;
        width: 1600px;
        position: absolute;
        left: 50%;
        margin-left: -905px;
        background-color: $white;
        top: 40px;
        z-index: 1000;
        padding: building-units(1.5);
        border-top: 1px solid $search-result-border-color;

        @include media-breakpoint-only(lg) {
            width: 1400px;
            margin-left: -805px;
        }

        @include media-breakpoint-only(md) {
            width: 1024px;
            margin-left: -618px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            position: fixed;
            left: 0;
            top: 80px;
            margin-left: 0;
        }

        .search-suggestion--open & {
            display: block;
        }

        @include media-breakpoint-up(md) {
            top: 60px;
            padding-top: 50px;
            padding-bottom: building-units(3);
            border-top: 0 none;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 62px;
        }
    }

    display: flex;
    flex-wrap: wrap;

    &__col1 {
        flex: 1 1 100%;
        @include media-breakpoint-up(md) {
            flex: 1 1 60%;
        }
    }

    &__col2 {
        flex: 1 1 100%;
        @include media-breakpoint-up(md) {
            flex: 1 1 40%;
        }

        .search-suggestion__headline {
            margin-top: building-units(1.5);
            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }

    &__headline {
        @include sans-bold-18();
        margin-bottom: building-units(1.5);

        @include media-breakpoint-up(md) {
            margin-bottom: building-units(3);
        }
    }

    &__count {
        font-weight: normal;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .search-suggestion--no-result & {
            display: none;
        }
    }

    &__list-item {
        @include sans-regular-18();

        position: relative;
        padding: 0 0 0 building-units(2);
        margin: 0 0 building-units(1) 0;

        &:nth-child(n+5) {
            display: none;
        }

        &:before {
            @extend %vacomicon;
            content: "\e900";
            font-size: 6px;
            transform: rotate(90deg);
            position: absolute;
            top: 0;
            left: -10px;
        }

        &:hover {
            &:before {
                color: $search-result-show-more-color;
            }
        }
    }

    &__show-more {
        @include sans-regular-18();
        color: $search-result-show-more-color;
        display: none;
        cursor: pointer;
        margin: 0;
        padding: 0;

        &:hover {
            text-decoration: underline;
        }

        @include media-breakpoint-up(md) {
            margin-top: building-units(2);
        }
    }

    &__show-more--visible {
        display: inline-block;
    }

    &__no-result,
    &__no-result-link {
        @include sans-regular-18();
        display: none;

        .search-suggestion--no-result & {
            display: block;
        }
    }

    &__no-result {
        + .search-suggestion__show-more {
            display: none;
            margin-top: 0;

            .search-suggestion--no-result & {
                display: block;
            }
        }
    }

    &__no-result-link {
        color: $search-result-show-more-color;
    }
}

.autocomplete--open .search-suggestion-container {
    display: block;
}

.autocomplete--empty .automplete--show-all {
  display: none;
}

body:not(.autocomplete--open) .dimmer {
    display: none;
}

@import "search-result/dimmer-vc";
@import "search-result/product-list-vc";
