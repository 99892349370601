//-------------------------------------------------------------
// Footer Copyright
//-------------------------------------------------------------

.page-footer__copyright {

  @include pt-sans-regular-14;
  color: $white;

  margin-bottom: building-units(2);

  @include media-breakpoint-up(sm) {

    margin-bottom: 0;
  }

  &-links {

    @include media-breakpoint-up(sm) {

      text-align: right;
    }

    .copyright {

      &__list {

        @extend %reset-ul;
        font-size: 0;
      }

      &__list-item {

        padding: 0;
        margin: 0;

        display: inline-block;

        &:not(:last-child) {

          margin-right: building-units(4);
        }
      }

      &__list-link {

        @include page-footer-link();
      }
    }
  }
}
