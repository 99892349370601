//-------------------------------------------------------------
// Input Label
//-------------------------------------------------------------

// Variables
$label-margin:          building-units(1);
$label-color:           $black;
$label-color-disabled:  $gray-medium-2;

// Label Styling
label {
    @include sans-regular-18();
    display:            block;
    padding-bottom:     0;
    margin-bottom:      $label-margin;
    color:              $label-color;

    .mandatory {
        color:          $label-color;
        margin-left:    2px;
    }

    text-transform:     none;
    font-weight:        $font-weight-normal;

    &[disabled] {
      color: $label-color-disabled;
    }
}

.explain-text {
  @include pt-sans-regular-14();
  margin-left: $label-margin;
}
