//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables

.modal {
    position: fixed;
    z-index: 1001;
    top: 5%;
    right: 0;
    left: 50%;
    margin-right: auto;
    margin-left: auto;
    width: auto;
    max-width: 100%;
    max-height: 90%;
    border: 1px solid $gray-medium-2;
    background-color: $white;
    display: none;
    padding: building-units(3) building-units(1) building-units(3) building-units(3);
    border-radius: 0;
    transform:scale(0);
    animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

    @include media-breakpoint-only(xl) {
        width: calc((#{$screen-xl} / 12) * 8);
        margin-left: calc((((#{$screen-xl} / 12) * 8) * -1) / 2) !important;
    }
    @include media-breakpoint-only(lg) {
        width: calc((#{$screen-xl} / 12) * 8);
        margin-left: calc((((#{$screen-xl} / 12) * 8) * -1) / 2) !important;
    }
    @include media-breakpoint-only(md) {
        width: calc((#{$screen-xl} / 12) * 8);
        margin-left: calc((((#{$screen-xl} / 12) * 8) * -1) / 2) !important;
    }
    @include media-breakpoint-down(sm) {
        width: calc(100% - 30px);
        left: 0;
    }

    &__header {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        border-bottom: 1px solid $purple;
        padding-bottom: building-units(1);
        margin-right: building-units(2);
    }

    &__title {
      @include pt-sans-bold-18();

      color: $black;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }

    &__footer {
        margin-top: building-units(1);
    }

    &__content {
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: building-units(2);
        padding-right: building-units(2);

        .img_content-box {
            .img_content-box--linked,
            .img_content-box__image {
              width: 100%;
            }
        }
    }

    &__close {
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;

        .cadfemicon {
            font-size: 30px;
            color: $purple;
        }
    }

    &--open {
        display: flex;
        margin: 0 auto;
        flex-flow: column;
        transform:scale(0);
        animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    &--out {
        animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    z-index: 999;
    overflow-y: auto;
    background-color: rgba(#000000, 0.4);
    width: 100%;
    height: 100%;
    animation: zoomIn;
}

.modals-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
