//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
.swiper {
    position: relative;
    display: flex;
    margin: 0 auto;
    left: -30px;

    @media (max-width: 1023px) {
        width: 100%;
        left: unset;
    }

    @media (min-width: 1024px) {
        width: 1024px;
    }

    @media (min-width: 1400px) {
        width: 1400px;
    }

    @media (min-width: 1600px) {
        width: 1600px;
    }

    margin-bottom: building-units(2);

    @include media-breakpoint-up(md) {
        margin-bottom: building-units(4);
    }

    &-container {
        margin: 0 auto;
        width: calc(100% - 188px);
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;

        @include media-breakpoint-only(xs) {
            width: 100%;
        }
    }

    &-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
        transform: translate3d(0px, 0, 0);
    }

    &-slide {
        flex-shrink: 0;
        width: 100%;
        height: unset;
        position: relative;
        transition-property: transform;

        @include media-breakpoint-up(sm) {
            display: flex !important;
            height: auto !important;
        }
    }

    &-slide__image {
        background-color: $purple;
        background-size: cover;
        background-position-x: center;

        height: calc(100% - 15px);
        max-height: inherit;
        overflow: hidden;
        display: inline-block;

        > img {
            height: auto;
        }

        @include media-breakpoint-only(xs) {
            position: relative;
            width: 100%;
            overflow: inherit !important;

            > img {
                display: inline-block;
            }
        }
    }

    &-slide__content {
        display: flex !important;
        align-items: flex-end;
        height: 100%;
        width: 40%;
        background-color: $slider-content-background-color;
        border: none;
        z-index: 9;
        vertical-align: top !important;
        margin-bottom: 0;

        /**
         * News Teaser in research module
         */
        > div[data-filter] {
            padding-top: 15px;
        }

        /**
         * Product Widget
         */
        > div.frame-type-mmsmagentoconnect_product_widget {
            height: 100%;
        }

        /**
         * Video Teaser
         */
        .video-player.js-lazyload {
            min-height: auto !important;
        }

        > div[data-filter],
        > div.frame-type-mmsvacomccintegration_textimage-teaser,
        > div.frame-type-mmsvacomccintegration_video-teaser {
            display: flex;
            height: 100%;
            width: 100%;
            flex-wrap: nowrap;
            justify-content: stretch;
            align-content: stretch;
            align-items: stretch;

            > a.no-underline.w-100 {
                display: inline-flex;
                min-height: 100%;

                > .teaser__news {
                    min-height: 100%;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        /**
         * News Teaser Alone
         */
        > a.no-underline.w-100 {
            margin-top: 15px;
            display: flex;
            min-height: 100%;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-content: stretch;
            align-items: flex-end;

            > .teaser {
                min-height: 100%;
                height: 100%;
                width: 100%;
            }
        }
    }

    &-pagination {
        order: 3;
        position: absolute;
        text-align: center;
        -webkit-transition: 300ms opacity;
        -o-transition: 300ms opacity;
        transition: 300ms opacity;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        z-index: 10;
        width: 100% !important;

        &-bullets-dynamic {
            .swiper-pagination-bullet {
                display: none;
            }

            .swiper-pagination-bullet-active,
            .swiper-pagination-bullet-active-main,
            .swiper-pagination-bullet-active-next,
            .swiper-pagination-bullet-active-prev {
                display: inline-block;
            }

            .swiper-pagination-bullet-active.swiper-pagination-bullet:first-child ~ .swiper-pagination-bullet-active-next-next,
            .swiper-pagination-bullet-active.swiper-pagination-bullet:last-child {
                display: inline-block;
            }
        }
    }

    &-pagination-bullets {
        bottom: -30px !important;
    }

    &-pagination-bullet {
        background-color: $white;
        border: 1px solid $gray-medium-2;
        width: building-units(1.5);
        height: building-units(1.5);
        margin-right: building-units(1.5);

        &:hover {
            border-color: $black;
            background-color: $white;
        }
        &:focus {
            outline: none;
        }
    }


    &-pagination-clickable .swiper-pagination-bullet {
        cursor: pointer;
    }

    &-pagination-bullet-active {
        background-color: $purple;
        border-color: $purple;

        &:hover {
            border-color: $purple;
            background-color: $purple;
        }
    }

    &--scheme-light {
        color: $scheme-dark;

        .swiper-slide__content {
            background-color: rgba($purple, 0.8);
        }
    }

    &--scheme-dark {
        color: $white;

        .swiper-slide__content {
            background-color: rgba($purple, 0.8);

            .swiper-slide__headline {
                color: $white;
            }
        }
    }

    &-bg--white {
        .swiper-slide__image {
            background-color: rgba($white, 0.8);
        }
    }

    &-bg--primary {
        .swiper-slide__image {
            background-color: $slider-bg-magenta-swiper-slide-image-background-color;
        }
    }

    &-bg--secondary {
        .swiper-slide__image {
            background-color: $slider-bg-secondary-swiper-slide-image-background-color;
        }
    }

    .swiper-button-prev.hidden,
    .swiper-button-next.hidden {
        display: none !important;
    }

    .product-tile-container--grid {
        display: block;
        padding-left: 0;
        margin-bottom: 0;
        height: 100%;

        .product-tile-wrapper {
            flex: 1;
            padding: 0;
            max-width: 100% !important;
            height: 100%;

            &.product-item-info {
              padding: 15px !important;
            }
        }
    }

    .video-player.js-lazyLoad {
        min-height: auto !important;
    }
}

.swiper.stage-slider {

    .swiper-container {
        width: 100%;
    }

    .swiper-button-prev {
        right: building-units(7);
        bottom: building-units(3);
    }

    .swiper-button-next {
        right: building-units(3);
        bottom: building-units(3);
    }

    .swiper-button-prev, .swiper-button-next {
        margin-top: unset !important;
        top: unset !important;
        left: unset !important;
        position: absolute;
        display: none;
        width: building-units(3);
        height: building-units(3);
        border-radius: building-units(3);
        border: 1px solid $gray-light;

        &:after {
            top: unset !important;
            left: unset !important;
            font-size: 18px;
            color: $gray-light;
            bottom: 14px;
            right: -10px;
            text-shadow: none;
        }

        &:hover {
            border: 1px solid $white;
            background: none;

            &:after {
                color: $white;
            }
        }

        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
    }

    .swiper-button-prev {
        &:after {
            right: -8px;
        }
    }

    .swiper-button-next {
        &:after {
            right: -10px;
        }
    }

    &:hover {
        @include media-breakpoint-up(sm) {
            .swiper-button-prev, .swiper-button-next {
                display: block;
            }
        }
    }

    .swiper-slide {
        /*
        @include media-breakpoint-only(xs) {
            height: 100% !important;
            width: 100%;
        }
        */

        &.swiper-slide-empty {
            background-color: $white;

            .swiper-slide__image {
                background-color: $white;
                @include media-breakpoint-only(xs) {
                    height: 100%;

                    > img {
                        visibility: hidden;
                    }
                }
            }
        }

        &.swiper-slide-video {
            background-color: transparent;

            .swiper-slide__image {
                height: 100%;
                min-height: 350px;
                position: inherit;
                background-size: initial;
                background-repeat: no-repeat;
                background-color: transparent;

                > img {
                    visibility: hidden;
                    object-fit: cover;
                }

                > video {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .swiper-slide__content {
                top: -6px;
            }
        }

        .swiper-slide__image {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: $purple;
            background-size: cover;
            background-position-x: center;
            max-height: unset;

            > img {
                visibility: hidden;
                object-fit: cover;
            }

            > video {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            @include media-breakpoint-up(sm) {
                min-height: 350px;
            }

            @include media-breakpoint-only(xs) {
                position: relative;
                width: 100%;
                height: 45% !important;

                > img {
                    display: inline-block;
                }
            }
        }

        &:not(.swiper-slide-empty) {
            .swiper-slide__image {
                @include media-breakpoint-only(xs) {
                    height: 100%;
                }
            }
        }
    }

    .swiper-slide__content {
        display: block !important;
        position: relative;
        width: 50%;
        height: 100%;
        max-height: 350px;
        max-width: unset;
        min-width: unset;
        background-color: rgba($purple, 0.8);
        border: none;
        padding: building-units(6) building-units(3) building-units(16) building-units(3);
        z-index: 9;
        margin: 0;

        button.btn {
            margin-bottom: 0;
            position: absolute;
            left: building-units(3);
            bottom: building-units(6);
            @include media-breakpoint-only(xs) {
                position: inherit;
                left: 0;
                bottom: unset;
            }
        }

        @include media-breakpoint-only(xs) {
            color: $white;
            padding: building-units(2) building-units(1.5);
            height: auto;
            display: grid !important;
            > p {
                align-self: start
            }
            > .swiper-slide__headline {
                align-self: start;
                justify-self: start;
            }
        }

        @include pt-sans-regular-18();
        color: $white;

        @include media-breakpoint-only(xs) {
            background-color: $purple;
            width: 100%;
            max-height: unset;
            position: relative;
        }

        @include media-breakpoint-between(sm, md) {
            width: 50%;
        }

        @include media-breakpoint-between(lg, xl) {
            width: 40%;
        }
    }

    .swiper-slide__headline {
        @include pt-serif-regular-34();
        color: $white;
        line-height: 42px;

        margin-bottom: building-units(1.5);

        @include media-breakpoint-only(xs) {
            @include pt-serif-regular-26();
            color: $white;
        }
    }

    .swiper-pagination-bullets {
        bottom: -30px !important;
        left: 0;
        width: 100%;
    }
}

.swiper:not(.stage-slider) .swiper-container:not(.swiper-container-initialized) .swiper-wrapper .swiper-slide {
    @include media-breakpoint-up(md) {
        flex: 0 0 33.3%;
    }
    @include media-breakpoint-only(md) {
        flex: 0 0 50%;
    }
}

.swiper:not(.stage-slider) .swiper-container .swiper-wrapper .swiper-slide {
    @include media-breakpoint-only(xs) {
        display: flex !important;
    }
}

.swiper:not(.stage-slider) .swiper-slide__image {
    height: 50% !important;
}
