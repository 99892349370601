//-------------------------------------------------------------
//Action Items
//-------------------------------------------------------------

// Variables

// General
.product-switch {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    margin-right: building-units(2);

    &__button {
        margin-right: building-units(1.5);
        line-height: 50px;
    }

    &__button:not(:first-child) {
        margin-left: building-units(1);
        width: 24px;
        height: 24px;
    }

    &--active {
        > .vacomicon {
            color: $purple !important;
        }
    }

    .vacomicon {
        font-size: rem(24px);
        color: $black;

        &:hover {
            color: $purple;
        }

        &:active {
            color: $purple;
        }
    }
}