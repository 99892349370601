%cookie-banner {
    position: fixed;
    padding: rem(30px) 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    background: $white;
    z-index: 1000;
}

.cookie-banner {
    @extend %cookie-banner;

    p {
        margin: 0 0 rem(12px);

        + .btn {
            margin-top: rem(18px);
        }
    }

    &__container {
        position: relative;
        padding-right: rem(60px);
    }

    &__close {
        display: inline-block;
        position: absolute;
        top: rem(-10px);
        right: rem(10px);
        padding: rem(10px);
        height: rem(40px);
        cursor: pointer;
    }

    // CC specific Styling
    &.cc-invisible {
        display: none;
    }

    &.cc-theme-dark {
        background: $gray-56;
        color: $cookie-banner-dark-text-color;

        a {
            color: $cookie-banner-dark-link-color;
        }
    }
}

.cc-window {
    @extend %cookie-banner;
    &.cc-invisible {
        display: none;
    }
}

.cc-theme-light {
    background: $cookie-banner-light-background-color;
}

.cc-theme-dark {
    background: $cookie-banner-dark-background-color;
    color: $cookie-banner-dark-text-color;

    a {
        color: $cookie-banner-dark-link-color;
    }
}

.cc-message {
    @include make-container();
    @include make-container-max-widths();
    display: block;
    font-size:   rem($font-size-text-small);
    line-height: $line-height-text-small;
    margin-bottom: rem(12px);
}

.cc-compliance {
    @include make-container();
    @include make-container-max-widths();
}

.cc-btn {
    @include button();
    @include reset-hover();

    color:            $button-primary-color;
    background-color: $button-primary-background-color;
    border-color:     $button-primary-border-color;

    &:focus-within,
    &:focus,
    &:hover {
        color:            $button-primary-hover-color;
        background-color: $button-primary-hover-background-color;
        border-color:     $button-primary-hover-border-color;
    }


}

.cc-top {
    bottom: auto;
    top: 0;
}
