// Styles for Typo 3 Text Image Element (ce-textpic)

.ce-textpic {
  .ce-gallery {
    .col {
      flex: 0 0 auto;
      width: auto;
    }
  }

  &.ce-left .row {
    justify-content: flex-start;
  }

  &.ce-center .row {
    justify-content: center;
  }

  &.ce-right .row {
    justify-content: flex-end;
  }
}
