//-------------------------------------------------------------
// Buttons
//-------------------------------------------------------------

// Variables
$button-padding: 0 building-units(3);
$button-padding-md: 0 building-units(4);
$button-icon-indent: building-units(1);
$button-icon-font-size: 15px;
$button-border-radius: 5px;

button:focus {
    outline: 0 !important;
}

// Mixin font styles
@mixin font-default() {
  font-weight: bold;
  letter-spacing: .2px;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
}

// Mixin button
@mixin button() {
    cursor: pointer;
    color: $white;
    border: 1px solid transparent;
    padding: $button-padding;
    background: none;
    text-decoration: none;
    border-radius: $button-border-radius;
    white-space: normal;
    display: inline-flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    height: building-units(5);
    max-height: building-units(5);

    @include media-breakpoint-up(md) {
      padding: $button-padding-md;
    }
}

%button {
    @include button();
    @include font-default();
}

.btn {
    @extend %button;
    @extend %reset-hover;

    >.vacomicon {
        margin-right: rem(10px);
        font-size: rem($button-icon-font-size);
    }

    >.icon--right {
        padding-left: rem(5px);
        padding-right: 0;
        margin: 15px;
    }

    &:hover {
        text-decoration: none !important;
    }

    &:focus-within,
    &:focus,
    &:focus {
        color: $text-color;
    }
}

@import "button/button_sizes-vc";
@import "button/button_primary-vc";
@import "button/button_secondary-vc";
@import "button/button_outline-vc";
@import "button/button_oval-vc";
@import "button/button_text-vc";
