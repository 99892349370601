//-------------------------------------------------------------
// Dropdown
//-------------------------------------------------------------

// Variables
$optionlist-br-color: $gray-dark;
$optionlist-bg: $white;
$optionlist-color-primary: $black;
$optionlist-bg-hover: $white-light;
$optionlist-bg-select: $gray-220;
$optionlist-bg-hover-select: $gray-199;

// Dropdown stylings
.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;

    margin-bottom: 5px;

    &__toggle {
        @include pt-sans-regular-16();
        line-height: building-units(3);

        @include media-breakpoint-up(lg) {
            @include pt-sans-regular-18();
            line-height: building-units(3);
        }
        position: relative;
        height: 50px;
        overflow: hidden;
        color: $optionlist-color-primary;
        background-color: $white;
        border: 1px solid $gray-medium-3;
        border-radius: 0;
        width: 100%;
        margin-bottom: 0;
        padding: building-units(1) building-units(3.5) building-units(1) building-units(1.5);

        @include reset-hover();

        &--no-arrow {
            &:after {
                display: none !important;
            }
        }

        &:focus,
        &.active,
        &:focus-within {
            border-color: $black;
            color: $optionlist-color-primary;
            background-color: $white;
        }

        //separate because of IE
        &:hover {
            border-color: $black;
            color: $optionlist-color-primary;
            background-color: $white;
        }

        &:after {
            @extend %vacomicon;
            content: "\e924";

            position: absolute !important;
            top: 50%;
            right: building-units(1.5);

            transform: translateY(-50%);

            height: 10px;
            font-size: 10px;

            color: $black;
        }
    }

    &__content {
        display: none;
        position: absolute;
        top: building-units(5) - 1px;
        left: 0;
        min-width: building-units(15);
        z-index: 5;
        border: 1px solid $optionlist-br-color;
        border-radius: 0;
        background-color: $optionlist-bg;
        padding: building-units(1);
        overflow: hidden;
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: $optionlist-bg;
        overflow: auto;
    }

    &__link {

        @include pt-sans-regular-16();
        line-height: building-units(5);

        @include media-breakpoint-up(lg) {

            @include pt-sans-regular-18();
            line-height: building-units(5);
        }

        color: $optionlist-color-primary;

        text-decoration: none;
        padding: 0 building-units(1.5);

        height: building-units(5);

        display: block;
        margin-bottom: 0;

        @include reset-hover();

        &:focus,
        &:focus-within {
            background: $optionlist-bg-hover;
            color: $purple;
        }

        &:hover {
            background: $optionlist-bg-hover;
            color: $purple;
        }

        &:active {
            background: $optionlist-bg-hover;
            color: $purple;
        }
    }

    &__item {
        margin: 0;
        padding: 0;

        label {
            word-break: break-word !important;
        }
    }

    &__item--inactive {
        .dropdown__link {
            color: $gray-medium-2;
            cursor: no-drop;

            &:focus,
            &.active,
            &:focus-within {
                color: $gray-medium-2 !important;
            }

            //separate because of IE
            &:hover {
                color: $gray-medium-2 !important;
            }
        }
    }

    &--open {
        .dropdown__toggle {

            border-color: $black;
            color: $optionlist-color-primary; // add for IE
            background-color: $white;

            &:after {
                content: "\e900"
            }
        }

        .dropdown__content {
            display: block;
            width: 100%;
        }
    }

    &--open-left {
        .dropdown__content {
            left: auto;
            right: 0
        }
    }

    &--open-center {
        .dropdown__content {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &--open-top {
        .dropdown__content {
            top: auto;
            bottom: calc(100% + #{building-units(.5)});
        }
    }

    &--plain {
        width: auto;

        .dropdown__toggle {
            background-color: transparent;
            border-color: transparent;
            padding-left: 0;
            padding-right: 40px;
        }

        &:hover .dropdown__toggle,
        &:hover .dropdown__toggle:after {
            color: $gray-38;
        }
    }

    &--cozy {
        .dropdown__toggle {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &--dark {
        .dropdown__toggle {
            color: #fff;
        }
    }

    &--inactive {
        .dropdown__toggle {
            color: $gray-medium-2;
            background-color: $white-light;
            border-color: $gray-medium-1;
            cursor: no-drop;

            &:after {
                color: $gray-medium-2;
            }

            &:focus,
            &.active,
            &:focus-within {
                color: $gray-medium-2 !important;
            }

            //separate because of IE
            &:hover {
                color: $gray-medium-2 !important;
            }
        }
    }

    &--alert {
        .dropdown__toggle {
            background-color: rgba($red, .05);
            border-color: $red;
            color: $red;

            &:after {
                color: $red
            }

            &:hover,
            &:focus,
            &:focus-within {
                background-color: rgba($red, .05);
            }
        }
    }
}

.validation-message {
    @extend %text-small;

    color: $red;
}
