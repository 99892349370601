//-------------------------------------------------------------
// Buttons
//-------------------------------------------------------------

// Variables
$button-padding:        building-units(.5) building-units(2);
$button-icon-indent:    building-units(1);
$button-icon-font-size: 18px;
$button-border-radius:  building-units(1 / 3);

button:focus {outline:0 !important;}

// Mixin
@mixin button() {
    display:          inline-block;
    text-align:       center;
    vertical-align:   middle;
    cursor:           pointer;
    border:           1px solid transparent;
    border-radius:    $button-border-radius;
    line-height:      rem(22px);
    padding:          $button-padding;
    font-weight:      normal;
    white-space:      nowrap;
    background:       none;
    text-decoration:  none;
    font-size:        $font-size-base;
}

%button {
    @include button();
}

.btn {
    @extend %button;
    @extend %reset-hover;

    > .teleicon {
        margin-top:     -1px;
        padding-right:  rem(5px);
        font-size:      rem($button-icon-font-size);
    }

    > .icon--right {
        padding-left:   rem(5px);
        padding-right:  0;
    }

    &:focus-within,
    &:focus,
    &:focus {
        color:          $text-color;
    }
}

//-------------------------------------------------------------
// Button primary
//-------------------------------------------------------------

.btn--primary {
    color:            $button-primary-color;
    background-color: $button-primary-background-color;
    border-color:     $button-primary-border-color;

    &:focus-within,
    &:focus,
    &:hover {
        color:            $button-primary-hover-color;
        background-color: $button-primary-hover-background-color;
        border-color:     $button-primary-hover-border-color;
    }

    &:active {
        color:            $button-primary-active-color;
        background-color: $button-primary-active-background-color;
        border-color:     $button-primary-active-border-color;
    }

    &[disabled] {
        cursor:       not-allowed;
        background:   $button-primary-disabled-background-color;
        border-color: $button-primary-disabled-border-color;
        color:        $button-primary-disabled-color;
    }

    &.btn--ghost {
      border-color:       $button-primary-ghost-border-color;
      color:              $button-primary-ghost-text-color;
      background-color: transparent;

      &:focus-within,
      &:focus,
      &:hover,
      &:active {
        border-color:     rgba(0,0,0,0.25);
        color:            $button-primary-ghost-focus-color;
        background-color: rgba(0,0,0,0.25);
      }
    }
}

//-------------------------------------------------------------
// Button secondary
//-------------------------------------------------------------

.btn--secondary {
    color:                $button-secondary-color;
    background-color:     $button-secondary-background-color;
    border-color:         $button-secondary-border-color;

    &:focus-within,
    &:focus,
    &:hover {
        color:            $button-secondary-hover-color;
        background-color: $button-secondary-hover-background-color;
        border-color:     $button-secondary-hover-border-color;
    }

    &:active {
        color:            $button-secondary-active-color;
        background-color: $button-secondary-active-background-color;
        border-color:     $button-secondary-active-border-color;
    }

    &[disabled] {
        cursor:       not-allowed;
        background:   $button-secondary-disabled-background-color;
        border-color: $button-secondary-disabled-border-color;
        color:        $button-secondary-disabled-color;
    }


}

//-------------------------------------------------------------
// Button outline
//-------------------------------------------------------------

.btn--outline {
    color:            $button-outline-color;
    background-color: $button-outline-background-color;
    border-color:     $button-outline-border-color;

    &:focus-within,
    &:focus,
    &:hover {
        color:            $button-outline-hover-color;
        background-color: $button-outline-hover-background-color;
        border-color:     $button-outline-hover-border-color;
    }

    &:active {
        color:            $button-outline-active-color;
        background-color: $button-outline-active-background-color;
        border-color:     $button-outline-active-border-color;
    }

    &[disabled] {
        cursor:       not-allowed;
        background:   $button-outline-disabled-background-color;
        border-color: $button-outline-disabled-border-color;
        color:        $button-outline-disabled-color;
    }
}

.btn--icon {
    padding: 6px 12px;

    > .teleicon {
        padding-right: 0;
    }
}

.btn--round {
  border-radius: 100%;
  max-width: building-units(3);
  max-height: building-units(3);

  > .teleicon {
    left: -4px;
    top: -1px;
  }
}

.btn--small {
  max-height:       24px;
  line-height:      rem(10px);
  padding:          6px;
  font-size:        rem(12px);

  > .teleicon {
    margin-top:     -1px;
    left:           0;
    top:            0;
    font-size:      rem(12px);
  }
}

.btn--medium {
  max-height:       28px;
  line-height:      rem(16px);
  padding:          6px;
  font-size:        rem(15px);

  > .teleicon {
    margin-top:     -2px;
    font-size:      rem(15px);
  }

  &.btn--round {
    > .teleicon {
      left:         0;
    }
  }

}

//-------------------------------------------------------------
// Button Link
//-------------------------------------------------------------

.btn--link {
    &:hover,
    &:focus,
    &:active {
        color: $button-link-hover-color;
    }
}


.btn--shadow {
    box-shadow: 2px 2px 4px rgba(0,0,0,0.15);
}

.btn--block {
    width: 100%;
}


// Button Groups
.btn-group {
    display:        flex;
    flex-flow:      row nowrap;

    // For radio and checkbox button groups
    > .radio-btn,
    > .checkbox-btn {
        position:   relative;
    }

    input[type="radio"],
    input[type="checkbox"] {
        opacity:    0;
        position:   absolute;
        
        + label.btn {
            position: relative;
        }
    }
}