//-------------------------------------------------------------
// banner
//-------------------------------------------------------------
// Variables

.banners {
  width: 100vw;
  margin-left: -50vw;
  position: relative;
  left: calc(50%);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: building-units(4);

  @include media-breakpoint-up(md) {

    min-height: 350px;
    padding: building-units(3);
  }

  &__wrapper {
    @extend .container;

    display: flex;
    justify-content: flex-start;
  }

  &__content {
    @include make-col(12, 12);
    padding-right: building-units(1);

    @include media-breakpoint-up(sm) {
      @include make-col(8, 12);
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    @include make-col(12, 12);

    @include media-breakpoint-up(sm) {
      @include make-col(4, 12);
    }
  }

  &__large_claim {

    @include serif-regular-34();

    color: $purple;
    display: block;

    .banners--background-secondary & {

      color: $white;
    }
  }

  &__introtext {

    @include sans-regular-18();

    color: $black;
    display: block;

    margin: building-units(1) 0 building-units(3) 0;

    @include media-breakpoint-up(md) {

      margin: building-units(2) 0 building-units(4) 0;
    }

    .banners--background-secondary & {

      color: $white;
    }
  }

  &--background-primary {
    background-color: $gray-medium-1;
    color: $black;
  }

  &--background-secondary {
    background-color: $purple;
  }

  &--background-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &--position-center {
    > .banners__wrapper {
      justify-content: center;
      flex-flow: wrap;
    }

    .banners__content, .banners__button {
      @include make-col(12, 12);
      text-align: center;
      justify-content: center;
    }
  }

  @include media-breakpoint-only(xs) {
    > .banners__wrapper {
      justify-content: center;
      flex-flow: column;
      display: block; // fix for IE
    }

    .banners__content, .banners__button {
      @include make-col(12, 12);
      text-align: center;
      justify-content: center;
      padding-left: 0;
    }
  }
  @include media-breakpoint-down(md) {
    .banners__button {
      padding-left: 0;
    }
  }
}

.banners:not(.banners--position-center) .banners__button {
  padding-left: building-units(1);
}
