.product-details-top {
    .add-to-wishlist {
        position: absolute;
        top: building-units(2.5);
        right: building-units(5);

        &:before {
            font-size: 26px;
        }
    }

    .price {
        font-size: 26px;
    }

    #product_addtocart_form .inp-spinner {
        padding-right: 5px;

        .inp-spinner__input {
            max-width: inherit;
        }
    }

    #product_addtocart_form .pds-items-cart {
        padding-left: 5px;
    }
}

.material,
.purityclass {
    margin-top: building-units(2);
    margin-bottom: building-units(2);

    &__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__label {
        @extend .text-small;
        color: $gray-dark;
        font-weight: bold;
        margin-bottom: building-units(1);
    }

    &__btn {
        display: inline-table;
        background-color: transparent;
        border: 1px solid $gray-medium-2;
        border-radius: 8px;
        margin-right: 10px;
        width: building-units(4);
        height: building-units(4);
        text-align: center;
        @extend .text-small;
        line-height: building-units(3.6);
        margin-bottom: 5px;
        padding: 0 5px;

        &:hover {
            border-color: $black;
        }

        &--active {
            color: $purple;
            border-color: $purple;
        }

        &--disabled {
            color: $gray-medium-1;
            border-color: $gray-medium-1;
            cursor: no-drop;

            &:hover {
                color: $gray-medium-1;
                border-color: $gray-medium-1;
                cursor: no-drop;
            }

            &:focus {
                color: $gray-medium-1;
                border-color: $gray-medium-1;
                cursor: no-drop;
            }
        }

        &--self {
            color: $purple;
            border-color: $purple;
            cursor: no-drop;
            position: relative;

            &:hover {
                border-color: $purple;
            }

            /*
            &:after {
                font-family: VacomIcon, serif;
                content: '\e906';
                position: relative;
                right: 10px;
                top: -10px;
            }
             */
        }
    }
}

.requestDeliveryDate {
    font-family: $vacom-font-family-sans-serif;
    font-weight: $font-weight-normal;
    font-size: 18px;
    font-style: normal;

    &__label {
      @extend .text-small;
      color: $gray-dark;
      margin-bottom: building-units(1);
      cursor: pointer;
    }

    &__spinner-dd {
      align-items: center;
      display: flex;
    }
}

.modal-check-availability {
  @include media-breakpoint-down(xs) {
    display: block !important;
  }
}

.material__btn {
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    width: auto;
}

#gallery-prev-area {
    height: 0;
    width: 0;
    position: absolute;
    left: -10000px;
}

.product-detail {
    &__download {
        margin-bottom: building-units(1);

        &--link {
            &:hover {
                text-decoration: underline;
            }
        }

        .vacomicon {
            color: $purple;
        }
    }

    &__td {
        border: none;
    }


}

.fotorama__stage__frame {
    border: 1px solid $gray-light !important;

    .fotorama__img {
        max-width: 99%;
    }
}

.product-details-tabbar {
    position: relative;

    .buttons {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        margin-top: building-units(2);
        margin-bottom: building-units(1.5);

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: -30px;
            right: 10px;
            z-index: 150;
        }
    }
}

.product-details-tabbar .tab-pane {
    margin-top: -7px;

    &.downloads {
        margin-top: building-units(1) * -1;

        table tr:last-child td,
        table tr:last-child th {
            border-bottom: transparent;
        }
    }
}

table.technical-sketch {
    > tbody {
        width: 100%;

        th {
            width: 15%;
        }

        th, td {
            white-space: nowrap;
            word-break: keep-all;
            padding: building-units(1) building-units(1.5);
        }
    }
}

body.catalog-product-view .modal__content {
    overflow-x: auto !important;
}

body.catalog-product-view {
    .additional-attributes-wrapper th {
        width: 25%;
    }

    .additional-attributes-wrapper tr {
        width: 75%;
    }
}

.dual-use-good {
    text-align: center;
    background-color: #f4f4f4;
    width: 100%;

    p {
        margin-bottom: 0;
        padding: building-units(1.5) 0;
    }
}
