//-------------------------------------------------------------
// Textarea
//-------------------------------------------------------------

// Variables
$textarea-minheight: rem(97px);
$textarea-height:    rem(97px);

// Textarea Styling
.form-textarea {
    @extend .form-input;

    overflow:   auto;
    min-height: $textarea-minheight;
    height:     $textarea-height;
    resize:     vertical;
}
