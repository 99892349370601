//-------------------------------------------------------------
// Input with Addon
//-------------------------------------------------------------

// Variables 
$addon-padding:        building-units(.5) building-units(1);
$addon-height:         building-units(3);
$addon-border-radius:  4px;

// Addon Styling
.form-input-set {
    .addon-wr {
        display: flex;
        clear:   both;

        .input-addon {
            padding:          $addon-padding;
            height:           $addon-height;
            background-color: $addon-input-background-color;
            color:            $addon-input-color;
            border-radius:    $addon-border-radius;
            border:           1px solid $addon-border-color;
        }

        &.addon-left {
            .input-addon {
                @include reset-border-radius-direction("right");

                border-right: 0;
            }

            .form-input {
                @include reset-border-radius-direction("left");
            }
        }

        &.addon-right {
            .input-addon {
                @include reset-border-radius-direction("left");

                border-left: 0;
            }

            .form-input {
                @include reset-border-radius-direction("right");
            }
        }
    }
}
