//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables

.lazyload {
    &__banner {
        background-color: rgba(0, 0, 0, 0.3);
        padding: building-units(2);
    }

    &__banner_text {
        color: $white;
        margin-bottom: 25px;
    }
}

.maps_google {
    .lazyload__banner {
        width: 100%;
        height: 100%;
        padding: 0;
        background-image: url("../assets/images/maps/WorldMap.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .lazyload--play {
        height: 100%;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.3);
        cursor: pointer;
    }
}

.video-player {
    &.js-lazyLoad {
        min-height: 360px;
    }

    .lazyload__banner {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        min-height: 360px;
        overflow-y: auto;
        padding-bottom: 46.25%;

    }

    .lazyload--play {
        height: 100%;
        min-height: 360px;
        padding-bottom: 46.25%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.3);
        cursor: pointer;
        background-image: url("../assets/images/videos/VideoBanner.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &.js-lazyLoad > figure.video > .video-embed {
        padding-bottom: 0;
    }
}
.frame-type-3cols .video-player .lazyload__banner {
    position: relative;
}
.frame-type-4cols .video-player .lazyload__banner {
    position: relative;
}

html[lang="de"] .maps_google .lazyload__banner {
    background-image: url("../assets/images/maps/WorldMap_de.jpg");
}

html[lang="en"] .maps_google .lazyload__banner {
    background-image: url("../assets/images/maps/WorldMap_en.jpg");
}

html[lang="de"] .video-player .lazyload--play {
    background-image: url("../assets/images/videos/VideoBanner_de.jpg");
}

html[lang="en"] .video-player .lazyload--play {
    background-image: url("../assets/images/videos/VideoBanner_en.jpg");
}
