.video-player {
    width: 100%;
    height: auto;
    position: relative;

    figure.video {
        width: 100%;
        height: auto;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        margin: 0;
        overflow: hidden;
    }

    .video-embed {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        max-width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    iframe {
        border-width: 0;
        border-style: inset;
        border-color: initial;
        border-image: initial;
    }

    iframe #player {
        width: 100% !important;
        @extend %imageTransitionHoverOut;
    }

    &__preview {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 0;
    }
    
    &--locked {
        position: relative;
    }

    &--locked::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 8;
        margin-left: -30px;
        margin-top: -30px;
        width: 60px;
        height: 60px;
        background-color: rgba($purple, 0.6);
        border-radius: 50%;
        content: " ";

        @include media-breakpoint-down(md) {
            margin-left: -45px;
            margin-top: -45px;
            width: 90px;
            height: 90px;
        }
    }

    &--locked:after {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 8;
        margin-top: -15px;
        margin-left: -15px;
        font-size: 30px;
        width: 30px;
        height: 30px;
        font-family: 'vadfemicon', serif;
        content: '\43';
        color: $purple;

        @include media-breakpoint-down(md) {
            margin-left: -25px;
            margin-top: -15px;
            font-size: 50px;
            width: 50px;
            height: 50px;
        }
    }

    &--play:after {
        position: absolute;
        bottom: -40px;
        right: 30px;
        z-index: 8;
        font-size: 55px;
        padding-top: 13px;
        width: 55px;
        height: 55px;
        margin-left: -27.5px;
        background-color: $white;
        border-radius: 50%;
        font-family: 'vacomicon';
        content: '\b3';
        color: $purple;
    }

    figcaption {
        margin-top: 25px;
        line-height: 15px;
        color: $gray-38;
        @extend %text-small;

        .vacomicon {
            margin-right: 5px;
        }
    }
}

div:not(.frame) > div.video-player iframe,
div:not(.teaser__image) > div.video-player iframe {
    border: 1px solid $white-light;
}

div:not(.teaser__image) > div.video-player--locked {
    &:before {
        margin-top: -60px;
    }

    &:after {
        margin-top: -43px;
    }
}

.video-embed.vimeo-responsive {
    padding-bottom: 0 !important;
}

/**
 * uploaded file video player
 */
.ce-gallery video.video-embed-item {
    width: 100%;
}