//-------------------------------------------------------------
// Tabbar
//-------------------------------------------------------------

$tabbar-container-height: auto;

.tabbar-container {
    border-bottom: 1px solid $tabs-border-color;
    display:       none;
    height:        $tabbar-container-height;

    @include media-breakpoint-down(sm) {
        &.horizontal-scroller-container {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;
        flex-flow: row nowrap;
    }

    .horizontal-scroller-btn {
        padding-top: 12px;
    }
}

@import "tabbar-list";
@import "tabbar-link";
@import "tabbar-content";
@import "tabbar-dropdown";