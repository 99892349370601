//-------------------------------------------------------------
// Header
//-------------------------------------------------------------
$header-br-color: $gray-light;

.header-vc {
    position: relative;
    z-index: 250;
    background-color: $white;
    border-bottom: 1px solid $header-br-color;
    transition: height 0.3s;

    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: normal;
        align-content: normal;

        @extend .container;
    }

    &--left {
        max-height: 120px;
        width: 180px;
        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: auto;
        order: 0;

        @include media-breakpoint-up(lg) {
            margin-right: 30px;
        }

        @include media-breakpoint-down(md) {
            width: 100px;
            max-height: 80px;
            min-width: 80px;
        }
    }

    &--right {
        display: block;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: auto;
        order: 0;
    }

    &--fixed {
        position: fixed;
        width:    100%;
        z-index:  151;
        height: 81px;

        .top-navigation {
            height: 30px;
        }

        .navbar-vc,
        .navbar-vc__container {
            height: 50px;
        }

        .brandbar-vc__logo-wrapper {
            width: 100px;
            height: 79px;
            box-shadow: inherit;
            background-color: transparent;
        }

        @include media-breakpoint-up(md) {
            .brandbar-vc__logo {
                height: 60px;
            }
        }

        .megamenu-vc {
            top: 38px;
            padding-top: 20px;
        }

        .search-suggestion-container {
            top: 50px;

            @include media-breakpoint-down(sm) {
                top: 80px;
            }
        }

        .navbar-search-vc {
            bottom: 5px;
        }
    }
}

//-------------------------------------------------------------
// Brandbar
//-------------------------------------------------------------
.brandbar-vc {
    transition: height 0.3s, padding-bottom 0.3s;

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        position: relative;
        z-index: 1001;
    }

    &__logo-link {
        display: block;
    }

    &__logo-wrapper {
        transition: height 0.3s, width 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        @include media-breakpoint-up(lg) {
            width: 180px;
            height: 160px;
            background-color: $white;
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        }
    }

    &__logo {
        transition: height 0.3s, width 0.3s;
        height: 70px;
        width: auto;

        @include media-breakpoint-up(lg) {
            height: 100px;
        }
    }
}

.action {
    @extend %text-small;
    text-align: center;
    color: $purple;

    .vacomicon {
        position: relative;
        font-size: 18px;
        height: 18px;
        width: 18px;
    }

    .badge {
        position: absolute;
        left: 14px;
        top: -14px;
    }

    &:last-child {
        margin-right: 0;
    }
}
