//-------------------------------------------------------------
// Section
//-------------------------------------------------------------
// Variables
$section-bg-dark-gray: #4a4a4a;

.section {
  color: $gray-dark;
  overflow: hidden;
  width: 100%;
  position: relative;
  background-color: $white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: building-units(4) building-units(1);
  //default padding

  border: 0 none;

  &__content {
    @extend .container;
    min-width: auto;
    position: relative;
    z-index: 2; //to lay over the dimmer container
    padding: 0 building-units(1); //default padding

    @include media-breakpoint-down(sm) {
      padding: 0 building-units(0.5);
    }

    .topline {

      margin: 0;
    }
  }

  &--border {
    border-top: 1px solid $white-light;
    border-bottom: 1px solid $white-light;
  }

  &--fullwidth {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: calc(50%);
  }

  //BACKGROUND COLORS
  &--bg-primary {
    border-color: $white-light;
    background-color: $white-light;

    h2, p, .card_vc h3 {
      color: $purple;
    }
  }

  &--bg-default,
  &--bg-secondary {
    border-color: $gray-light;
    background-color: $gray-light;

    h2, p {
      color: $gray-dark;
    }
  }

  &--bg-gradient {
    background-color: $white-light;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gray-light', endColorstr='$white',GradientType=0 );
    background: $white-light;
    background: -moz-linear-gradient(top, $white-light 0%, $white 100%);
    background: -webkit-linear-gradient(top, $white-light 0%, $white 100%);
    background: linear-gradient(to bottom, $white-light 0%, $white 100%);
    color: $gray-dark;
  }

  &--offset-huge {
    .section_vc__content {
      @include media-breakpoint-up(lg) {
        padding: 0 calc((((100% / 12) / 2) * 3));
      }

      @include media-breakpoint-only(md) {
        padding: 0 calc((((100% / 12) / 2) * 2));
      }

      @include media-breakpoint-only(sm) {
        padding: 0 calc(((100% / 12) / 2));
      }
    }
  }

  &--offset-small {
    .section_vc__content {
      @include media-breakpoint-up(sm) {
        padding: 0 calc(((100% / 12) / 2));
      }
    }
  }

  &--image-top-left {
    background-position: top left;
  }

  &--image-center {
    background-position: center center;
  }

  &--align-left {
    .section_vc__content {
      text-align: left;
    }
  }

  &--align-center {
    .section_vc__content {
      text-align: center;
      margin: 0 auto;
    }

    .topline:before {
      left: calc(50% - 22.5px);
    }
  }

  &--align-right {
    .section_vc__content {
      text-align: right;
    }
  }

  &--no-overflow {
    overflow: inherit;
  }

  .card_vc.card_vc--bg-primary {
    > h3, p {
      color: $gray-dark;
    }
  }
}

