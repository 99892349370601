//-------------------------------------------------------------
// Color system
//-------------------------------------------------------------

// Basic Colors
$white: #fff;
$gray-248: #f8f8f8;
$gray-237: #ededed;
$gray-220: #dcdcdc;
$gray-208: #d0d0d0;
$gray-199: #c7c7c7;
$gray-194: #c2c2c2;
$gray-184: #b8b8b8;
$gray-178: #b2b2b2;
$gray-164: #a4a4a4;
$gray-151: #979797;
$gray-117: #757575;
$gray-56: #383838;
$gray-38: #262626;
$black: #000;

$magenta: #e20074;
$magenta-227: #e3bacf;
$magenta-208: #d0006b;
$magenta-188: #bc0060;
$magenta-160: #a00053;
$magenta-140: #8c0048;

$blue: #00a0de;
$blue-175: #007faf;
$blue-121: #005879;

$blue-rgba-50: rgba($blue, 0.5);

// Function Colors
$red: #d90000;
$red-208: #d0021b;
$red-253: #fdf2f2;
$red-251: #fbe6e6;
$green: #babc59;
$green-240: #f0f7e9;
$green-248: #f8fbf4;

$green-rgba-50: rgba($green, 0.5);
$magenta-rgba-50: rgba($magenta, 0.5);

//-------------------------------------------------------------
// Theming
//-------------------------------------------------------------

// Global Color Definition
$color-brand: $magenta;
$color-text: $gray-56;
$color-subtext: #6b6b6b;
$color-area: $gray-237;
$color-background: $gray-38;
$color-area-hover: $gray-220;
$color-link: #00739f;
$color-success: #6bb324;
$color-error: $red;
$color-alert: #b30000;
$color-warning: #eca859;
$color-hint: #babc59;
$color-info: #63b8e3;
$color-tip: #408e99;

// Global
$text-color: $color-text;
$site-background-color: $white;
$link-color: $blue-175;
$link-hover-color: $link-color;

// Components

// Accordion
$accordion-border-color: $gray-220;
$accordion-headline-color: $text-color;
$accordion-headline-background-color: transparent;
$accordion-headline-hover-color: $color-brand;
$accordion-headline-hover-background-color: $gray-237;
$accordion-toggle-color: $gray-56;
$accordion-toggle-hover-color: $accordion-headline-hover-color;
$accordion-headline-open-color: $color-brand;
$accordion-headline-open-background-color: $gray-237;
$accordion-toggle-open-color: $accordion-headline-open-color;
$accordion-inactive-color: $gray-184;

// Blockquote
$blockquote-text-link-color: $gray-56;
$blockquote-text-link-hover-color: $blue-175;
$blockquote-card-text-color: $white;

// Brand Bar
$brandbar-background-color: $magenta;
$brandbar-text-color: $white;

// Breadcrumbs
$breadcrumb-current-link-color: $text-color;
$breadcrumb-link-color: $gray-117;

// Briefing Components
$briefing-background-color: #f4f6f8;
$briefing-subline-text-color: #eca859;
$briefing-component-border-color: $gray-194;
$briefing-component-content-light-background-color: $white;
$briefing-component-content-dark-background-color: $gray-56;
$briefing-component-content-dark-text-color: $white;
$briefing-component-image-link-color: $gray-56;
$briefing-component-image-link-dark-color: $white;
$briefing-component-image-link-hover-color: $color-brand;

// Theme Switcher
$theme-switcher-checkbox-border: #ccc;
$theme-switcher-light-background: $white;
$theme-switcher-dark-background: $gray-56;
$theme-switcher-dark-color: $gray-56;

// Buttons
$button-primary-color: $white !default;
$button-primary-background-color: $magenta !default;
$button-primary-border-color: $magenta-160 !default;
$button-primary-hover-color: $white !default;
$button-primary-hover-background-color: $magenta-160 !default;
$button-primary-hover-border-color: $magenta-160 !default;
$button-primary-active-color: $white !default;
$button-primary-active-background-color: $magenta-188 !default;
$button-primary-active-border-color: $magenta-140 !default;
$button-primary-disabled-color: $gray-184 !default;
$button-primary-disabled-background-color: $gray-248 !default;
$button-primary-disabled-border-color: $gray-220 !default;
$button-primary-ghost-border-color: $white !default;
$button-primary-ghost-text-color: $white !default;
$button-primary-ghost-focus-color: $white !default;

$button-secondary-color: $gray-56;
$button-secondary-background-color: $gray-237;
$button-secondary-border-color: $gray-178;
$button-secondary-hover-color: $gray-56;
$button-secondary-hover-background-color: #dbdbdb;
$button-secondary-hover-border-color: $gray-178;
$button-secondary-active-color: $gray-56;
$button-secondary-active-background-color: $gray-199;
$button-secondary-active-border-color: $gray-117;
$button-secondary-disabled-color: $gray-184;
$button-secondary-disabled-background-color: $gray-248;
$button-secondary-disabled-border-color: $gray-220;

$button-outline-color: $gray-56;
$button-outline-background-color: transparent;
$button-outline-border-color: $gray-178;
$button-outline-hover-color: $gray-56;
$button-outline-hover-background-color: $gray-237;
$button-outline-hover-border-color: $gray-178;
$button-outline-active-color: $gray-56;
$button-outline-active-background-color: $gray-220;
$button-outline-active-border-color: $gray-117;
$button-outline-disabled-color: $gray-184;
$button-outline-disabled-background-color: $white;
$button-outline-disabled-border-color: $gray-220;

$button-link-color: $text-color;
$button-link-background-color: transparent;
$button-link-border-color: $gray-178;
$button-link-hover-color: $color-brand;
$button-link-hover-background-color: $gray-237;
$button-link-hover-border-color: $gray-178;
$button-link-active-color: $gray-56;
$button-link-active-background-color: $gray-220;
$button-link-active-border-color: $gray-117;
$button-link-disabled-color: $gray-184;
$button-link-disabled-background-color: $white;
$button-link-disabled-border-color: $gray-220;

// Cards
$card-primary-background-color: $color-brand;
$card-primary-text-color: $white;
$card-primary-border-color: $gray-194;
$card-primary-teleicon-color: $white;

$card-kicker-text-color: $text-color;
$card-title-text-color: $text-color;
$card-default-background-color: $white;
$card-default-text-color: $gray-56;
$card-default-border-color: $gray-194;

$card-dark-grey-text-color: $white;
$card-dark-grey-background-color: $gray-56;
$card-dark-grey-border-color: $gray-56;
$card-dark-grey-icon-color: $white;
$card-footer-border-color: $gray-248;
$card-footer-timestamp-color: $text-color;
$card-footer-teleicon-color: $text-color;

// Checkbox
$checkbox-background-color: $gray-237;
$checkbox-icon-color: $color-text;
$checkbox-border-color: $gray-178;
$checkbox-selected-icon-color: $color-text;
$checkbox-hover-background-color: $gray-220;
$checkbox-active-background-color: $gray-199;
$checkbox-focus-border-color: $blue;
$checkbox-focus-shadow-color: $blue;
$checkbox-icon-color: $green;
$checkbox-error-focus-border-color: $gray-178;

$checkbox-disabled-background-color: $gray-248;
$checkbox-disabled-border-color: $gray-220;
$checkbox-disabled-icon-color: $green-rgba-50;

$checkbox-error-background-color: $red-253;
$checkbox-error-border-color: $red;
$checkbox-error-icon-color: $red;
$checkbox-error-hover-icon-color: $red-251;
$checkbox-error-focus-border-color: $red;
$checkbox-error-focus-shadow-color: $red;

// Collapse-menu
$collapse-menu-item-backgroung-color: $white;
$collapse-menu-border-bottom-color: $gray-220;
$collapse-menu-toggle-color: $gray-56;
$collapse-menu-content-background-color: $gray-248;
$collapse-menu-quicklinks-border-top-color: $gray-220;
$collapse-menu-quicklinks-title-color: $gray-117;

// Comment
$comment-date-color: $gray-117;

// Component-Demonstrator
$component-demonstrator-after-color: $blue;

// Cookie-Banner
$cookie-banner-dark-text-color: $white;
$cookie-banner-dark-link-color: $white;
$cookie-banner-light-background-color: $white;
$cookie-banner-dark-background-color: $gray-56;

// Dos & Donts
$dos-text-color: $white;
$donts-text-color: $white;

// Dropdown
$optionlist-border-color: $gray-178;
$optionlist-background: $white;
$optionlist-color: $gray-56;
$optionlist-hover-background-color: #d0d0d0;
$optionlist-select-background-color: $gray-220;
$optionlist-select-hover-background-color: $gray-199;
$optionlist-select-focus-background-color: $gray-220;
$optionlist-item-dropdown-link-inactive-color: $gray-184;
$optionlist-item-dropdown-toggle-open-color: $gray-199;
$optionlist-item-dropdown-alert-border-color: $red;
$optionlist-item-dropdown-alert-background-color: $red-253;
$optionlist-item-dropdown-alert-focus-color: $red-251;
$optionlist-dropdown-toggle-dark-color: $white;
$optionlist-dropdown-toggle-inactive-color: $gray-184;
$optionlist-dropdown-toggle-inactive-background-color: $white;
$optionlist-dropdown-toggle-inactive-border-color: $gray-220;

// Footer
$footer-background-color: $color-background;
$footer-text-color: $white;
$footer-link-color: $white;
$footer-link-hover-color: $white;

// Hero Image
$hero-image-background-color: $magenta;
$hero-image-text-color: $white;
$hero-image-link-color: $white;
$hero-image-link-hover-color: $blue;

// Horizontal Scroller
$horizontal-scroller-btn-color: $text-color;
$horizontal-scroller-btn-hover-color: $magenta;
$horizontal-scroller-btn-background-color: $white;

// Icon Collection
$icon-collection-tile-border: #efefef;
$icon-collection-header-background-color: $gray-237;
$icon-collection-header-color: $black;
$icon-collection-header-dark-background: $gray-117;
$icon-collection-name-color: $gray-117;
$icon-collection-download-color: #383838;
$icon-search-before-color: $gray-56;

// Images
$images-caption-color: $gray-56;

// Info Hints
$info-hint-info-color: $color-info;
$info-hint-warning-color: $color-warning;
$info-hint-alert-color: $color-alert;
$info-hint-hint-color: $color-hint;
$info-hint-tip-color: $color-tip;

// Input
$input-background-color: $white;
$input-border-color: $gray-178;
$input-color: $gray-56;
$input-hover-background-color: $gray-237;
$input-focus-border-color: $blue;
$input-active-background-color: $gray-220;
$input-disabled-border-color: $gray-220;
$input-disabled-color: $gray-184;
$input-focus-box-shadow-color: $blue-rgba-50;
$input-icon-color: $blue;

// Input with Addon
$addon-input-background-color: $gray-237;
$addon-input-color: $gray-56;
$addon-border-color: $gray-178;

// Input Validation Styling
$input-error-background-color: $red-253;
$input-error-hover-background-color: $red-251;
$input-error-color: $red;

$input-success-background-color: $green-248;
$input-success-hover-background-color: $green-240;
$input-success-color: $green;

// Label
$label-color: $gray-56;
$label-mandatory-color: $blue-175;

// Links
$link-color: $blue-175;
$link-hover-color: $blue-121;

// Link icon
$link-icon-color: $text-color;
$link-icon-hover-color: $text-color;

// Link List Container
$link-list-headline-border-color: $gray-220;
$link-list-link-color: $white;

// Living Example Demonstrator
$living-example-demonstrator-background-color: #f4f6f8;
$living-example-demonstrator-top-background-color: $gray-248;
$living-example-demonstrator-top-border-bottom-color: $gray-220;
$living-example-demonstrator-button-background-color: $gray-248;
$living-example-demonstrator-button-icon-color: $gray-56;
$living-example-demonstrator-selected-background-color: #727272;
$living-example-demonstrator-medium-screen-background-color: #e5e5e5;
$living-example-demonstrator-large-screen-background-color: #d9d9d9;
$living-example-demonstrator-xlarge-screen-background-color: #cccccc;
$living-example-demonstrator-notch-color: #757575;

// Navbar
$navbar-background-color: $white;
$navbar-border-color: $gray-220;
$navbar-link-color: $text-color;
$navbar-link-hover-color: $magenta;
$navbar-link-active-color: $text-color;
$navbar-link-active-background-color: $gray-199;
$navbar-item-hover-border-color: $navbar-link-hover-color;
$megamenu-background-color: $navbar-background-color;
$megamenu-link-active-color: $text-color;
$megamenu-submenu-link-active-color: $magenta;
$search-background-color: $navbar-background-color;
$navbar-close-button-color: $gray-56;
$navbar-close-button-hover-color: $magenta;
$navbar-mobile-menu-background-color: $white;
$navbar-mobile-menu-title-color: $magenta;

// Pagination
$pagination-text-color: $text-color;
$pagination-item-background-color: $white;
$pagination-item-border-color: $gray-178;
$pagination-item-hover-text-color: $text-color;
$pagination-item-hover-background-color: $gray-237;
$pagination-item-hover-border-color: $gray-178;
$pagination-item-active-text-color: $gray-56;
$pagination-item-active-background-color: $gray-220;
$pagination-item-active-border-color: $gray-117;
$pagination-item-disabled-text-color: $gray-184;
$pagination-item-disabled-background-color: $white;
$pagination-item-disabled-border-color: $gray-220;

// Placeholder
$placeholder-color: $gray-117;
$placeholder-disabled-color: $gray-184;

// Prev - Next
$pre-next-background-color: $white;
$pre-next-border: $gray-220;
$pre-next-focus-background-color: #ededed;
$pre-next-active-background-color: #d0d0d0;
$pre-next-link-next-color: #383838;
$pre-next-link-next-direction-color: #007faf;

// Radio Buttons
$radiobutton-background-color: $gray-237;
$radiobutton-border-color: $gray-178;
$radiobutton-hover-background-color: $gray-220;
$radiobutton-active-background-color: $gray-199;
$radiobutton-focus-border-color: $blue;
$radiobutton-focus-shadow-color: $blue;
$radiobutton-icon-color: $magenta;

$radiobutton-disabled-background-color: $gray-248;
$radiobutton-disabled-border-color: $gray-220;
$radiobutton-disabled-icon-color: $magenta-rgba-50;

$radiobutton-error-background-color: $red-253;
$radiobutton-error-border-color: $red;
$radiobutton-error-hover-background-color: $red-251;
$radiobutton-error-focus-border-color: $red;
$radiobutton-error-focus-shadow-color: $red;
$radiobutton-error-icon-color: $red;

$radio-button-deselected-background-color: $gray-237;
$radio-button-deselected-background-color-border: $gray-178;
$radio-button-deselected-text-color: $gray-56;
$radio-button-selected-background-color: $color-brand;

// Search Result
$search-result-breadcrumb-color: $gray-117;

// Section Menu
$section-menu-link-color: $blue;
$section-menu-link-hover-color: $section-menu-link-color;

// Sections
$section-primary-border-color: $magenta;
$section-primary-background-color: $magenta;
$section-primary-text-color: $white;
$section-secondary-border-color: #4a4a4a;
$section-secondary-background-color: #4a4a4a;
$section-secondary-text-color: $white;
$section-light-white-background-color: $white;
$section-light-grey-border-color: $gray-248;
$section-light-grey-background-color: $gray-248;
$section-image-color: $white;
$section-text-color: $black;
$section-border-bottom-color: $gray-151;
$section-dimmer-white-background-color: rgba(255, 255, 255, 0.5);
$section-dimmer-black-background-color: rgba(0, 0, 0, 0.5);
$section-dimmer-black-text-color: $white;

// Selectbox
$optionlist-border-color: $gray-178;
$optionlist-background: $white;
$optionlist-color: $gray-56;
$optionlist-hover-background-color: $gray-237;
$optionlist-select-background-color: $gray-220;
$optionlist-select-hover-background-color: $gray-199;
$optionlist-inactive-text-color: $gray-184;
$optionlist-selectbox-label-inactive-text-color: $gray-184;
$optionlist-selectbox-label-inactive-background-color: $white;
$optionlist-selectbox-label-inactive-border-color: $gray-220;
$optionlist-selectbox-label-alert-border-color: $red;
$optionlist-selectbox-label-alert-background-color: $red-253;
$optionlist-selectbox-label-alert-focus-background-color: $red-253;
$optionlist-selectbox-label-dark-text-color: $white;

// Sidebar
$sidebar-link-background-color: $white;
$sidebar-link-headline-color: $color-brand;
$sidebar-link-text-color: $gray-56;
$sidebar-link-hover-background-color: $gray-237;
$sidebar-link-hover-text-color: $color-brand;
$sidebar-link-hover-icon-color: $color-brand;
$sidebar-link-hover-icon-background-color: $gray-237;
$sidebar-link-active-border-left: none;
$sidebar-link-active-background-color-hover: transparent;
$sidebar-link-active-background-color-hover-border-left: none;
$sidebar-title-text-color: $gray-117;
$sidebar-footer-icon-color: $gray-56;
$sidebar-mobile-isopen-background-color: $white;
$sidebar-collapse-button-hover-color: $magenta;

// Slider
$slider-content-border: #dbdbdb;
$slider-bullet-hover: #9b9b9b;
$slider-content-background-color: rgba($white, 0.9);
$slider-button-prev-next-hover-background-color: $gray-237;
$slider-scheme-dark-text-color: $white;
$slider-scheme-dark-swiper-slide-content-border-color: #9b9b9b;
$slider-bg-white-swiper-slide-image-background-color: $white;
$slider-bg-magenta-swiper-slide-image-background-color: $magenta;
$slider-bg-secondary-swiper-slide-image-background-color: $gray-56;
$slider-swiper-button-prev-next-after-color: $white;
$slider-swiper-button-prev-next-after-text-shadow-color: $black;
$slider-swiper-button-prev-next-hover-background-color: rgba($gray-237, 0.75);

// Switch
$switch-background-color: $gray-237;
$switch-toggle-background-color: $white;
$switch-border-color: $gray-178;
$switch-checked-border-color: $green;
$switch-checked-hover-border-color: darken($green, 2%);
$switch-hover-background-color: $gray-220;
$switch-active-background-color: $gray-199;
$switch-checked-background-color: $green;
$switch-checked-hover-background-color: darken($green, 2%);
$switch-focus-border-color: $blue;
$switch-focus-shadow-color: $blue;
$switch-icon-color: $green;

$switch-disabled-background-color: $gray-248;
$switch-disabled-border-color: $gray-220;

// Syntax Highlight
$syntax-pre-text-color: $black;
$syntax-code-token-comment-text-color: slategray;
$syntax-code-token-punctuation-color: #999;
$syntax-code-token-property-color: #905;
$syntax-code-token-selector-color: #690;
$syntax-code-token-operator-color: #9a6e3a;
$syntax-code-token-operator-background-color: hsla(0, 0%, 100%, 0.5);
$syntax-code-token-atrule-color: #07a;
$syntax-code-token-function-color: #dd4a68;
$syntax-code-token-regex-color: #e90;
$syntax-line-numbers-rows-color: #999;

// Table
$table-border-color: $gray-220;
$table-sorting-icon-color: $gray-178;
$table-sorting-active-icon-color: $gray-184;

// Tabs
$tabs-border-color: $gray-220;
$tabbar-list-color: $gray-117;
$tabbar-list-hover-color: $text-color;
$tabbar-list-active-color: $color-brand;
$tabbar-list-inactive-color: $gray-184;
$tabbar-list-border-color: transparent;
$tabbar-list-hover-border-color: $gray-237;
$tabbar-list-active-border-color: $color-brand;

// Teaser
$teaser-primary-text-color: $white;
$teaser-primary-background-color: $color-brand;
$teaser-primary-hover-background-color: $magenta;
$teaser-grey-text-color: $white;
$teaser-grey-background-color: $gray-56;
$teaser-grey-hover-background-color: $black;
$teaser-header-link-color: $white;
$teaser-text-link-color: $white;
$teaser-link-label-text-color: $white;
$teaser-magenta-background-color: rgba($magenta, 0.7);
$teaser-grey-background-color: rgba($gray-56, 0.7);
$teaser-rey-content-text-color: $white;

// Tile
$tile-background-color: $white;
$tile-border-color: $gray-164;
$tile-text-color: $gray-38;
$tile-header-background-color: $gray-237;

// Design Tokens Typography

// Toolbar
$toolbar-background-color: $color-background;
$toolbar-text-color: $white;
$toolbar-text-hover-color: $gray-220;

//-------------------------------------------------------------
// Fonts
//-------------------------------------------------------------

// General
$font-family-sans-serif: "TeleGroteskNext", Roboto, "Helvetica Neue", Arial,
  sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;
$font-family-icon: "TeleIcon";

$font-size-base: 18px;
$line-height-base: 24px / $font-size-base;

$font-weight-light: 100;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-ultra: 900;

$font-weight-base: $font-weight-normal;

//-------------------------------------------------------------
// Base Unit Definitions
//-------------------------------------------------------------

$building-unit: 12px;
$rem-baseline: $building-unit;

//-------------------------------------------------------------
// Media queries - Breakpoints
//-------------------------------------------------------------

// Small screen
$screen-xs: 0;

$screen-sm: 320px;
$screen-sm-min: $screen-sm;

// Medium screen
$screen-md: 640px;
$screen-md-min: $screen-md;

// Large screen
$screen-lg: 1024px;
$screen-lg-min: $screen-lg;

// Extra Large screen
$screen-xl: 1500px;
$screen-xl-min: $screen-xl;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 320px,
  md: 640px,
  lg: 1024px,
  xl: 1500px,
) !default;

//-------------------------------------------------------------
// Container Widths
//-------------------------------------------------------------

// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  lg: 1008px,
  xl: 1296px,
) !default;

//-------------------------------------------------------------
// Grid Column Definitions
//-------------------------------------------------------------

// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 24px !default;

//-------------------------------------------------------------
// Copy Text
//-------------------------------------------------------------

$font-size-default: 18px;
$line-height-default: 24px / $font-size-default;

$font-size-text-large: 21px;
$line-height-text-large: 28px / $font-size-text-large;

$font-size-text-small: 15px;
$line-height-text-small: 18px / $font-size-text-small;

$font-size-text-x-small: 12px;
$line-height-text-x-small: 14px / $font-size-text-x-small;

//-------------------------------------------------------------
// Headings
//-------------------------------------------------------------

$font-size-h1-l: 48px;
$line-height-h1-l: 54px / $font-size-h1-l;
$font-size-h1-sm: 36px;
$line-height-h1-sm: 42px / $font-size-h1-sm;

$font-size-h2-l: 36px;
$line-height-h2-l: 42px / $font-size-h2-l;
$font-size-h2-sm: 24px;
$line-height-h2-sm: 30px / $font-size-h2-sm;

$font-size-h3: 24px;
$line-height-h3: 30px / $font-size-h3;

$font-size-h4: 21px;
$line-height-h4: 24px / $font-size-h4;

$font-size-h5: 18px;
$line-height-h5: 24px / $font-size-h5;

$font-size-h6: 15px;
$line-height-h6: 18px / $font-size-h6;

$font-size-display: 60px;
$line-height-display: 72px / $font-size-display;

$margin-headings: 24px 0 6px;

//-------------------------------------------------------------
// Icons
//-------------------------------------------------------------

$icon-font-path: "../assets/fonts/teleicon";
$icon-image-path: "../assets/images/icons";

$icon-solid-font-weight: 700;

$icon-sizes: (12, 15, 18, 20, 24, 36, 48, 60) !default;
