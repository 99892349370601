//-------------------------------------------------------------
// Breadcrumb
//-------------------------------------------------------------


// General

.breadcrumbs {
    margin: 24px 0 12px;
    &__list {
        list-style: none; 
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
    }

    &__item {
        @extend %text-small;
        
        &::after {
            @extend %teleicon;

            content: '\a7';
            font-size: building-units(1);
            padding: 0 building-units(1);
        }

        &--current {
            border-bottom: none;
            &::after {
                display: none;
            }
        }
    }

    &__link {
        color: $breadcrumb-current-link-color;
        &:hover {
            text-decoration: none;
        }

        margin: 0;
        color: $breadcrumb-link-color;
        text-decoration: none;
        font-weight: normal;
        &:hover {
            text-decoration: underline;
        }
    }
}

