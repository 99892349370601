//-------------------------------------------------------------
// Sidebar
//-------------------------------------------------------------

// General
%margin-default {
  margin-left: building-units(1);
  margin-right: building-units(1);
}

%border-bottom {
  border-bottom: 1px solid $gray-220;
}

.sidebar {
  width: 0;
  height: 0;
  display: inline-block;
  margin: 0;
  flex: 0 0;

  &__header {
    flex-shrink: 0;
    display: none;
    position: relative;
    padding: 0 building-units(1);
  }

  &__title {
    display: block;
    font-weight: $font-weight-ultra;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    color: $sidebar-title-text-color;
    text-align: center;
    padding-top: building-units(2);
    padding-bottom: building-units(1);
    border-bottom: 1px solid $gray-220;
  }

  &__shrink {
    font-size: 18px;
    width: building-units(1.5);
    height: building-units(1.5);
    position: absolute;
    right: building-units(2);
    top: building-units(1);
    text-decoration: none;
    border: none;
    background-color: transparent;
    outline: 0;

    &:hover,
    &:active,
    &:focus {
      color: $sidebar-collapse-button-hover-color;
      border: none;
      background-color: transparent;
      outline: 0;
      box-shadow: none;
    }
  }

  &__expand {
    display: none;
    position: fixed;
    bottom: building-units(1);
    z-index: 12;
  }

  &__footer-icon {
    margin-left: building-units(1);
    text-decoration: none;
    margin-top: 6px;
  }

  &__language {
    font-size: 12px;
    line-height: 14px;
    margin-left: auto;
    align-self: center;
  }

  &__content {
    display: none;
    flex-grow: 1;
    overflow-y: auto;
    padding: 0 building-units(1);
  }

  &__overview-link {
    text-transform: uppercase;
  }

  &__footer {
    display: none;
    flex-shrink: 0;
    border-top: 1px solid $gray-220;
    padding: building-units(1) 0;
    position: relative;
  }

  &__footer-icon {
    color: $sidebar-footer-icon-color;
  }

  &--relative {
    position: relative;
  }

  &.is-open {
    box-shadow: 2px 0 4px 0 rgba($black, 0.1);
    max-height: 100vh;
    width: 300px;
    max-width: 300px;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: building-units(2);

    > .sidebar__header,
    > .sidebar__content,
    > .sidebar__footer {
      display: inline-block;
    }

    .sidebar__footer {
      display: flex;
    }

    .sidebar__shrink {
      display: block;
    }

    .sidebar__expand {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    &.is-open {
      left: -12px;
      z-index: 6;
      background-color: $sidebar-mobile-isopen-background-color;
      min-width: 100vw;
      height: calc(100vh - 162px);
      flex: 1 0 100vw;
    }
  }
}
