//-------------------------------------------------------------
// Teaser
//-------------------------------------------------------------
// Variables

.img_content-box {
    margin: 0;

    //image wrapper to use a zoom effect
    &__image {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        border: 1px solid transparent;
        border-radius: 0;
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

        &--outline {
            border: 1px solid $gray-medium-1;
            border-radius: 0;

            &:hover {
                border: 1px solid $purple;
            }
        }

        &:hover .link {
            color: $purple;
            background-color: $white;
        }
    }

    &--linked {
        position: relative;
        width: 100%;

        .img_content-box__img:hover {
            transition: transform .4s ease-in, .4s ease-out;
            transform: scale(1.04);
        }

        .vacomicon {
          font-weight: 700;

          &:before {
            content: "\DC"
          }
        }

        .img_content-box__image--outline {
          &:hover {
            border-color: $purple;
          }
        }
    }

    &--linked[target="_blank"] {
        .vacomicon:before {
            content: "\6C"
        }
    }

    &__img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    &__caption {
        width: 100%;
        margin: building-units(1) 0 0 0;
        color: $black;
        font-size: $font-size-text-small;
        line-height: $line-height-text-small;
    }

    &--linked+.img_content-box__caption {
      padding-right: building-units(7);
      margin-top: building-units(0.5);

    }

    .link {
        position: absolute;
        right: building-units(0.5);
        bottom: building-units(-2.5);
        width: building-units(2);
        height: building-units(2);
        background-color: $white;
        border-bottom-right-radius: 0;

        .vacomicon {
            color: $purple;
            margin-left: 5px;
            margin-top: 6px;
        }
    }
}

.col-md-3 {
    .img_content-box {
        margin-bottom: 15px;
    }
}
