//-------------------------------------------------------------
// Lists
//-------------------------------------------------------------

ul {
    padding-left: rem(20px);
    list-style-type: inherit;
    margin-bottom: 0;
}

ul:not(.linklist) li > a.link {
    display: inline-block !important;
}

.list--icon-confirm {
    list-style-type: none;
    padding-left: 0;

    > li:before {
        @extend %vacomicon;
        content: '\e906';
        font-size: rem(18px) !important;
        width: rem(20px);
        left: -3px;
        margin-left: -20px;
    }

    > li {
        padding-left: 25px;
    }
}
