//-------------------------------------------------------------
// Dropdown
//-------------------------------------------------------------

// Dropdown stylings
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;

  &__toggle {
    color: $optionlist-color;
    vertical-align: middle;
    display: inline-block;
    padding: rem(6px) rem(28px) rem(8px) rem(11px);
    background-color: $button-secondary-background-color;
    border: 1px solid $button-secondary-border-color;
    border-radius: rem(4px);
    white-space: wrap;
    line-height: $line-height-base;
    width: 100%;

    @include reset-hover();

    &--no-arrow {
      &::after {
        display: none;
      }
    }

    &:hover,
    &:focus,
    &:focus-within {
      background-color: $optionlist-select-focus-background-color;
    }

    &::after {
      @extend %teleicon;
      @extend .icon-navigation-down::before;

      font-size: 16px;
      padding-left: 6px;
      position: absolute;
      top: 50%;
      right: building-units(0.75);
      transform: translateY(-50%);
      height: 16px;
    }
  }

  &__content {
    display: none;
    position: absolute;
    top: calc(100% + #{building-units(1) + 1});
    left: 0;
    min-width: building-units(15);
    z-index: 1000;
    max-height: 400px;
    border: 1px solid $optionlist-border-color;
    border-radius: building-units(0.5);
    background: $optionlist-background;
    padding: building-units(1);
    overflow: hidden;
    overflow-y: auto;
    font-size: inherit;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background: $optionlist-background;
  }

  &__link {
    color: $optionlist-color;
    text-decoration: none;
    padding: building-units(1);
    display: block;

    @include reset-hover();

    &:hover,
    &:focus,
    &:focus-within {
      background: $optionlist-hover-background-color;
    }

    &--active {
      background: $optionlist-select-background-color;

      &:hover {
        background: $optionlist-select-hover-background-color;
      }
    }
  }

  &__item--inactive {
    .dropdown__link {
      color: $optionlist-item-dropdown-link-inactive-color;
      cursor: no-drop;
    }
  }

  &--open {
    .dropdown__toggle {
      background-color: $optionlist-item-dropdown-toggle-open-color;

      &::after {
        @extend .icon-navigation-up::before;
      }
    }

    .dropdown__content {
      display: block;
    }
  }

  &--open-left {
    .dropdown__content {
      left: auto;
      right: 0;
    }
  }

  &--open-center {
    .dropdown__content {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--open-top {
    .dropdown__content {
      top: auto;
      bottom: calc(100% + #{building-units(0.5)});
    }
  }

  &--plain {
    width: auto;
    .dropdown__toggle {
      background-color: transparent;
      border-color: transparent;
      padding-left: 0;
    }
  }

  &--cozy {
    .dropdown__toggle {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &--dark {
    .dropdown__toggle {
      color: $optionlist-dropdown-toggle-dark-color;
    }
  }

  &--inactive {
    .dropdown__toggle {
      color: $optionlist-dropdown-toggle-inactive-color;
      background-color: $optionlist-dropdown-toggle-inactive-background-color;
      border-color: $optionlist-dropdown-toggle-inactive-border-color;
      cursor: no-drop;
    }
  }

  &--alert {
    .dropdown__toggle {
      background-color: $optionlist-item-dropdown-alert-background-color;
      border-color: $optionlist-item-dropdown-alert-border-color;

      &:hover,
      &:focus,
      &:focus-within {
        background-color: $optionlist-item-dropdown-alert-focus-color;
      }
    }
  }
}
