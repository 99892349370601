//-------------------------------------------------------------
// Tabbar: content
//-------------------------------------------------------------

.tabbar-content {
    padding: 0 building-units(2);

    > .tab-pane {
        display: none;
        padding-top: 0;
        margin-top: building-units(2);
    }

    > .active {
        display: block;
    }
}

.fade {
    opacity:    0;
    transition: opacity .15s linear;

    &.in {
        opacity: 1;
    }
}
