//-------------------------------------------------------------
//blockquote
//-------------------------------------------------------------

// Variables

// General
.quote {

    padding: 15px;

    &__content {
        margin: 0px 0px 0 30px;
        position: relative;
        p {
            opacity: 1;
            font-size: 18px;
            line-height: 24px;
            margin-top:0;
        }

        &:before {
            position: absolute;
            content: '\201D';
            font-size: 64px;
            font-weight: bold;
            left: -36px;
            top: -15px;
            height: 29px;
            width: 32px; 
        }

        p:before {
            position: absolute;
            content: '\201C';
            font-size: 32px;
            font-weight: bold;
            margin-top: -10px;
            margin-left: -11px;
        }
    }

    &__source {
        font-size: 15px;
        text-align: right;
        display: block;
    }
    
    a {
        color: $blockquote-text-link-color;
        text-decoration: underline;

        .card--bg-default & {
            color: $blockquote-text-link-color;

            &:hover {
                color: $blockquote-text-link-hover-color;
            }
        }

        .card--bg-primary &,
        .card--bg-dark-grey & {
            color: $blockquote-card-text-color;

            &:hover {
                color: $blockquote-text-link-hover-color;
            }
            
        }
    }

    a:hover {
        color: $blockquote-text-link-hover-color;
    }

    .card__text & {
        margin-left: -14px;
        margin-right: -14px;
    }
}

