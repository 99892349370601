//-------------------------------------------------------------
// Footer Contact
//-------------------------------------------------------------

$page-footer-contact-margin-bottom: building-units(4);

.page-footer__info {

    .contact {
        &__list {
            @extend %reset-ul;

            &:first-of-type {
                margin-bottom: $page-footer-contact-margin-bottom;
            }
        }

        &__list-item {
            padding: 0;
            margin: 0;

            > .widget.block.block-category-link {
                margin: 0;

                > a {
                    @include page-footer-link();
                }
            }
        }

        &__list-link {
            @include page-footer-link();
        }
    }
}
