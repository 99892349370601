//-------------------------------------------------------------
// Tabbar: list
//-------------------------------------------------------------

$tabbar-list-margin: 0 building-units(2.5);
$tabbar-list-padding: building-units(1) building-units(1) 0;
$tabbar-list-height: auto;

.tabbar-list {
    display:         flex;
    height:          $tabbar-list-height;
    justify-content: center;
    list-style:      none;
    margin:          0;
    overflow:        hidden;
    padding:         0;
    transition:      all .2s ease;
    white-space:     nowrap;
    z-index:         1;
    position:        relative;

    .tabbar-container & {
        // scsslint-disable importantRule
        min-width: 100%;
    }

    li {
        display:             flex;
        flex-flow:           column-reverse;
        padding:             $tabbar-list-padding;
        color:               $tabbar-list-color;
        border-bottom:       4px solid $tabbar-list-border-color;

        > a {
          text-decoration:     none;
          outline:             0;
          color:               $tabbar-list-color;
        }

        &::before {
            font-weight: normal;
            left:        30px;
            line-height: $line-height-base;
            position:    absolute;
            width:       0;
        }

        &:last-child {
            padding-bottom: 0;
        }

        &:hover,
        &:focus,
        &:focus-within,
        &:active {
          text-decoration:     none;
          outline:             0;
          border-color:        $tabbar-list-hover-border-color;
          cursor:              pointer;

          > a {
            text-decoration:   none;
            color:             $tabbar-list-hover-color;
          }
        }

        &.active {
          > a {
            text-decoration:   none;
            outline:           0;
            color:             $tabbar-list-active-color;
          }
          border-color:        $tabbar-list-active-border-color;
        }

        &.inactive {
          > a {
            cursor:            no-drop;
            color:             $tabbar-list-inactive-color;
          }
        }
    }
}
