//-------------------------------------------------------------
// product-tile
//-------------------------------------------------------------
// Variables

/** can override the design if it shows list or grid view */
.product-tile-container {
    display: flex;
    flex-flow: row wrap;
    /*width: 100%;*/

    /* to center tiles in mobile viewport */
    @include media-breakpoint-up(sm) {
        margin-left: -15px;
        margin-right: -15px;
    }

    &--grid {
        @include media-breakpoint-only(xs) {
            padding-left: 0;
        }

        @include media-breakpoint-down(sm) {
            .btn-medium {
                padding: 0 building-units(2);
            }
        }

        .product-tile-wrapper {
            @include media-breakpoint-only(xs) {
                @include make-col(12, 12);
            }

            @include media-breakpoint-between(sm, xl) {
                @include make-col(4, 12);
            }
        }

        .product-tile {
            flex-direction: column;
            border-radius: 0;
            border-top-color: $gray-medium-1 !important;

            .product-tile__image,
            .product-tile__content,
            .product-tile__info {
                flex: 0 0 auto;
                max-width: 100%;
            }

            .product-tile__content-wrapper {
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                height: 100%;
            }

            .product-tile__content {
                margin-top: building-units(1);
                padding-bottom: 0;
            }

            .product-tile__image {
                overflow: hidden;
                min-width: 100%;

                figure.img_content-box {
                    height: auto;
                    width: 100%;
                    margin-bottom: 0;

                    a, img {
                        width: 100%;
                    }
                }

                picture.img_content-box__image {
                    height: auto;
                    width: 100%;
                }

                img.img_content-box__img {
                    height: auto;
                    max-width: 100%;
                    /*min-height: 205px;*/
                    width: 100%;
                    border-radius: 0;
                    position: inherit;
                    top: 0;
                    right: inherit;
                    left: 0;
                }

                @include media-breakpoint-down(md) {
                    display: block;
                    width: 100%;
                }
            }

            .product-tile__manufacturer-logo {
                flex-flow: row;
                justify-content: flex-start;
                align-items: flex-end;
                padding-bottom: building-units(1);
            }

            .product-tile__info {
                margin-top: auto;
                flex-direction: column;
                background-color: transparent;
                padding: 0 building-units(2) building-units(2) building-units(2);

                .spinner-wrapper {
                    display: none;
                }

                .add-to-cart {
                    order: 2;
                    margin-left: building-units(1);
                    width: 100%
                }
            }

            .product-tile__location {
                margin-bottom: 0;
            }

            .product-tile__button {
                margin-top: building-units(1);
            }

            .product-tile__info-main ul {
                list-style-type: disc!important;
            }

            .product-tile__wishlist {
                padding-top: 5px;
                margin-bottom: 0;
            }

            .hidden-xs-up {
                display: flex !important;
            }

            @include media-breakpoint-only(xs) {
                .add-to-cart,
                .product-details-btn {
                    min-width: 50%
                }
            }
        }

        .product-tile--placeholder {
            max-width: 458px;
            width: 100%;
        }

        .wishlist-title {
            display: none;
        }

        .hidden-grid {
            display: none !important;
        }
    }

    &--list {
        .product-tile-wrapper {
            @include make-col(12, 12);
            padding-top: 0;
            padding-bottom: 0;

            &:last-child > .product-tile {
                border-bottom: 1px solid $gray-medium-1;
            }
        }

        .product-tile {
            border: none;
            border-top: 1px solid $gray-medium-1;
            border-radius: 0;
            justify-content: flex-start;
            align-items: flex-start;

            .product-tile__badge_wrapper {
                top: -13px;
                left: 1px;
            }

            .product-tile__image {
                border-bottom-width: 0;
                border-radius: 0;
                margin-top: building-units(1.5);
                margin-right: building-units(1.5);
                min-width: building-units(10);
                max-width: building-units(10);
                height: 100%;

                .img_content-box:hover {
                    border: none !important;
                }

                @include media-breakpoint-only(xs) {
                    display: none;
                }
            }

            .product-tile__content-wrapper {
                display: flex;
                flex-flow: row;
                height: 100%;
                width: 100%;

                @include media-breakpoint-down(sm) {
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }

            @include media-breakpoint-only(xs) {
                .product-tile__headline {
                    margin-top: building-units(1);
                }
            }

            .product-tile__location {
                margin-bottom: building-units(1);
            }

            .product-tile__usp .col-8,
            .product-tile__usp .col-4 {
                @include pt-sans-regular-14();
            }

            .product-tile__content {
                padding-top: building-units(1.5);
                padding-left: 0;

                @include media-breakpoint-down(sm) {
                    padding-bottom: 0;
                }
            }

            .add-to-cart-small {
                display: none;
            }

            .product-tile__info {
                align-self: flex-start;
                max-width: 32%;
                width: 100%;
                height: 100%; /* NEW */

                .product-details-btn {
                    display: none;
                }

                .product-tile__button {
                    margin-bottom: building-units(2);
                }

                .add-to-cart {
                    margin-left: building-units(1);
                    margin-right: building-units(0);
                }

                .add-to-wishlist {
                    margin-left: building-units(1);
                    margin-right: building-units(1);
                    padding-left: building-units(1);
                    padding-right: building-units(1);
                }



                @include media-breakpoint-down(sm) {
                    max-width: 100%;
                    padding-bottom: building-units(2)
                }
            }

            .product-tile--placeholder {
                height: 400px;
                width: 100%;
            }

            .action.towishlist {
                margin-top: 12px;
            }
        }

        .hidden-list {
            display: none;
        }
    }
}

@include media-breakpoint-up(lg) {
    .col-lg-4,
    .col-md-4 {
        .product-tile-widget {
            .product-tile-wrapper {
                min-width: 100%;
            }
        }
    }
}

.product-tile {
    position: relative;
    background-color: $white;
    border: 1px solid $gray-medium-1;
    display: flex;
    flex-flow: row nowrap;
    border-radius: 0;
    @include pt-sans-regular-16();
    height: 100%;
    text-align: left;

    @include make-col(12, 12);

    &-widget {
        .product-tile-wrapper {
            min-width: 100%;
            @include media-breakpoint-up(md) {
                min-width: 33%;
            }
        }
    }

    &-container--grid {
        display: flex;
        flex-flow: row wrap;
    }

    &-wrapper {
        padding: building-units(1.5);
        width: 100%;
    }

    &__badge {
        &_wrapper {
            position: absolute;
            z-index: 1;
            top: building-units(-1);
            left: building-units(1);
        }

        @extend %text-small;
        line-height: 25px;
        margin-right: -3px;
        display: inline-block;
        padding: 0 building-units(1);
    }

    &__image {
        min-width: 100%;
        max-height: 100%;
        position: relative;
        overflow: hidden;
        border: none;

        a {
            .img_content-box__image {
                .img_content-box__img {
                    border: 0;
                    &:hover {
                        border: 0;
                    }
                }
            }
        }

        > a {
            display: block;
            height: 100%;
        }

        figure.img_content-box {
            width: 100%;
            height: 100%;
            border-radius: 0;
            display: flex;
            align-content: flex-start;
            justify-content: flex-start;
            align-items: flex-start;

            span.link {
                display: none;
            }
        }

        picture.img_content-box__image {
            width: auto;
            border-radius: 0;
            position: relative;
            display: inline-block;
        }

        img.img_content-box__img {
            max-width: inherit;
            border-radius: 0;
        }
    }

    &__content {
        display: flex;
        flex: 1 1 auto !important;
        flex-direction: column;
        padding: 0 building-units(2) building-units(2) building-units(2);

        @include pt-sans-regular-14();

        ul {
            @include pt-sans-regular-14();
        }

        ul > li {
            margin-bottom: 0;
        }
    }

    &__headline {
        @include pt-serif-regular-20();

        a {
            color: $purple;
            @include pt-serif-regular-20();

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__info {
        align-self: flex-end;
        background-color: $white;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: building-units(1);

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        .add-to-cart-small {
            display: inline-block;
            font-size: building-units(3);
        }
    }

    &__info-label,
    &__info-value {
        margin-bottom: 0;
        text-transform: none;
        line-height: building-units(1);
    }

    &__wishlist {
        position: absolute;
        top: building-units(1);
        right: building-units(2);

        > a {
            color: $purple;

            &:hover {
                color: $purple;
            }
        }

        .vacomicon {
            font-size: 18px;
        }
    }

    &__location {
        @include pt-sans-regular-14();
        color: $gray-medium-2;
        margin-bottom: building-units(1.5);
    }

    &__price {
        padding-bottom: building-units(1);
        margin-top: building-units(1.5);

        .price {
            color: $black;

            &__tax {
                @include pt-sans-regular-14();
            }
        }
    }

    &__button {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        width: 100%;

        > form {
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }

        .add-to-cart {
            padding: 0 20px;

            > .icon-cart {
                margin-right: 0;
                width: auto;
            }
        }

        .add-to-wishlist {
            margin-left: building-units(1.5);
        }

        .cart {
            margin-left: building-units(1);
        }
    }
}

.product-tile-wrapper.small {
    .product-tile__location {
        display: none;
    }

    .product-tile__content {
        padding: 0 building-units(2) building-units(2) building-units(2);
    }

    .product-tile__info {
        .product-tile__info-main,
        .product-tile__button {
            display: none;
        }

        .product-tile__price {
            margin-bottom: 0;
            padding-bottom: 0;

            .product-tile__price_availability {
                margin: building-units(1.5) 0 0 0;
            }
        }
    }
}

.swiper-slide__content .product-tile-container {
    margin-left: 0;
    margin-right: 0;
    min-width: 100%;
}

.subcategory-listing .teaser_img {
    animation: none;
    animation-timing-function: inherit;
    animation-fill-mode: none;
}

.subcategory-listing .teaser_img:hover {
    animation: none;
    animation-timing-function: inherit;
    animation-fill-mode: none;
}
