//-------------------------------------------------------------
// Search Suggestion Dimmer
//-------------------------------------------------------------

.dimmer {

  position: fixed;
  z-index: 150;
  top: 0;
  left: 0;
  right: 0;

  height: 100vh;
  width: 100vw;

  &:after {

    position: absolute;
    top: 0;
    left: 0;
    z-index: 40;

    width: 100%;
    height: 100%;

    background: rgba(0,0,0,.5);
    content: '';
    opacity: 1;

    transition: opacity 0.5s;
  }
}
