//-------------------------------------------------------------
// Tabbar: link
//-------------------------------------------------------------

// Variables
$tabbar-link-font-size: $font-size-text-small;
$tabbar-link-line-height: $line-height-text-small;

.tabbar-link {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    text-decoration: none;
    padding: 0 building-units(1);

    span {
        width: 100%;
        height: auto;
    }
}

span.tabbar-link-text {
    font-size: $tabbar-link-font-size;
    font-weight: $font-weight-bold;
    line-height: $tabbar-link-line-height;
    padding: 11px building-units(1);
    text-transform: uppercase;
    letter-spacing: 1px;
}


span.tabbar-link-subline {
    font-size: $tabbar-link-font-size;
    line-height: $tabbar-link-line-height;
    margin-bottom: 10px;
}

@include media-breakpoint-down(md) {
    span.tabbar-link-icon {
        width: 24px;
        height: 24px;
        margin: 0 6px;

        //huge icons in tabbar
        &.icon-50 {
            width: 50px;
            height: 50px;
        }
    }
}