//-------------------------------------------------------------
// Lists
//-------------------------------------------------------------

ol,
ul,
dl {
    @include pt-sans-regular-18();

    &.icon {
        padding-left: 0;

        li {
            list-style: none;
            padding-left: 0;

            &:before {
                @extend %vacomicon;
                color: $purple;
                margin-right: building-units(1)
            }
        }
    }

    &.icon-arrow-right {
        list-style-type: none;
        padding-left: building-units(2.4);

        li:before {
            @extend %vacomicon;
            content: "\e923";

            font-size: rem(11px);
            left: building-units(-0.5);
            margin-left: -25px;
        }
    }

    li {
        margin-bottom: building-units(1);
        padding-left: building-units(0.5);

        ol, ul, dl {
            margin-top: building-units(0.5);
            padding-left: building-units(2);
        }

        &.icon-confirm {
            list-style-type: none;
            padding-left: building-units(0.5);

            &:before {
                @extend %vacomicon;
                content: '\e906';
                color: $purple;

                font-size: rem(14px);
                width: rem(20px);
                left: building-units(-0.5);
                margin-left: -20px;
            }
        }
    }
}


.list {
    &__download > li {
        list-style-type: none;
        position: relative;

        &:before {
            @extend %vacomicon;
            content: '\e90a';
            font-size: rem(18px) !important;
            position: absolute !important;
            left: rem(-20px);
            top: 0;
            width: rem(20px);
            text-align: center;
        }
    }

    &--small {
        li, dt, dd {
            @include pt-sans-regular-14();
            margin-bottom: 0;
        }
    }
}

.list-square-l > li {
    margin-bottom: building-units(0.5);

    span {
        font-size: rem(building-units(2)-2);
        line-height: rem(building-units(2)-2);
    }
}

dt {
    display: list-item;
    list-style-type: square;
    font-weight: $font-weight-bold;
}

.icon {
    dt {
        display: inline;
        list-style: none;

        &:before {
            @extend %vacomicon;
            color: $purple;
            padding-right: building-units(0.5);
        }
    }
}

dd {
    margin: 0 0 rem(12px);
}

//-------------------------------------------------------------
// global stylings
//-------------------------------------------------------------
.list--style {
    &-none {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;

        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            padding-left: 0 !important;
        }

        li:before {
            content: '' !important;
            margin-right: 0;
        }
    }

    &-disc {
        list-style-type: disc !important;
    }

    &-circle {
        list-style-type: circle !important;
    }
}
