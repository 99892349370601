//-------------------------------------------------------------
// Radiobutton
//-------------------------------------------------------------

// Variables
$radiobutton-icon-size: building-units(2);
$radiobutton-br-radius: 100%;
$radiobutton-icon-size: building-units(2);
$radiobutton-shadow: $form-elements-box-shadow;

$radiobutton-bg-color: $white;
$radiobutton-br-color: $gray-medium-2;
$radiobutton-bg-color-hover: $white;
$radiobutton-br-color-hover: $black;
$radiobutton-bg-color-active: $gray-199;
$radiobutton-br-color-focus: $purple;
$radiobutton-shadow-focus: none;
$radiobutton-icon-color: $black;
$radiobutton-color-hover: $black;

$radiobutton-bg-color-disabled: opacify($gray-medium-2, 0.1);
$radiobutton-br-color-disabled: $gray-medium-2;
$radiobutton-icon-color-disabled: $gray-medium-2;

$radiobutton-bg-color-error: rgba($red, .05);
$radiobutton-br-color-error: $red;
$radiobutton-bg-color-error-hover: $red;
$radiobutton-br-color-error-focus: $red;
$radiobutton-shadow-error-focus: $radiobutton-shadow $red;
$radiobutton-icon-color-error: $red;

// General styles
.form-radiobutton-set {
    margin-bottom:  building-units(1);
    min-height:     auto;
    position:       relative;
    line-height:    building-units(2.5);

    &.has-error .validation-message {
        padding-left: building-units(1);
    }

    &:last-child {
        margin-bottom: 0;
    }
}
.form-radiobutton-group {
    margin: building-units(1) 0;

    &.has-error .validation-message {
        font-weight: $font-weight-bold;
        margin-bottom: building-units(1);
    }
}

.form-radiobutton {
    position: absolute;
    top: inherit;
    left:     0;
    z-index:  1;
    opacity:  0;

    + label {
        font-size:      $font-size-default !important;
        line-height:    building-units(2) !important;
        font-weight:    normal;
        display:        inline-block;
        margin-bottom:  0; //override default label
        text-transform: none;

        &::before {
            //@extend %cadfemicon;

            position:      absolute;
            top:           0;
            left:          0;
            height:        $radiobutton-icon-size - 1px;
            width:         $radiobutton-icon-size - 1px;
            font-size:     $radiobutton-icon-size !important;
            color:         $radiobutton-icon-color;
            margin-right:  building-units(1);
            background:    none;
            text-align:    center;
            transition:    opacity .2s ease-in-out;
        }

        &::after {
          height:        $radiobutton-icon-size - 1px;
          width:         $radiobutton-icon-size - 1px;
          line-height:   $radiobutton-icon-size - 1px;
          font-size:     $radiobutton-icon-size - 5px;
          color: $purple;
          background:    none;
        }


        &:hover {
            cursor: pointer;
            color:  $radiobutton-color-hover;
            border-color: $radiobutton-br-color-hover;
            background-color: $white;
        }
    }

    &:focus {
      +label {
          &::before,
          &::after {
            box-shadow: none !important;
            border-color: $black !important;
          }
      }
    }

    &:checked {
        + label {
            &::before {
                color:          $radiobutton-color-hover;
                border-color:   $radiobutton-color-hover;
                content:        "\3b";
            }
        }
    }

    &:hover {
      cursor: pointer;

      &:not(:disabled) {
        + label {
          &::before,
          &::after{
            color:              $radiobutton-br-color-focus;
            background-color:   $radiobutton-bg-color-hover;
            border-color:       $radiobutton-br-color-hover;
          }
        }
      }
    }

    &:active {
        outline: 0;
        + label {
            &::before,
            &::after {
              background-color:   $radiobutton-bg-color-hover;
              border-color:       $radiobutton-br-color;
            }
        }
    }

    // Disabled
    &:disabled {
        cursor: not-allowed;

        + label {
            cursor: not-allowed;
            color:        $radiobutton-icon-color-disabled;

            &::before,
            &::after {
                border-color: $radiobutton-br-color-disabled;
                color:        $radiobutton-icon-color-disabled;
            }
        }
    }

    // Error
    .has-error & {
        + label {
            color: $radiobutton-icon-color-error;
            &::before {
                background-color:   $radiobutton-bg-color-error;
                color:              $radiobutton-icon-color-error;
            }
        }

        &:hover {
            + label {
                &::before {
                    background-color: $radiobutton-bg-color-error;
                }
            }
        }
    }
}
