//-------------------------------------------------------------
// Footer
//-------------------------------------------------------------

// Variables
$page-footer-bg-color: #252525;
$page-footer-link-color: $gray-medium-1;
$page-footer-link-color-hover: $white;
$page-footer-divider-color: rgba($gray-medium-2, .2);

// mobile
$page-footer-padding-top: building-units(2);
$page-footer-padding-bottom: building-units(2);
$page-footer-padding-left-right: building-units(1.5);
$page-footer-gap-sections: building-units(2);
$page-footer-container-padding-bottom: building-units(2);
$page-footer-container-padding-top: building-units(2);

// desktop
$page-footer-padding-top-lg: building-units(4);
$page-footer-padding-bottom-lg: building-units(2.5);
$page-footer-padding-left-right-lg: building-units(2.5);
$page-footer-gap-sections-lg: building-units(3);
$page-footer-container-padding-bottom-lg: building-units(4);


// Mixins
@mixin page-footer-headline() {
    @include pt-sans-bold-14();
    text-transform: uppercase;
    color: $white;

    padding-bottom: building-units(1);
}

@mixin page-footer-divider() {
    border-bottom: 1px solid $page-footer-divider-color;
    margin-bottom: building-units(1);
}

@mixin page-footer-link() {
    @include pt-sans-regular-14;
    color: $page-footer-link-color;
    margin-bottom: building-units(1);

    &:focus-within,
    &:focus {
        color: $page-footer-link-color-hover;
    }

    //separate because of IE
    &:hover {
        color: $page-footer-link-color-hover;
        text-decoration: underline;
    }
}

.page-footer {
    // keep always on bottom
    margin-top: auto;
    background-color: $page-footer-bg-color;
    padding: $page-footer-padding-top $page-footer-padding-left-right $page-footer-padding-bottom $page-footer-padding-left-right;

    @include media-breakpoint-up(md) {
        padding: $page-footer-padding-top-lg $page-footer-padding-left-right-lg $page-footer-padding-bottom-lg $page-footer-padding-left-right-lg;
    }

    &__heading {
        @include page-footer-headline();
        @include page-footer-divider();
    }

    &__image-link {
        width: 100%;
        text-align: center;
        display: inline-block;
    }

    &__image {
        height: auto;

        @include media-breakpoint-up(md) {
            width: 232px;
        }

        @include media-breakpoint-up(lg) {
            width: 332px;
        }

        @include media-breakpoint-up(xl) {
            width: 432px;
        }
    }

    &-container-top,
    &-container-copyright {
        display: flex;
        flex-wrap: wrap;
    }

    &-container-top {
        position: relative;
        border-bottom: 1px solid $page-footer-divider-color;
        padding-bottom: $page-footer-container-padding-bottom;

        @include media-breakpoint-up(md) {
            padding-bottom: $page-footer-container-padding-bottom-lg;
        }
    }

    &-container-bottom {
        padding-top: $page-footer-container-padding-top;
    }

    &__location {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &__location,
    &__info {
        flex: 1 1 calc(50% - #{$page-footer-gap-sections});
        margin-right: $page-footer-gap-sections;

        @include media-breakpoint-up(md) {
            flex: 1 1 calc(33% - #{$page-footer-gap-sections-lg});
            margin-right: $page-footer-gap-sections-lg;
        }
    }

    &__social {
        flex: 1 1 50%;

        @include media-breakpoint-up(md) {
            flex: 1 1 33%;
        }
    }

    &__payments {
        margin-top: building-units(4);

        > div {
            display: flex;
            flex-flow: row;
        }

        .page-footer__payments--logo {
            background-color: $white;
            height: building-units(4);
            width: building-units(6);
            margin-right: building-units(2);

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__copyright,
    &__copyright-links {
        flex: 1 1 100%;

        @include media-breakpoint-up(sm) {
            flex: 1 1 50%;
        }
    }
}

@import "footer/footer_scroll-top-vc";
@import "footer/footer_info-vc";
@import "footer/footer_social-vc";
@import "footer/footer_copyright-vc";
