//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables
@keyframes slidein {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}

.typoclou {
    display: inline-flex;
    flex-flow: column;
    justify-items: center;
    align-items: center;
    text-align: center;
    width: 100%;

    &__number-wrapper {
        height: building-units(6);
        width: 100%;
        position: relative;
    }

    &__number {
        @include serif-regular-34();

        line-height: 34px !important;
        color: $gray-dark;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 2;
    }

    &__title {
        @include sans-regular-18();
        font-size: 20px !important;
        font-weight: bold !important;
        color: $black !important;

        text-transform: none;
        margin: building-units(0.5) 0 building-units(0.5) 0;
        width: 100%;
        max-width: 348px;

        a {
            color: $purple;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }


    &__subline {
        font-size: 15px;
        font-weight: $font-weight-base;
        color: $black;
        width: 100%;
        max-width: 348px;
    }

    &__uppericon {
        .vacomicon {
            &::before {
                color: $purple;
                font-size: building-units(4);
            }
        }
    }

    &__lowericon {
        .vacomicon {
            &::before {
                color: $purple;
                font-size: building-units(2.5);
            }
        }
    }

    &__line {
        display: none;
    }

  &-square {
    .typoclou-btn {
      padding: 0 !important;
      overflow: hidden;

      .typoclou__uppericon .vacomicon::before {
        font-size: 2em !important;

        @include media-breakpoint-up(sm) {
          font-size: 3em !important;
        }
      }
    }

    .typoclou__uppericon {
      padding: 20px 20px 0 20px !important;
    }

    .typoclou__title {
      padding: 7px 10px 0 10px !important;

      @include media-breakpoint-up(sm) {
        padding-top: 20px !important;
      }
    }
  }
}

a:hover > .typoclou .typoclou__title {
    text-decoration: underline;
}

/**
*  TYPOCLOU BUTTONS
*
*  typoclou typoclou-btn typoclou-btn--primary
*  typoclou typoclou-btn typoclou-btn--secondary
*
*/

.typoclou-btn {
  width: 230px;
  height:230px;
  padding: 45px;
  border: 1px solid $white;

  .typoclou__number-wrapper {
    height: building-units(11);
  }

  .typoclou__title {
    @include sans-regular-18();
    font-size: 30px !important;
    font-weight: normal !important;
  }

  .typoclou__uppericon {
    .vacomicon {
      width: building-units(9);
      &::before {
        font-size: building-units(9);
      }
    }
  }

  @include media-breakpoint-up(xs) {
    width: 100px;
    height:100px;
    padding: 24px;
    border: 1px solid $white;

    .typoclou__number-wrapper {
      height: building-units(4);
    }

    .typoclou__title {
      @include sans-regular-18();
      font-size: 16px !important;
      font-weight: normal !important;
    }

    .typoclou__uppericon {
      .vacomicon {
        width: building-units(4);
        &::before {
          font-size: building-units(4);
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    width: 165px;
    height:165px;
    padding: 30px;
    border: 1px solid $white;

    .typoclou__number-wrapper {
      height: building-units(7);
    }

    .typoclou__title {
      @include sans-regular-18();
      font-size: 23px !important;
      font-weight: normal !important;
    }

    .typoclou__uppericon {
      .vacomicon {
        width: building-units(6.5);
        &::before {
          font-size: building-units(6.5);
        }
      }
    }
  }

  &--primary {
    background-color: $purple;
    color: $white;

    .typoclou__title {
      color: $white !important;
    }

    .typoclou__uppericon {
      .vacomicon {
        &::before {
          color: $white;
        }
      }
    }
  }

  &--primary:hover {
    border: 1px solid $purple;
  }

  &--secondary {
    background-color: $gray-light;
    color: $purple;

    .typoclou__title {
      color: $purple !important;
    }

    .typoclou__uppericon {
      .vacomicon {
        &::before {
          color: $purple !important;
        }
      }
    }
  }

  &--secondary:hover {
    border: 1px solid $purple;
  }
}

a:hover > .typoclou-btn--primary .typoclou__title {
  text-decoration: none !important;
}

a:hover > .typoclou-btn--secondary .typoclou__title {
  text-decoration: none !important;
}

// square clous

//row container
div.row:has(>div > div.frame-type-mmsvacomccintegration_typoclou > a > div.typoclou.typoclou-square) {
  margin-bottom: 15px;

  > div {
    margin-bottom: 0;
    display: grid;
    width: 100%;
    padding: 7.5px;

    @include media-breakpoint-up(md) {
      padding: 15px;
    }


    .frame-type-mmsvacomccintegration_typoclou {
      width: 100%;

      a {
        width: 100%;
      }
    }

    .typoclou.typoclou-square {
      width: 100%;
    }

    .typoclou-btn {
      aspect-ratio: 1 / 1;
      padding: 15px;
      width: 100%;
      height: auto;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
