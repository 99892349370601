//-------------------------------------------------------------
// Button sizes
//-------------------------------------------------------------

.btn--small {
  height: building-units(3);
  max-height: building-units(3);
  padding: 0 building-units(2.5);
  font-size: 14px;
  border-radius: 3px;

  >.vacomicon {
    font-size: rem(15px);
    width: rem(15px);
    height: rem(15px);
    margin-right: rem(10px);
  }

  &.btn--round {
    >.vacomicon {
      margin: 0;
      position: relative;
      left: 1px;
    }
  }
}

.btn--medium {
  height: building-units(5);
  max-height: building-units(5);
  padding: $button-padding;

  @include media-breakpoint-up(md) {

    padding: $button-padding-md;
  }

  >.vacomicon {
    font-size: rem(20px);
    width: rem(20px);
    height: rem(20px);
    margin-right: rem(10px);
  }

  &.btn--round {
    >.vacomicon {
      margin: 0;
      position: relative;
      left: 1px;
    }
  }
}

.btn--large {
  height: 50px;
  max-height: 50px;
  line-height: 20px;
  padding: $button-padding;

  @include media-breakpoint-up(md) {

    padding: $button-padding-md;
  }

  >.vacomicon {
    font-size: rem(20px);
    width: rem(20px);
    height: rem(20px);
    margin-right: rem(10px);
  }

  &.btn--round {
    >.vacomicon {
      margin: 0;
      position: relative;
      left: 1px;
    }
  }
}
