.font-weight-bold,
.bold {
    font-weight: $font-weight-bold !important;
}

.font-weight-normal {
    font-weight: $font-weight-base !important;
}

.text-transform-none {
    text-transform: none !important;
}

.text-transform-uppercase {
    text-transform: uppercase !important;
}

%overflow-ellipsis {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflow-ellipsis {
    @extend %overflow-ellipsis;
}

.text-align-center,
.ta-center {
    text-align: center !important;
}

.text-align-left,
.ta-left {
    text-align: left !important;
}

.text-align-right,
.ta-right {
    text-align: right !important;
}

.serif {
    font-family: $vacom-font-family-serif;
}

.underline {
    text-decoration: underline !important;
}

.line-height-normal {
    line-height: 1;
}
