body.checkout-index-index {

    @include media-breakpoint-between(sm, md) {
        .opc-wrapper {
            width: 60.666666%;
        }

        .opc-sidebar {
            width: 38.3333333%;
        }
    }

    .no-quotes-block {
        @extend %text-default;
    }
}


body.catalogsearch-result-index,
body.checkout-onepage-success {
    > .container {
        margin-top: 50px;
    }
}

.payment-method-content .actions-toolbar {
    display: none !important;
}

.payment-method > .payment-method-content > .payment-method-billing-address > :not(.infobox ) {
    display: none;
}