//-------------------------------------------------------------
// Input Spinner
//-------------------------------------------------------------
// Variables

$inp-spinner-button-width-height: building-units(5);
$inp-spinner-input-max-width: building-units(5);

.inp-spinner {
    font-size: 0;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
    }

    &__input {
        font-size: 16px;
        height: $inp-spinner-button-width-height;
        line-height: building-units(4.5);
        width: calc( 100% - ( 2 * (#{$inp-spinner-button-width-height})) );
        text-align: center;
        border: 1px solid $purple;
        caret-color: $purple;
        min-width: building-units(4);
        max-width: $inp-spinner-input-max-width;

        &:focus,
        &:focus-within,
        &:active {
            outline: none;
            border: 1px solid $purple;

        }

        &:hover {
            border: 1px solid $black;
            color: $black;
        }
    }

    &__button {
        cursor: pointer;
        display: inline-block;
        user-select: none;
        font-size: 16px;
        font-weight: bold;
        line-height: $inp-spinner-button-width-height;
        text-align: center;
        width: $inp-spinner-button-width-height;
        height: $inp-spinner-button-width-height;
        color: $purple;
        background: $white;
        vertical-align: top;

        &:hover {
            background: $purple;
            color: $white;
        }
    }

    &__increase {
      border-top: 1px solid $purple;
      border-right: 1px solid $purple;
      border-bottom: 1px solid $purple;
      border-radius: 0 building-units(0.5) building-units(0.5) 0;
    }

    &__decrease {
      border-top: 1px solid $purple;
      border-left: 1px solid $purple;
      border-bottom: 1px solid $purple;
      border-radius: building-units(0.5) 0 0 building-units(0.5);
    }

    &__increase,
    &__decrease {
      font-size: 28px;
      font-weight: normal;
    }

    &--selector {
        width: 100%;
        height: 40px;
        font-size: 14px;
        background-color: white;
        border: none;

        &:hover {
            background-color: $white;
        }

    }

    &__button--disabled {
        color: $gray-medium-2;
        border-color: $gray-light;
        background: $gray-light;

        &:hover {
            cursor: not-allowed;
            color: $gray-medium-2;
            background: $gray-light;
        }
    }

    &__quantity {
        &-container {
            height: 100%;
            font-size: 14px;
            position: absolute;
            display: none;

            &--active {
                display: block;
            }

        }

        &-header {
            font-size: 14px;
            min-height: 40px;
            background-color: $purple;
            padding: 10px;
        }

        &-content {
            height: 250px;
            padding: 5px;
            overflow: auto;
            background-color: white;
        }

        &-footer {
            min-height: 30px;
            padding: 5px;
            background-color: white;
        }

        &-inner-container {
            top: 20%;
            right: 0;
            left: 0;
            z-index: 35;
            position: fixed;
            width: 350px;
            display: flex;
            margin: 25px auto;
            flex-flow: column;
            transform: scale(0);
            animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }

        &-backdrop {
            position: fixed;
            top: 0;
            left: 0;
            overflow-y: auto;
            background-color: rgba(#000000, 0.5);
            width: 101%;
            height: 100%;
            animation: zoomIn;
            z-index: 30;
        }
    }
}

.requestDeliveryDate__spinner-dd {

  .inp-spinner__button {
    width: 60px;

    @include media-breakpoint-down(sm) {
      width: 50px;
    }

  }

  .inp-spinner__input {
    max-width: 60px;

    @include media-breakpoint-down(sm) {
      max-width: 50px;
    }
  }
}
