//-------------------------------------------------------------
// Teaser
//-------------------------------------------------------------
// Variables

$teaser-magenta-background-color: $purple;
$teaser-magenta-hover-background-color: $purple;
$teaser-grey-background-color: $gray-medium-1;
$teaser-grey-hover-background-color: $black;

/*****************************************
 * teaser
 *****************************************/
.teaser {
    position: relative;
    border-radius: 0;
    overflow: hidden;
    margin-bottom: building-units(3);
    display: flex;
    flex-flow: column;
    justify-content: stretch;
    flex: 1 1 auto;

    &-container--overlay {
        .teaser {
            margin-bottom: 0;
        }

        .row {
            margin-right: 0;
            margin-left: 0;
        }

        div[class*="col-"] {
            padding: 0;
            margin-bottom: -6px;

            > a {
                width: 100%;
                height: 100%;
            }
        }

        @include media-breakpoint-up(sm) {

            div[class*="col-"] {
                border-right: 1px solid white;
            }
        }
    }

    &__date {
        @include pt-sans-regular-14();
        margin: 0 0 building-units(0.5) 0;
        display: block;
    }

    &__header {
        @include serif-regular-26();
        margin: 0 0 building-units(1) 0;
        display: block;
        color: $purple;
        overflow-wrap: break-word;

        &:hover {
            color: $purple;
        }
    }

    &__image {
        overflow: hidden;
        margin-bottom: building-units(1);
    }

    &__img {
        display: block;
        width: 100%;
        height: auto;
    }

    &__content {
        display: flex;
        flex-flow: column;
        width: 100%;
        padding: building-units(2);

        @include media-breakpoint-up(md) {
            padding: building-units(3);
        }
    }

    &__link-wrapper {
        flex-grow: 1; //align bottom
    }


    &__text {
        @include sans-regular-18();
        color: $black;
        margin: 0;
    }

    &__link-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        text-decoration: none;
    }

    &__link-label {
        @include sans-regular-18();
        color: $black;
        margin: 0;
        padding-top: 0;
    }

    .link__icon {
        font-size: 15px;
        color: $purple;
    }

    &--bg-white {
        background-color: $white;
    }

    &--bg-grey {
        background-color: rgba($gray-medium-2, .1);
    }

    &--shadow {
        box-shadow: $gray-medium-1;
    }

    &--outline {
        border: 1px solid $gray-medium-1;
    }

    &__company {
        &-logo {
            max-width: 210px;
            max-height: 80px;
            height: auto;
            margin-bottom: 15px;
        }

        &-name {
            @extend %text-small;
            margin-bottom: 5px;
            color: $purple;
        }
    }

    &:hover {

        .teaser__date {
            color: $black;
        }

        .teaser__link-label {
            color: $purple;
            text-decoration: underline;
        }

        .btn--outline:not(.btn--theme-dark) {
            color: rgba($purple, .8);
            border: 1px solid rgba($purple, .8);
        }
    }

    &--list-mode {
        @include media-breakpoint-up(sm) {
            .teaser__news {
                border: none;
                flex-flow: row;

                .teaser__image {
                    @include make-col(3, 12);
                    margin-bottom: 0;
                    margin-right: building-units(1);
                }

                .teaser__header {
                    font-size: 30px;
                }

                .teaser__content {
                    display: inline-block;
                    overflow: initial;
                    padding-bottom: 0;

                    .teaser__header {
                        margin-bottom: building-units(1);
                    }
                }
            }
        }
    }
}

.teaser div.link {
    color: $purple;
    text-decoration: underline;
}

.teaser--flex > div,
.teaser--flex > div > a {
  display: flex;
}

/*****************************************
 * news teaser
 *****************************************/
.teaser__news {
    border: 1px solid $tabs-border-color;
    overflow: visible;

    .teaser__caption {
        position: absolute;
        left: building-units(2);
        top: building-units(-1);
        display: block;
        background-color: $purple;
        color: $white;
        line-height: building-units(1.8);
        padding: building-units(0.4) building-units(1);
        z-index: 4;
    }

    .teaser__date {
        padding: 0 building-units(1.5);
    }

    .teaser__header {
        margin-bottom: building-units(2);
      padding: 0 building-units(1.5);
    }

    .teaser__content {
        overflow: hidden;
        padding: 0 0 building-units(3) 0 ;
    }

    .teaser__image {
        overflow: hidden;
        margin-bottom: building-units(2);
    }

    .teaser__text {
        padding: 0 building-units(1.5);
        margin-bottom: building-units(0.5);
    }

    .teaser__img {
        width: 100%;
    }

    .teaser__link-wrapper {
        position: relative;
    }

    .teaser__link-label {
        padding-left: building-units(1.5);

        &:before {
            @extend %vacomicon;
            content: "\e900";
            position: absolute;
            left: -11px;
            top: 0;
            font-size: 6px;
            transform: rotate(90deg);

        }
    }

    &:hover {
        .teaser__link-label {
            color: $purple;
            text-decoration: underline;
        }
    }
}

/*****************************************
 * teaser--text-image
 *****************************************/
.teaser--text-image,
.teaser--project {
    position: relative;

    .teaser__image {
        margin-bottom: 0;
        overflow: hidden;
    }

    .link--standalone {
        margin-bottom: 0;
    }
}

/*****************************************
 * subteaser from teaser--text-image
 *****************************************/
.teaser--overlay {

    .btn--theme-dark {
        color: $white;
        background-color: transparent;
        border-color: $white;
    }

    .teaser__btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .teaser__header {
        @include serif-regular-20();
        line-height: 26px;

        @include media-breakpoint-up(md) {
            line-height: 30px;
        }
    }

    .teaser__text {
        @include pt-sans-regular-14();
    }

    .teaser__header,
    .teaser__text {
        color: $white;

        p:last-child {
            margin-bottom: 0;
        }
    }

    .teaser__btn-outline-container,
    .teaser__header,
    .teaser__text {
        display: none;
    }

    .teaser__content {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
    }

    .teaser__image {
        background-size: cover;
        background-position: center center;
        overflow: hidden;
    }

    &:hover {
        .teaser__btn-container {
            display: none;
        }

        .teaser__btn-outline-container,
        .teaser__header,
        .teaser__text {
            display: block;
        }

        &:before {
            content: " ";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($purple, 0.8);
        }
    }
}

//text-image-teaser
.teaser--offset-overlay {
    overflow: inherit;

    .teaser__image {
        position: relative;
        right: 0;
        top: 0;
        height: 100%;

        > a {
            width: 100%;
        }
    }

    .teaser__img {
        width: 100%;
    }

    .teaser__content {
        position: relative;
        margin-top: -15%;
        width: calc(100% - 10px);
        background-color: $white;
        box-shadow: 10px 10px 0 0 $purple;

        @include media-breakpoint-up(sm) {
            margin-top: -20%;
            width: 90%;
        }
    }
}

/*****************************************
 * teaser--editorial
 *****************************************/
.teaser--editorial {
    width: 100%;
    margin-bottom: 0;
    border-bottom: 1px solid $purple;

    @include media-breakpoint-up(md) {
        height: 480px;
    }

    @include media-breakpoint-up(lg) {
        height: 530px;
    }

    @include media-breakpoint-up(xl) {
        height: 550px;
    }

    &.teaser--bg-iceblue {
        border-bottom: 1px solid $white
    }

    //list
    &-wrapper {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        border: 1px solid $purple;

        > li {
            // display: flex;
            width: 50%;

            > a {
                // display: flex;

                &:hover {
                    .teaser__kicker {
                        color: $purple;
                    }

                    .teaser__header {
                        color: $gray-medium-2;
                    }
                }
            }

            &:nth-child(odd) .teaser--editorial.teaser--bg-white {
                @include media-breakpoint-up(md) {
                    border-right: 1px solid $purple;
                    border-bottom: 1px solid $purple;
                }
            }

            &:last-child .teaser--editorial,
            &:nth-last-child(2) .teaser--editorial {
                border-bottom: none !important;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    .teaser__kicker {
        @extend %text-small;
        display: block;
        margin-bottom: building-units(1);
        text-transform: uppercase;
    }

    .teaser__header {
        @extend %h3;
        margin-bottom: building-units(1);
    }

    .teaser__img {
        border-radius: 0;

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .teaser__content {
        justify-content: space-between;
        padding: building-units(3) building-units(2);

        @include media-breakpoint-only(sm) {
            padding: building-units(2) 20px;
        }

        @include media-breakpoint-only(xs) {
            padding: building-units(1);
        }
    }

    .teaser__text, .teaser__text p {
        p:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-only(md) {
            font-size: $font-size-text-small;
            line-height: $line-height-text-small;
        }

        @include media-breakpoint-only(xs) {
            font-size: $font-size-text-small;
            line-height: $line-height-text-small;
        }
    }

    .link {
        line-height: 1; // fixes jumping in IE 11
        text-decoration: none;
    }

    .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        width: 100%;
    }

    .icon-wrapper span.vacomicon {
        font-size: 30px;
        color: $gray-medium-2;
        background-color: rgba($white, 0.5);
        border-radius: 50%;

        -webkit-transition: font-size 1s;
        -moz-transition: font-size 1s;
        -o-transition: font-size 1s;
        transition: font-size 1s;
    }

    &:hover .icon-wrapper span.vacomicon {
        font-size: 45px;
        text-decoration: none;
    }

    .link {
        color: $gray-medium-2;
    }

    &:hover .link {
        text-decoration: underline;
        color: $gray-medium-2;
    }

    &.teaser--image-position--left .icon-wrapper,
    &.teaser--image-position--right .icon-wrapper {

        @include media-breakpoint-up(sm) {
            justify-content: flex-start;
        }
    }
}

/*****************************************
 * teaser--video
 *****************************************/
.teaser--video {
    margin-bottom: building-units(0);

    &:hover {
        .teaser--locked:after,
        .teaser--play:after {
            color: $purple;
        }

        .video-player__preview {
            @extend %imageTransitionHover;
        }
    }

    .video-embed {
        margin-bottom: 0 !important;
    }

    .teaser__skyline {
        .vacomicon {
            font-size: 20px;
            margin-right: 5px;
        }

        .vacom {
            line-height: 20px;
            padding-right: 6px;
            margin-right: 1px;
        }

        .vacom.pipe-right {
            margin-right: 3px;
        }
    }

    .teaser--play figure.video {
        margin-bottom: 0;
    }

    .teaser--play figure.video:after {
        position: absolute;
        bottom: -25px;
        right: 25px;
        z-index: 8;
        font-size: 55px;
        padding-top: 13px;
        width: 55px;
        height: 40px;
        margin-left: -27.5px;
        background-color: $white;
        border-radius: 50%;
        font-family: 'vacomicon', serif;
        content: '\b3';
        color: $purple;
    }
}

a:hover .teaser--grid {
    cursor: default;
}

a:hover .teaser--editorial .teaser__header {
    color: $purple;
}

a:hover .teaser--editorial div.link {
    color: $gray-medium-2;
}

.teaser--editorial-wrapper li .teaser--no-image {

    .teaser__header,
    .teaser__kicker,
    .teaser__content {
        text-align: center;
    }

    .teaser__content {
        display: flex;
        flex-flow: column;
        width: 100%;
        height: 100%;
    }
}

.teaser--image-position--left,
.teaser--image-position--right {
    flex-flow: row wrap;

    @include media-breakpoint-up(sm) {
        // display: flex;
        flex-flow: row nowrap;
    }

    @include media-breakpoint-only(xs) {
        .teaser__header,
        .teaser__kicker,
        .teaser__content,
        .link {
            text-align: center;
        }
    }

    .teaser__content {

        flex: 1 1 auto;
        min-width: 60%;
        order: 0;

        @include media-breakpoint-down(xs) {
            min-width: 100%;
            flex: 1 1 auto;
        }
    }

    .teaser__img {
        width: auto;
        overflow: hidden;
        order: 1;
        flex: 1 1 auto;
        min-width: 40%;

        img {
            @include media-breakpoint-up(md) {
                height: 100%;
                width: auto;
            }
        }
    }
}

.teaser--image-position--right {
    @include media-breakpoint-up(sm) {
        flex-flow: row nowrap;
    }
}

.teaser--image-position--left {
    @include media-breakpoint-up(sm) {
        flex-flow: row-reverse nowrap;
    }
}

.teaser--image-position--below {

    flex-flow: row wrap;
    justify-content: space-between;

    .teaser__header,
    .teaser__kicker,
    .teaser__content {
        text-align: center;
        width: 100%;
    }

    .teaser__content {
        order: 0;
        flex: 1 1 auto;
    }

    .teaser__img {
        flex: 1 1 auto;
        order: 1;
        width: 100%;
    }
}

// @ToDo determine which of these is correct and remove redundant
.teaser--image-position--above,
.teaser--image-position--top {

    flex-flow: row wrap;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
        flex-flow: row wrap-reverse;

        .teaser__content {
            order: 0;
        }

        .teaser__img {
            order: 1;
        }
    }

    .teaser__header,
    .teaser__kicker,
    .teaser__content {
        text-align: center;
        width: 100%;
    }


    .teaser__content {
        flex: 1 1 auto;
    }

    .teaser__img {
        flex: 1 1 auto;
    }
}

.teaser--project {
    .teaser__logo {
        max-width: 170px;
        max-height: 50px;
        margin-bottom: 15px;
    }

    .teaser__kicker {
        @extend %text-small;
        margin-bottom: 0;
    }
}

a:hover > .teaser--project .teaser__kicker {
    color: $purple;
}

a:not(.category--tile) {
    .teaser__img {
        @extend %imageTransitionHoverOut;

        &:hover {
            @extend %imageTransitionHover;
        }
    }

    > .teaser:hover .teaser__img {
        @extend %imageTransitionHover;
    }
}

.category--tile {
    .teaser__header {
        color: $purple;
        @include pt-serif-regular-20();

        &:hover {
            text-decoration: underline;
        }
    }

    .teaser__text {

    }
}


.swiper .teaser {
    margin-bottom: 0;

    &.teaser--text-image,
    &.teaser__news {
        .teaser__text,
        p:last-child {
            margin-bottom: 0;
        }
    }
}

/*****************************************
 * teaser--nos
 *****************************************/
.teaser--nos {
  position: absolute;
  padding: 4px 10px;
  background-color: #9c0a7d;
  color: white;
  top: 0;
  left: 30%;
}
