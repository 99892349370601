//-------------------------------------------------------------
// Placeholder
//-------------------------------------------------------------

// Placeholder: general
::-webkit-input-placeholder { // Chrome/Opera/Safari
    color: $placeholder-color;
}

::-moz-placeholder { // Firefox 19+
    color: $placeholder-color;
}

:-ms-input-placeholder { // IE 10+
    color: $placeholder-color;
}

:-moz-placeholder { // Firefox 18-
    color: $placeholder-color;
}

// Placeholder: disabled
:disabled {
    &::-webkit-input-placeholder { // Chrome/Opera/Safari
        color: $placeholder-disabled-color;
    }

    &::-moz-placeholder { // Firefox 19+
        color: $placeholder-disabled-color;
    }

    &:-ms-input-placeholder { // IE 10+
        color: $placeholder-disabled-color;
    }

    &:-moz-placeholder { // Firefox 18-
        color: $placeholder-disabled-color;
    }
}
