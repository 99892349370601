//-------------------------------------------------------------
// Page Layout Styles
//-------------------------------------------------------------

// Layout of pages and content wrappers

.content {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
}

.content-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;

    &--right-column {
      .main {
        max-width: 100%;
      }
    }

    @include media-breakpoint-up(lg) {
        &--background {
            background-position: center;
            background-size: cover;
            background-image: url(../../assets/images/content-wrapper.jpg)
        }
    }
}

.main {
    position: relative;
    padding-bottom: 74px;

    @include media-breakpoint-up(lg) {
        padding-bottom: 94px;
    }

    flex: 1 1 auto;
    max-width: 100%;
}

.centered-main {
  align-self: center;
  margin: 0 auto;
}

.sidebar {
    flex: 0 0 300px;
    margin-right: 24px;

    &.is-open + .main {
        flex: 0 0 calc(100% - 324px);
        max-width: calc(100% - 324px)
    }
}

.right-column {
    flex: 0 0 100%;
    margin-left: 0;
    margin-bottom: building-units(2);

    }

@include media-breakpoint-up(lg) {
  .content-wrapper {
    display: flex;
    flex-flow: row nowrap;

    &--right-column {
      .main {
        flex: 0 0 calc(100% - 324px);
        max-width: calc(100% - 324px);
      }
    }
  }

  .right-column {
    flex: 0 0 300px;
    margin-left: 24px;
  }
}
