//-------------------------------------------------------------
// Checkbox
//-------------------------------------------------------------

// Variables
$checkbox-size: building-units(2)-2px;
$label-line-height: building-units(2);
$checkbox-br-radius: 0;
$checkbox-icon-size: building-units(1.5);
$checkbox-shadow: $form-elements-box-shadow;

$checkbox-bg-color: $white;
$checkbox-br-color: $gray-medium-2;
$checkbox-bg-color-hover: $white;
$checkbox-br-color-hover: $black;
$checkbox-bg-color-active: $purple;
$checkbox-color-active: $white;
$checkbox-br-color-focus: $purple;
$checkbox-shadow-focus: $checkbox-shadow $purple;
$checkbox-icon-color: $white;

$checkbox-bg-color-disabled: opacify($gray-medium-2, 0.1);
$checkbox-br-color-disabled: $gray-medium-1;
$checkbox-color-disabled: $checkbox-color-active;
$checkbox-icon-color-disabled: $gray-medium-2;

$checkbox-bg-color-error: rgba($color-error, .05);
$checkbox-br-color-error: $red;
$checkbox-bg-color-error-hover: $red-251;
$checkbox-br-color-error-focus: $red;
$checkbox-shadow-error-focus: $checkbox-shadow $red;
$checkbox-icon-color-error: $red;

// General styles
.form-checkbox-set {
    margin-bottom: building-units(1);
    margin-top: 0;
    position: relative; // prevent scrolling on click at label

    label {
        line-height: $checkbox-size; //add border
    }

    &:last-child {
        margin-bottom: 0;
    }

    // Error
    &.has-error {
      input.form-checkbox {
        + label {
          color:  $checkbox-icon-color-error;
          &::before {
            border-color: $checkbox-br-color-error;
            background:   $checkbox-bg-color;
            color:        $checkbox-icon-color;
          }
          &::after {
            border-color: $checkbox-br-color;
          }
        }

        &:hover {
          + label {
            &::after {
              background: $checkbox-bg-color-active;
            }
          }
        }

        &:active:not(:checked),
        &:focus:not(:active) {
          outline: 0;
          box-shadow: none;

          + label {
            &::before {
              border-color: $checkbox-br-color-error-focus;
              box-shadow: none;
            }
            &::after {
              border-color: $checkbox-bg-color-active;
              box-shadow: none;
        }
      }
    }
  }
}
}

.form-checkbox-group {
margin: building-units(2) 0;

&.has-error .validation-message {
    font-weight: $font-weight-bold;
    margin-bottom: building-units(1);
}
}

.form-checkbox {
width: $checkbox-size;
height: $checkbox-size;
border-radius: $checkbox-br-radius;

position: absolute;
top:      0;
left:     0;
z-index:  1;
opacity:  0;

+ label {
    @include pt-sans-regular-18();
    line-height: $label-line-height;
    display:        inline-flex;
    min-height:     0;
    margin-bottom:  0;
    position:       relative;
    left:           building-units(0);
    text-transform: none;
    padding-left: building-units(1);

    > span {
        padding-left: 0;
    }

    &:hover {
        color: $checkbox-br-color-hover;
        &::before,
        &::after {
            border-color: $checkbox-br-color-hover;
            background-color: $checkbox-bg-color-hover;
        }
    }

    &::before {
        //@extend %cadfemicon;
        position:      absolute !important;
        top:           -1px;
        left:          0;
        height:        $checkbox-size;
        width:         $checkbox-size;
        border:        1px solid $checkbox-br-color;
        font-size:     $checkbox-size;
        color:         $checkbox-icon-color;
        text-align:    center;
        transition:    none;
        border-radius: $checkbox-br-radius;
        background-color: $checkbox-bg-color;
        border-shadow: none !important;
    }

    &::after {
      height:        $checkbox-size;
      width:         $checkbox-size;
      line-height:         $checkbox-size;
      border-radius: 0;
      border-shadow: none !important;
      transition: none;
    }

    &::before {
        content: "";
        transition:    none;
    }

    &:hover {
        cursor: pointer;
    }
}

&:checked {
    + label {
        &::before,
        &::after {
            top:           -1px;
            left:          0;
            background-color: $checkbox-bg-color-active;
            border-color: $checkbox-bg-color-active;
            color: $checkbox-color-active
            //content: "\39"
        }
    }
    &:hover:not(:disabled) {
      + label {
        &::before,
        &::after {
          content:"\58";
          border-color: $checkbox-bg-color-active;
        }
      }
    }
}

&:hover:not(:checked) {
    cursor: pointer;

    + label {
      &::after,
      &::before {
        background-color: $checkbox-bg-color-hover;
        border-color: $checkbox-br-color-hover;
      }
    }
}

&:active:not(:checked) {
  outline: 0;
  + label {
    &::after,
    &::before {
      background-color: $checkbox-bg-color-hover;
    }
  }
}

&:focus:not(:active) {
    outline: 0;

    + label {
        &::before,
        &:after {
            border-color: $checkbox-br-color-focus;
            box-shadow: none;
        }
    }
}

// Disabled
&:disabled:checked {
    cursor: not-allowed;
    + label {
        cursor: not-allowed;
        color: $checkbox-br-color-disabled;

            &::before,
            &::after{
                border-color: transparent;
                background: $checkbox-bg-color-disabled;
                color: $checkbox-color-disabled;
            }
    }
}
&:disabled:not(:checked) {
    +label {
        cursor: not-allowed;
        color:  $checkbox-br-color-disabled;

        &::before {
            border-color: $checkbox-br-color-disabled;
            background-color: transparent;
            color: $checkbox-br-color-disabled;
        }
    }
}
}
