//-------------------------------------------------------------
// Button outline
//-------------------------------------------------------------

// Variables
$button-text-color-outline: $purple;
$button-bg-color-outline: transparent;
$button-border-color-outline: $purple;

$button-text-color-outline-hover: rgba($purple, .8);
$button-bg-color-outline-hover: transparent;
$button-border-color-outline-hover: rgba($purple, .8);

$button-text-color-outline-active: $white;
$button-bg-color-outline-active: $purple;
$button-border-color-outline-active: $purple;

$button-text-color-outline-disabled: $gray-medium-2;
$button-bg-color-outline-disabled: transparent;
$button-border-color-outline-disabled: $gray-medium-2;

// dark theme
$button-text-color-dark-theme-outline: $white;
$button-bg-color-dark-theme-outline: transparent;
$button-border-color-dark-theme-outline: $white;

$button-text-color-dark-theme-outline-hover: rgba($purple, .8);
$button-bg-color-dark-theme-outline-hover: rgba($white, .8);
$button-border-color-dark-theme-outline-hover: rgba($purple, .8);

$button-text-color-dark-theme-outline-active: rgba($white, .8);
$button-bg-color-dark-theme-outline-active: rgba($white, .8);
$button-border-color-dark-theme-outline-active: $white;

$button-text-color-dark-theme-outline-disabled: $gray-medium-2;
$button-bg-color-dark-theme-outline-disabled: transparent;
$button-border-color-dark-theme-outline-disabled: $gray-medium-2;

.btn--outline {

  color: $button-text-color-outline;
  background-color: $button-bg-color-outline;
  border: 1px solid $button-border-color-outline;

  &:focus-within,
  &:focus {
    color: $button-text-color-outline-hover;
    background-color: $button-bg-color-outline-hover;
    border: 1px solid $button-border-color-outline-hover;
  }

  //separate because of IE
  &:hover {
    color: $button-text-color-outline-hover;
    background-color: $button-bg-color-outline-hover;
    border: 1px solid $button-border-color-outline-hover;
  }

  &:active {
    color: $button-text-color-outline-active;
    background-color: $button-bg-color-outline-active;
    border: 1px solid $button-border-color-outline-active;
  }

  &[disabled] {
    cursor: not-allowed;
    background: $button-bg-color-outline-disabled;
    border: 1px solid $button-border-color-outline-disabled;
    color: $button-text-color-outline-disabled;
  }

  // dark theme
  &.btn--theme-dark {

    color: $button-text-color-dark-theme-outline;
    background-color: $button-bg-color-dark-theme-outline;
    border: 1px solid $button-border-color-dark-theme-outline;

    &:focus-within,
    &:focus {
      color: $button-text-color-dark-theme-outline-hover;
      background-color: $button-bg-color-dark-theme-outline-hover;
      border: 1px solid $button-border-color-dark-theme-outline-hover;
    }

    //separate because of IE
    &:hover {
      color: $button-text-color-dark-theme-outline-hover;
      background-color: $button-bg-color-dark-theme-outline-hover;
      border: 1px solid $button-border-color-dark-theme-outline-hover;
    }

    &:active {
      color: $button-text-color-dark-theme-outline-active;
      background-color: $button-bg-color-dark-theme-outline-active;
      border: 1px solid $button-border-color-dark-theme-outline-active;
    }

    &[disabled] {
      cursor: not-allowed;
      background: $button-bg-color-dark-theme-outline-disabled;
      border: 1px solid $button-border-color-dark-theme-outline-disabled;
      color: $button-text-color-dark-theme-outline-disabled;
    }
  }
}
