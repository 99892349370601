//-------------------------------------------------------------
// Metanavigation
//-------------------------------------------------------------

.meta-navigation-vc {
    @extend %flex-container;
    width: auto;

    @include media-breakpoint-down(lg) {
        width: 100%;
    }

    &__menu {
        position: relative;
        z-index: 101;

        @extend %flex-container;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        .meta-navigation-vc__item {
            margin-bottom: 0 !important;

            &:first-child {
                padding: 0;
                .navbar-vc-link-icon {
                    padding: 0;
                }
            }
        }
    }

    &__item {
        .vacomicon {
            font-size: 20px;
            height: 20px;
            width: 20px;
        }

        .badge {
            position: absolute;
            left: 26px;
            top: -14px;
        }
    }

    &__link {
        display: block;

        &.minicart,
        &.wishlist {
            position: relative;
        }
    }
}
