.manadev-show-more {
    position: relative;
    margin-top: 25px;
    margin-bottom: building-units(1);
    background-color: $white;
    padding-left: building-units(1);
    padding-top: 5px;
}

.manadev-show-more > a {
    max-height: 24px;
    color: $purple;
    text-transform: uppercase;
}

.manadev-show-more > a:hover {
    cursor: pointer;
}

.manadev-expandable-filter.outer-container {
    overflow:hidden;
    transition-property: height;
}

.mana-filter-checkboxes .item:not(:first-child) {
    margin-top: 25px;
}

@include media-breakpoint-only(xs) {
    .mana-filter-block .filter-current {
        border-width: 0;
    }
}

.mana-no-match {
    display: none;
}

.mana-overlay {
    position: absolute;
    background: white;
    filter: alpha(opacity=0.5); /* percent */
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
    z-index: 10000;
}

#mana-please-wait {
    position: absolute;
    color: #d85909;
    font-size: 1.1em;
    text-align: center;
    z-index: 20000;

    p {
        position: fixed;
        top: 45%;
        left: 50%;
        width: 120px;
        margin-left: -120px;
        padding: 15px 60px;
        background: rgba(255, 255, 255, 0.9);
        border: 2px solid $purple;
        color: $purple;
        text-align: center;
        z-index: 20100;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        -ms-box-sizing: content-box;

        img {
            margin: 10px auto 0;
        }
    }
}

.category_product_list_banner {
    width: 100%;
    padding-left: building-units(1.5);
    padding-right: building-units(1.5);

    @include media-breakpoint-down(md) {
        padding-left: building-units(0.75);
        padding-right: building-units(0.75);
    }

    .teaser--text-image {

        @include media-breakpoint-up(sm) {
            margin: building-units(0.75) 0;
            flex-flow: row !important;
            justify-content: flex-start;
            align-items: stretch;
            align-content: flex-start;

            .teaser__image {
                background-size: cover;
                max-width: unquote("calc(33% - 15px)");
                width: 100%;

                img {
                    visibility: hidden;
                }
            }
        }

        @include media-breakpoint-only(sm) {
            .teaser__image {
                max-width: unquote("calc(33% - 7.5px)");
            }
        }
    }
}

.product-toolbar {
    margin-bottom: building-units(1.5)
}

.catalog-category-view,
.catalogsearch-result-index {
    .subcategory-listing {

        .teaser--outline:hover {
            border: 1px solid $gray-medium-2;
        }

        .teaser__text {
            margin-bottom: 0;

            > p {
                margin-bottom: 10px;
            }
        }
    }

    .toolbar.toolbar-products {
        display: flex;
        flex-flow: row;
        width: 100%;

        @include media-breakpoint-only(xs) {
            flex-flow: column;
        }

        &.top {
            justify-content: space-between;
            margin-bottom: 10px;
        }

        &.bottom {
            justify-content: center;
            margin-top: 20px;
        }

        .toolbar--wrapper {
            width: 100%;
        }

        &.top .toolbar--wrapper {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
        }

        .pagination {
            width: 100%;
            margin-top: 10px;
        }

        .mode {
            display: flex;
            flex-grow: 0;
            justify-content: flex-end;
            width: 100%;

            .product-switch {
                min-width: 75px;
                margin-right: 0 !important;
            }
        }

        .sort {
            align-items: flex-end;

            @include media-breakpoint-only(xs) {
                width: 100%;
            }

            .toolbar-sorter {
                display: inline-flex;
            }

            .sorter-action {
                line-height: 50px;
            }
        }
    }
}

body.customer-logged-in .sidebar.sidebar-additional {
    display: none;
}

@include media-breakpoint-down(md) {
    body.customer-logged-out .sidebar.sidebar-additional {
        display: none;
    }
}