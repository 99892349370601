.chip {
    @include pt-sans-regular-14();
    display: inline-block;
    color: $black;
    padding: building-units(0.5) building-units(1);
    border-radius: 0;
    background-color: $gray-light;
    margin-top: building-units(1);
    margin-right: building-units(1);
    cursor: pointer;

    &:hover {
        @include pt-sans-regular-14();
        color: $white;
        background-color: $purple;

        .vacomicon {
            &:before {
                color: $white;
            }
        }
    }

    .vacomicon {
        &:before {
            position: relative;
            color: $purple;
            top: 6px;
        }
    }

    > .vacomicon {
        cursor: pointer;
        float: right;
        font-size: 12px;
        padding-left: building-units(1);
    }
}
