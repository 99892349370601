//-------------------------------------------------------------
// Headings
//-------------------------------------------------------------

// Mixins and Extend Classes

%heading-margin {
    margin: 0;
}

%h1-h4-color {
  color: $purple;
}

%h1 {
    @include pt-serif-regular();
    @extend %h1-h4-color;

    font-size:   rem($font-size-h1-sm);
    font-weight: $font-weight-normal;
    line-height: $line-height-h1-sm;

    margin: 0 0 building-units(2) 0;

    @include media-breakpoint-up(md) {
        font-size:     rem($font-size-h1-l);
        line-height:   $line-height-h1-l;
    }
}

%h2 {
    @include pt-serif-regular();
    @extend %h1-h4-color;

    font-size:   rem($font-size-h2-sm);
    font-weight: $font-weight-normal;
    line-height: $line-height-h2-sm;

    margin: 0 0 building-units(2) 0;

    @include media-breakpoint-up(md)  {
        font-size:     rem($font-size-h2-l);
        line-height:   $line-height-h2-l;
    }
}

%h3 {
    @include pt-serif-regular();
    @extend %h1-h4-color;

    font-size:   rem($font-size-h3-sm);
    font-weight: $font-weight-normal;
    line-height: $line-height-h3-sm;

    margin: 0 0 building-units(1.5) 0;

    @include media-breakpoint-up(md)  {
      font-size:     rem($font-size-h3-l);
      line-height:   $line-height-h3-l;
    }
}

%h4 {
    @include pt-serif-regular();
    @extend %h1-h4-color;

    font-size:   rem($font-size-h4-sm);
    font-weight: $font-weight-normal;
    line-height: $line-height-h4-sm;

    margin: 0 0 building-units(1.5) 0;

    @include media-breakpoint-up(md)  {
      font-size:     rem($font-size-h4-l);
      line-height:   $line-height-h4-l;
    }
}

%h5 {
    @include pt-sans-regular();

    font-size:   rem($font-size-h5-sm);
    font-weight: $font-weight-bold;
    line-height: $line-height-h5-sm;
    color: $black;

    margin: 0 0 building-units(1.5) 0;

    @include media-breakpoint-up(md)  {
      font-size:     rem($font-size-h5-l);
      line-height:   $line-height-h5-l;
    }
}

%h6 {
  @include pt-sans-regular();

  font-size:   rem($font-size-h5-sm);
  font-weight: $font-weight-bold;
  line-height: $line-height-h5-sm;
  color: $black;

  margin: 0 0 building-units(1.5) 0;

  @include media-breakpoint-up(md)  {
    font-size:     rem($font-size-h5-l);
    line-height:   $line-height-h5-l;
  }
}

%display {

    font-size:   rem($font-size-display);
    font-weight: $font-weight-light;

    margin: 0 0 building-units(3) 0;
}

// Styles

h1,
.h1 {
    @extend %h1;
}

h2,
.h2 {
    @extend %h2;
}

h3,
.h3 {
    @extend %h3;
}

h4,
.h4 {
    @extend %h4;
}

h5,
.h5 {
    @extend %h5;
}

h6,
.h6 {
    @extend %h6;
}

.display {
    @extend %display;
}

.header-container {
  width: 100%;

  .heading {
    .is-home & {
      &__main {
        @include pt-serif-regular-40();
        margin-bottom: building-units(3);
        color: $purple;
      }
    }

    &__description {
      @extend %text-default;
      margin-bottom: building-units(2);
    }
  }
}
