.fib {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.fi {
  @extend .fib;
  position: relative;
  display: inline-block;
  width: 28px;
  //line-height: 1em;
  &:before {
    content: '\00a0';
  }
}

@mixin flag-icon($country) {
  .fi-#{$country} {
    background-image: url(#{$flag-icons-path}/#{$country}.svg);
  }
}
