// Here you can write your own css in order to customise your UI components

// Teaser
// new = $teaser-grey-hover-text-color
.teaser--grey {
  .teaser__content {
    .teaser__header {
      color: $teaser-grey-header-color;
    }
    
    // &:hover .teaser__text {
    //   color: $teaser-grey-hover-text-color;
    // }

  }
}
.teaser--magenta { 
  .teaser__content {
    .teaser__header {
      color: $teaser-primary-header-color;
    }
  }
}