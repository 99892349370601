@mixin navbar-link {
  text-decoration: none;
  color: $navbar-link-color;

  &:hover,
  &:focus,
  &:active {
    color: $navbar-link-hover-color;
    text-decoration: none;
  }
}

%navbar-link {
  @include navbar-link();
}
