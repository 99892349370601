hr{
    margin: 24px 0;
    border-top: 0;
    border-left:0;
    border-right:0;
    border-bottom-width: 1px;
    border-color: #D0D0D0;
    border-style: solid;
    &.dashed
        { 
            border-style: dashed;
        }
    &.emphasized
        { 
            border-bottom-width: 3px;
        }
    }