//-------------------------------------------------------------
// Lists
//-------------------------------------------------------------

ol,
ul {
    margin-top: 0;
}

ol {
    padding-left: rem(24px);
}

ul {
    padding-left: rem(20px);
    list-style-type: square;
}

dl {
    padding-left: rem(20px);
}

.list__download {
    list-style-type: none;
    position: relative;

    &:before {
        @extend %teleicon;
        content: '\64';
        font-size: rem(18px) !important;
        position: absolute !important;
        left: rem(-20px);
        top: 0;
        width: rem(20px);
        text-align: center;
    }
}

.list-square-l{
    margin-bottom:  rem(12px);

    span {
        font-size: rem(building-units(2)-2);
        line-height: rem(building-units(2)-2);
    }
}

dt {
    display: list-item;
    list-style-type: square;
    font-weight: $font-weight-bold;
}

dd {
    margin: 0 0 rem(12px);
}
