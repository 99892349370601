//-------------------------------------------------------------
// Input
//-------------------------------------------------------------

// Variables
$input-box-shadow: $form-elements-box-shadow;

$input-br-radius:             4px;
$input-height:                building-units(3);
$input-padding:               building-units(.5) building-units(1);
$input-elements-padding:      building-units(.25);
$input-icon-size:             building-units(1.5);
$input-margin:                building-units(.5) 0;

// Global Styles
.form-input-set {
    width:  100%;
    margin: $input-margin;

    input.form-input {
        height: $input-height;
    }

    .form-input {
        display:          block;
        width:            100%;
        padding:          $input-padding;
        background-color: $input-background-color;
        border-radius:    $input-br-radius;
        border:           1px solid $input-border-color;
        color:            $input-color;

        &:hover {
            background-color: $input-hover-background-color;
        }

        &:focus {
            outline:        0;
            border-color:   $input-focus-border-color;
            box-shadow:     $input-box-shadow $input-focus-box-shadow-color;
        }

        &:active {
            background-color: $input-active-background-color;
            border-color:     $input-border-color;
            box-shadow:       none;
        }

        &:disabled {
            color:        $input-disabled-color;
            border-color: $input-disabled-border-color;

            &:hover {
                background-color: $input-background-color;
                border-color:     $input-disabled-border-color;
                cursor:           default;
            }

            &:active {
                background-color: $input-background-color;
            }
        }
    }

    // Number
    [type=number] {
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    // Password
    .password-input {
        position: relative;

        .form-input {
            padding-right: building-units(3);

            &:disabled {
                ~ .toggle-visibility {
                    opacity: .3;
                    cursor:  default;
                }
            }
        }

        .toggle-visibility {
            position: absolute;
            height:   $input-icon-size;
            width:    $input-icon-size;
            bottom:   8px;
            right:    building-units(1);
            cursor:   pointer;
        }
    }

    // Icon
    .icon-input {
        position: relative;

        .form-input {
            padding-right: building-units(3);

            &:disabled {
                ~ .teleicon {
                    opacity: .3;
                    cursor:  default;
                }
            }
        }

        .teleicon {
            cursor:   pointer;
            position: absolute;
            bottom:   8px;
            right:    building-units(1);

            &::before {
                font-size: $input-icon-size;
                color:     $input-icon-color;
            }

            &.icon-search::before {
                color:     $icon-search-before-color;
            }
        }
    }
}

@import "input-validation";
@import "input-addon";
@import "input-link";
