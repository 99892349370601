//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables

.sitechooser {
    padding-left: 0;

    &__description {
        margin-bottom: 20px;
        font-size: $font-size-text-small;
        line-height: 24px;
    }

    &__modal {
        width: 750px;
        margin-left: -375px;
    }

    img {
        height: 24px;
        vertical-align: middle;
    }

    ul {
        font-size: $font-size-text-small;
        line-height: 24px;
        list-style: none;
        padding-left: 0;
    }

    li {
        padding-left: 0;
    }

    .header {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: $font-size-text-small;
        line-height: 24px;
        font-weight: bold;
    }
}

@include media-breakpoint-up(xl) {
    #sitechooser__modal {
        width: 750px;
        margin-left: -375px !important;
    }
}
