//-------------------------------------------------------------
// Button secondary
//-------------------------------------------------------------

// Variables
$button-text-color-secondary: $white;
$button-bg-color-secondary: $purple;
$button-border-color-secondary: $purple;

$button-text-color-secondary-hover: $white;
$button-bg-color-secondary-hover: rgba($purple, .8);
$button-border-color-secondary-hover: rgba($purple, .8);

$button-text-color-secondary-active: $white;
$button-bg-color-secondary-active: $purple;
$button-border-color-secondary-active: $purple;

$button-text-color-secondary-disabled: $gray-medium-2;
$button-bg-color-secondary-disabled: $gray-light;
$button-border-color-secondary-disabled: $gray-medium-2;

.btn--secondary {

  color: $button-text-color-secondary;
  background-color: $button-bg-color-secondary;
  border-color: $button-border-color-secondary;

  &:focus-within,
  &:focus,
  &:hover {
    color: $button-text-color-secondary-hover;
    background-color: $button-bg-color-secondary-hover;
    border-color: $button-border-color-secondary-hover;
  }

  &:active {
    color: $button-text-color-secondary-active;
    background-color: $button-bg-color-secondary-active;
    border-color: $button-border-color-secondary-active;
  }

  &[disabled] {
    cursor: not-allowed;
    background: $button-bg-color-secondary-disabled;
    border-color: $button-border-color-secondary-disabled;
    color: $button-text-color-secondary-disabled;
  }
}
