//-------------------------------------------------------------
// Selectbox
//-------------------------------------------------------------

// Variables
$optionlist-br-color: $gray-dark;
$optionlist-bg: $white;
$optionlist-color-primary: $black;
$optionlist-color: $optionlist-color-primary;
$optionlist-bg-hover: $white-light;
$optionlist-bg-select: $gray-220;
$optionlist-bg-hover-select: $gray-199;

.js-selectbox {
    margin: 0 5px 0 7px;
    padding: 0 10px;
    height: 50px;
    border-radius: 0;

    -webkit-appearance: listbox;
}
select.js-selectbox {
    margin: 0 4px 0 7px;
    padding: 10px 40px 10px 15px;
}

// Selectbox stylings
.selectbox {
    position: relative;
    display: inline-block;

    .form-input-search {
        border-bottom: 1px solid $optionlist-br-color;
        padding: building-units(1 / 6) 0;
        margin-bottom: building-units(1);

        &::before {
            display: none;
        }

        .form-input {
            border: 0;
            padding: 0 building-units(.5);
            height: building-units(8 / 3);
            width: 100%;
            margin-bottom: 0;
        }
    }

    .empty-result-entry {
        color: $optionlist-color;
        padding: building-units(1);
    }

    &-label {
        @include pt-sans-regular-16();
        white-space: inherit;

        > div {
            //white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 3px;
        }

        @include media-breakpoint-up(lg) {
            @include pt-sans-regular-18();
        }

        position: relative;
        height: 50px;
        color: $optionlist-color-primary;
        background-color: $white;
        border: 1px solid $gray-medium-3;
        border-radius: 0;
        width: calc(100%);
        margin-bottom: 0;
        padding: building-units(1) building-units(4) building-units(1) building-units(1.5);
        line-height: 28px !important;

        @include reset-hover();

        &:focus,
        &.active,
        &:focus-within {
            border-color: $black;
            color: $optionlist-color-primary;
            background-color: $white;
        }

        //separate because of IE
        &:hover {
            border-color: $black;
            color: $optionlist-color-primary;
            background-color: $white;
        }

        &::after {
            @extend %vacomicon;
            content: "\e924";
            position: absolute !important;
            top: 50%;
            right: building-units(1.5);
            transform: translateY(-50%);
            height: 10px;
            font-size: 10px;
            color: $black;
        }

        &--open {
            &:after,
            &:after {
                content: '\e900';
            }
        }
    }

    &-option {
        display: table;
        width: 100%;
        min-height: building-units(5);

        padding: 0;
        margin: 0;
    }

    &-optionlist {
        list-style-type: none;
        display: none;
        position: absolute;
        top: building-units(5) - 1px;
        left: 0;
        min-width: building-units(15);
        z-index: 5;
        border: 1px solid $optionlist-br-color;
        border-radius: 0;
        background-color: $optionlist-bg;
        padding: 0;
        overflow-y: auto;
        width: 100%;

        &.optionlist-open {
            display: block;
        }

        a {
            @include pt-sans-regular-16();

            @include media-breakpoint-up(lg) {
                @include pt-sans-regular-18();
            }

            color: $optionlist-color;
            text-decoration: none;
            padding: 0 building-units(1.5);
            display: table-cell;
            vertical-align: middle;

            &:focus,
            &:focus-within {
                background: $optionlist-bg-hover;
                color: $purple;
            }

            &:hover {
                background: $optionlist-bg-hover;
                color: $purple;
            }

            &:active {
                background: $optionlist-bg-hover;
                color: $purple;
            }

            &.selectbox-selected {
                color: $purple;
            }

            &.option-inactive {
                color: $gray-medium-2;
                cursor: no-drop;
            }
        }
    }

    &--inactive {
        .selectbox-label {
            color: $gray-medium-2;
            background-color: $white-light;
            border-color: $gray-medium-1;
            cursor: no-drop;

            &:after {
                color: $gray-medium-2;
            }
        }
    }

    &.has-error,
    &--alert {
        .selectbox-label {
            background-color: rgba($color-error, .05);
            border-color: $color-error;
            color: $color-error;

            &:after {
                color: $color-error;
            }
        }
    }

    &--open-left {
        .selectbox-optionlist {
            left: auto;
            right: 0
        }
    }

    &--open-center {
        .selectbox-optionlist {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &-hidden {
        visibility: hidden;
        position: absolute;
        left: -9999px;
    }

    &--dark {
        .selectbox-label {
            color: #fff;
        }
    }
}

.input-group.has-error .selectbox-label {
    background-color: rgba($color-error, .05);
    border-color: $color-error;
    color: $color-error;

    // Prevent overlapping of the icons (Alert, Select)
    &:after {
        margin-right: 20px;
    }
}
