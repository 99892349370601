// Width and height

@each $prop, $abbrev in (width: w, height: h) {
    @each $breakpoint in map-keys($vacom-grid-breakpoints) {
        @each $size, $length in $sizes {
            @include media-breakpoint-up($breakpoint) {
                $infix: breakpoint-infix($breakpoint, $vacom-grid-breakpoints);
                .#{$abbrev}-#{$size}#{$infix} { #{$prop}: $length !important; }
            }
        }
    }
}

.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }
