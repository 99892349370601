//-------------------------------------------------------------
// collapse-menu
//-------------------------------------------------------------
// Variables

// reset ul
ul.collapse-menu {
  margin: 0;
  padding: 0;
}

.collapse-menu__navigation {
  @extend %teleicon;

  content: "\24";
}

.collapse-menu {

  &__item {
    background-color: $collapse-menu-item-backgroung-color;
    border-bottom: 1px solid $collapse-menu-border-bottom-color;
    display: block;
    list-style: none;

    &:last-child {
      border-bottom: none;
    }
  }

  &__headline {
    display: flex;
    flex-direction: row nowrap;
  }

  // clickable option
  &__link {
    color: $sidebar-link-text-color;
    display: inline-block;
    font-size: $font-size-base;
    letter-spacing: 0;
    line-height: $line-height-base;
    padding-top: building-units(1);
    padding-bottom: building-units(1);
    padding-left: building-units(1);
    text-decoration: none;
    flex: 1;

    @include reset-hover();

    &:hover, &:active {
      color: $sidebar-link-hover-text-color;
      background-color: $sidebar-link-hover-background-color;
      cursor: pointer;
    }
  }

  &__toggle {
    flex: 0 50px;
    min-width: 50px;
    text-decoration: none;
    display: block;
    position: relative;
    padding: building-units(1);
    color: $collapse-menu-toggle-color;
    margin-left: auto;

    &:before {
      position: absolute;
      width: 1px;
      top: building-units(1);
      bottom: building-units(1);
      left: 0;
      border-left: 1px solid $gray-220;
      content: ""
    }

    &:hover {
      background-color: $sidebar-link-hover-icon-background-color;
      color: $sidebar-link-hover-icon-color;
    }

    .icon-navigation-up {
      display: none
    }

    .icon-navigation-down {
      display: inline-block
    }
  }

  //&__toggle-icon {
    //position: relative;
    //top: building-units(1.25);
    //left: building-units(1.25);
  //}

  //option content area
  &__content {
    display: none;
    background-color: $collapse-menu-content-background-color;
    cursor: default;
  }

  &__directlinks {
    padding: 0 building-units(1);
  }

  &__quicklinks {
    border-top: 1px solid $collapse-menu-quicklinks-border-top-color;
  }

  &__quicklinks-title {
    color: $collapse-menu-quicklinks-title-color;
  }

  &__quicklinks-list {
    @extend %horizontal-nav;
    flex-flow: row wrap;
  }

  &__quicklinks-item {
    margin-right: 48px;
    min-height: 42px;
  }

  &__quicklinks-link {
    @extend %navbar-link;
  }

  //content area is now opened
  &--open {
    > .collapse-menu__headline .collapse-menu__link {
      font-weight: 700;
    }

    //no background-color at any level on hover
    .collapse-menu__link:hover {
      background-color: $sidebar-link-active-background-color-hover;
    }

    //display
    > .collapse-menu__headline > .collapse-menu__toggle {

      > .icon-navigation-up {
        display: inline-block;
      }

      > .icon-navigation-down {
        display: none;
      }
    }

    > .collapse-menu__content {
      display: block;
      padding-left: building-units(0);
    }

    //sub lists will be have no border and a lighter background
    .collapse-menu__item {
      background-color: $gray-237;
      border-bottom: none;

      .collapse-menu__content {
        padding-left: building-units(1);
      }

      .collapse-menu__item {
        background-color: transparent;
      }
    }
  }

  &--current {
    > .collapse-menu__headline .collapse-menu__link {
      color: $sidebar-link-headline-color;
      font-weight: 700; 
    } 
  }
}

