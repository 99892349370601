.index-table {
  width: 100%;
  border: 1px solid #dcdcdc;
  border-collapse: collapse;

  td,
  th {
    padding: rem(12px);
  }

  &:not(.index-table--filtered) tr {
    &:nth-child(2n) {
      td,
      th {
        background: $gray-248;
      }
    }
  }

  &--filtered {
    .even {
      td,
      th {
        background: $gray-248;
      }
    }
  }

  &__container {
    margin-top: rem(30px);
  }

  &__input-container {
    position: relative;
    margin-bottom: rem(12px);
  }

  &__input-append {
    display: block;
    pointer-events: none;
    color: $gray-56;
    position: absolute;
    right: 1px;
    height: rem(32px);
    top: 1px;
    bottom: auto;
    width: rem(44px);
    background: $gray-237;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 4px 4px 0;
    font-size: rem(18px);
  }

  &__input {
    border-radius: 4px;
    border: solid 1px #dcdcdc;
    color: #262626;
    padding: rem(6px 50px 6px 12px);
    width: 100%;

    &:hover {
      background-color: $input-hover-background-color;
    }

    &:focus {
      outline: 0;
      border-color: $input-focus-border-color;
      box-shadow: $input-box-shadow $input-focus-box-shadow-color;
      pointer-events: none;
    }

    &:active {
      background-color: $input-active-background-color;
      border-color: $input-border-color;
      box-shadow: none;
    }
  }

  &__table-container {
    max-width: 100%;
    overflow-x: auto;
  }

  &__header {
    padding: 0 rem(12px);
    background: $gray-237;
    text-align: left;
    cursor: pointer;
    font-size: rem(16px);
    text-transform: uppercase;
    font-weight: 600;

    &[aria-sort="none"] {
      .index-table__arrow-up,
      .index-table__arrow-down {
        color: $gray-178;
      }
    }

    &[aria-sort="ascending"] {
      .index-table__arrow-up {
        color: $gray-56;
      }
    }
    &[aria-sort="descending"] {
      .index-table__arrow-down {
        color: $gray-56;
      }
    }

    &:hover {
      // color: $gray-178;
    }
  }

  &__header-inner {
    display: flex;
    align-items: center;
  }

  &__arrow {
    font-family: TeleIcon;
    cursor: pointer;
    height: rem(10px);
    line-height: rem(12px);
    color: $gray-184;
  }

  &__arrows-container {
    display: flex;
    flex-direction: column;
    margin-left: rem(8px);
    font-size: rem(14px);
  }

  &__cell {
    vertical-align: top;
    text-align: left;
    padding: 0 rem(12px);
    font-size: rem(15px);
    line-height: rem(24px);
  }

  &__tag {
    font-size: rem(22px);
    font-weight: bold;

    th {
      text-align: left;
    }
  }

  &__tags-form {
    display: flex;
    margin: rem(0 0 12px);
  }

  &__button-container {
    position: relative;

    & + & {
      margin-left: rem(12px);
    }
  }

  &__button {
    opacity: 0;
    position: absolute;
    border-color: #eeeeee;
  }

  &__button-label {
    cursor: pointer;
    font-family: $font-family-base;
    line-height: 30px;
    padding: 0 10px;
    border-radius: 4px;
    color: $gray-38;
    border: solid 1px #dcdcdc;
    background: transparent;

    &:hover {
      background: $gray-178;
    }

    .index-table__button:checked + & {
      background: $gray-178;
      border-color: #dcdcdc;
      left: 0;
    }
  }
}
