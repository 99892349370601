//-------------------------------------------------------------
// Tabbar: link
//-------------------------------------------------------------

// Variables
$tabbar-link-font-size: 24px;
$tabbar-link-line-height: 29px;

.tabbar-link {
    display:             flex;
    flex-flow:           column nowrap;
    align-items:         center;
    text-align:          center;
    text-decoration:     none;
    padding:             0 building-units(1);

    span {
      width: 100%;
      height: auto;
    }
}

span.tabbar-link-text {
    font-size:     $tabbar-link-font-size;
    line-height:   $tabbar-link-line-height;
    margin:        4px 0 6px 0;
}


span.tabbar-link-subline {
  font-size:     $font-size-default;
  line-height:   $line-height-default;
  margin-bottom: 10px;
}

@include media-breakpoint-down(md) {
  span.tabbar-link-icon {
    margin:        0 6px;
  }
}