//-------------------------------------------------------------
// Flyout
//-------------------------------------------------------------
// Variables
$flyout-link-hover: $purple;

.flyout {
  position: relative;
  z-index: 10;
  display: block;
  max-height: 0;

  &__headline {
    font-weight: $font-weight-bold;
    color: $black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    padding: 0;

    @include reset-hover();

    &:hover, &:active {
      color: $flyout-link-hover;
      background-color: transparent;
      cursor: pointer;

      .vacomicon {
        color: inherit;
      }
      .flyout__title {
        color: inherit;
      }

      .flyout__toggle-icon {
        color: $black;
      }
    }

    > .vacomicon {

      color: $black;
      bottom: -1px;

      margin-right: building-units(2);

      @include media-breakpoint-up(md) {

        margin-right: building-units(0.5);
      }
    }
  }

  &__title {
    @include pt-sans-regular-14();

    display: block;
    color: $black;
  }

  &__toggle {
    color: $gray-medium-2;
    flex: 0 16px;
    min-width: 16px;
    text-decoration: none;
    margin-left: building-units(0.5);
    align-content: center;

    &:hover {
      color: $black;
    }

    .icon-small-arrow-up {
      display: none;
    }

    .icon-arrow-down {
      display: inline-block;
    }

    .vacomicon {
      font-size: 6px;
      color: $black;
      bottom: 5px;
    }
  }

  &__content {
    display: none;
    background-color: $white;
  }

  &--open {
    > .flyout__headline {
      border-bottom: 1px solid $gray-medium-2;
    }

    .flyout__title, > .vacomicon {
      color: $black;
    }

    > .flyout__content {
      display: block;
      box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    }

    .flyout__toggle-icon {
      color: $black;

      &.icon-small-arrow-up {
        display: inline-block;
      }

      &.icon-arrow-down {
        display: none;
      }
    }
  }
}
