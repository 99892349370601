//-------------------------------------------------------------
// Reset Stylings
//-------------------------------------------------------------

%reset-ul {

  margin: 0;
  padding: 0;
  list-style-type: none;
}
