//-------------------------------------------------------------
// Teaser
//-------------------------------------------------------------

.teaser {
  position: relative;

  &__image {
    a,
    img {
      display: block;
    }
    img {
      width: 100%;
    }
  }

  &__header {
    margin-top: 0;

    a {
      color: $teaser-header-link-color;
    }
  }

  &__kicker {
    display: block;
    margin-bottom: rem(12px);

    @extend %text-small;
  }

  &__content {
    padding: rem(12px) rem(18px) rem(20px);

    @include media-breakpoint-up(md) {
      &--overlay {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &__text {
    color: $teaser-grey-text-color;
    position: relative;

    a {
      color: $teaser-header-link-color;
      font-weight: $font-weight-bold;
      position: relative;
      z-index: 2;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &__link-label {
    font-size: rem(15px);
    line-height: 1.25;
    font-weight: bold;
    color: $teaser-link-label-text-color;
    display: block;
    text-decoration: none;
    padding-top: rem(14px);
    margin: 0;

    &:hover {
      text-decoration: none;
    }

    .teleicon {
      font-size: rem(15px);
      padding-left: rem(4px);
    }
  }

  &--magenta {
    .teaser__content {
      color: $teaser-primary-text-color;
      background: $teaser-primary-background-color;

      &:hover {
        background: $teaser-primary-hover-background-color;
      }

      @include media-breakpoint-up(md) {
        &.teaser__content--overlay {
          background-color: rgba($teaser-primary-background-color, 0.7);

          &:hover {
            background: rgba($teaser-primary-hover-background-color, 0.95);
          }
        }
      }
    }
  }

  &--grey {
    .teaser__content {
      color: $teaser-rey-content-text-color;
      background: $teaser-grey-background-color;

      &:hover {
        background: $teaser-grey-hover-background-color;
      }

      @include media-breakpoint-up(md) {
        &.teaser__content--overlay {
          background-color: rgba($teaser-grey-background-color, 0.7);

          &:hover {
            background: rgba($teaser-grey-hover-background-color, 0.95);
          }
        }
      }
    }
  }

  &-magenta {
    background-color: $teaser-magenta-background-color;
  }
  &-grey {
    background-color: $teaser-grey-background-color;
  }
}

.teaser--grey {
  @include media-breakpoint-up("md") {
    //background-color: rgba($gray-56, 0.70);
  }
}

.teaser--magenta {
  @include media-breakpoint-up("md") {
    //position: absolute;
    //background-color: rgba($magenta, 0.70);
  }
}
