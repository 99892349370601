//-------------------------------------------------------------
// Navbar
//-------------------------------------------------------------

$navbar-bg-color: $gray-medium-1;
$navbar-br-color: $gray-light;

$navbar-link-font-size-lg: 18px;
$navbar-link-font-size-xl: 21px;
$navbar-link-color: $black;
$navbar-link-color-hover: $purple;
$navbar-link-bg-color-hover: $purple;
$navbar-link-color-active: $purple;
$navbar-link-bg-color-active: $purple;
$li-active-navbar-link-color: $purple;
$li-active-navbar-link-color-hover: $purple;

.navbar-vc {
    max-height: 60px;
    height: 100%;
    border-top: 1px solid $navbar-br-color;
    transition: height 0.3s, padding-bottom 0.3s;

    @include media-breakpoint-down(md) {
        max-height: 50px;
    }

    &__container {
        @extend %flex-container;
        position: relative;
        align-items: center;
        height: 50px;

        @include media-breakpoint-up(lg) {
            height: 60px;
        }
    }

    .vacomicon {
        color: $black;

        &.icon-search {
            z-index: 51;
        }

        &:hover {
            color: $navbar-link-color-active;
        }
    }
}

.main-navigation-vc {
    flex: auto;
    display: none;
    width: 100%;

    @include media-breakpoint-up(lg) {
        display: block;
        position: absolute;
    }

    &__list {
        @extend %horizontal-nav;
    }

    &__item {
        margin: 0 building-units(4) 0 0;
        padding: 0;

        &--active {
            border-bottom-color: $li-active-navbar-link-color;
        }

        &--active > a {
            color: $li-active-navbar-link-color;
        }

        &:last-child {
            border-bottom: none;
            justify-self: flex-end;

            @include media-breakpoint-up(md) {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    &__link {
        @include pt-sans-bold-18();
        position: relative;
        display: block;
        width: 100%;
        white-space: nowrap;
        text-transform: uppercase;
        color: $black;

        .vacomicon {
            color: $black;
        }

        &:active {
            color: $navbar-link-color-active;
        }

        &:hover {
            color: $navbar-link-color-active;

            .vacomicon {
                color: $navbar-link-color-active;
            }
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: none !important;
        }

        // Styles when list-item is active
        &--active {
            color: $li-active-navbar-link-color;

            &:hover,
            &:focus,
            &:active {
                color: $li-active-navbar-link-color;
            }
        }
    }

    //fix to open menu also within living styleguide typo3
    &--open-on-hover {
        .main-navigation__item:hover {
            .megamenu {
                display: block;
            }
        }
    }
}

.megamenu-vc {
    position: absolute;
    z-index: 10;
    top: 43px;
    width: 1600px;
    max-width: 1600px;
    left: 50%;
    margin-left: -905px;
    background: #fff;
    display: none;
    transition: height 0.3s, top 0.3s, padding-top 0.3s;

    @include media-breakpoint-only(lg) {
        width: 1400px;
        margin-left: -805px;
    }

    @include media-breakpoint-only(md) {
        width: 1024px;
        margin-left: -622px;
    }

    &--open {
        display: block;
        padding-top: building-units(5);
        box-shadow: 0 10px 25px rgba(0, 0, 0, .3);
    }

    &__top {
        display: flex;
        flex-flow: row;
        justify-content: space-between;

        /* Level 1 Links */
        > div {
            @include sans-bold-16();
            margin: 57px 0 building-units(2) 0;
        }
    }

    &__close-button {
        > .vacomicon {
            color: $black;
            margin-top: building-units(3);
        }
    }

    &__overview-link {
        color: $black;
        text-transform: uppercase;

        &:hover {
            color: $purple;
        }
    }

    &__menu-list {
        @extend %horizontal-nav;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
    }

    &__menu-item {
        display: block;
        position: relative;
        width: 20%;
        min-height: 1px; // Prevent collapsing
        padding: 0 building-units(3);
        margin-bottom: building-units(3);

        &:not(:last-child) {
            &:before {
                content: " ";
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                border-right: 1px solid $gray-light;
            }
        }

        &--no-left-padding {
            padding-left: 0;
        }

        &--no-border:before,
        &--no-border:after {
            border-right-color: transparent !important;
        }

        &:nth-child(5n+0):before {
            border-right-color: transparent !important;
        }
    }

    &__menu-item--columns-2 {
        width: 40%;

        .megamenu-vc__submenu {
            display: flex;
            flex-flow: row;
        }

        .megamenu-vc__submenu-list {
            width: 50%;

            &:nth-child(n+2) > li {
                padding-left: 10px;
            }
        }
    }

    &__menu-teaser {
        margin-left: auto;
    }

    /* Level 2 Links */
    &__menu-link {
        @include sans-bold-16();
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: building-units(1);

        &:hover {
            text-decoration: none !important;
        }
    }

    &__link-icon {
        margin-right: 6px;
    }

    &__image-box {
        margin-top: building-units(1);
    }

    &__item-image {
        width: 100%;
        max-width: 100%;
        border-radius: 8px;
    }

    &__submenu-list {
        @extend %navigation-list;
        padding-top: 5px;
    }

    &__submenu-item {
        margin: 0;
        padding: 0;
    }

    /* Level 3 Links */
    &__submenu-link {
        @include pt-sans-regular-14();
        margin-bottom: building-units(0.8);
        display: block;
    }

    &__submenu-more {
        padding-top: building-units(0.7);
    }

    &__quicklinks {
        margin: 0 building-units(3);

        &-headline {
            @include pt-sans-bold-14();
            color: $gray-medium-2;
            margin: 0 0 building-units(1) 0;
            padding: 0;
        }
    }

    &__quicklinks-list {
        @extend %horizontal-nav;
    }

    &__quicklinks-item {
        padding: 0;
        margin: 0 building-units(3) 0 0;
    }

    &__quicklinks-link {
        @extend %text-small;
        color: $black;

        &:hover {
            color: $purple;
        }
    }

    /************************************
     * TEASER
     ************************************/
    &__teaser {
        display: flex;
        flex-flow: row;

        &:not(:last-child) {
            margin-bottom: building-units(4);
        }
    }

    &__teaser-image {
        border-radius: 8px;
        margin-right: building-units(1);
        max-width: 180px;
        max-height: 180px;
        height: 180px;
        min-width: -moz-max-content; //1066 fix for Firefox
    }

    &__teaser-content {
        p {
            @extend %text-small;
        }
    }

    &__teaser:hover .megamenu-vc__teaser-content {
        //
        div, p {
            color: $blue;
        }
    }
}

.login-dropdown {
    &__role {
        font-size: 18px;
        margin-bottom: 24px;
    }
}

.navbar-vc-link-icon {
    @extend %navbar-link;
    padding: 0 building-units(1);
}

.navbar-search-vc {
    position: absolute;
    right: 0;
    bottom: 10px;
    background-color: $navbar-background-color;
    display: none;
    max-height: 40px;
    height: 100%;
    width: 100%;
    z-index: 102;

    @include media-breakpoint-down(sm) {
        bottom: 5px;
    }

    &--open {
        display: block;
    }

    &__form {
        margin: 0;
    }

    &__input {
        height: building-units(5);
        border: 1px solid #E6E6E6;
        border-radius: 8px;
        text-indent: building-units(1);

        &:focus {
            outline-style: none;
            box-shadow: none;
        }

        &.form-input {
            height: building-units(4) !important;
            width: calc(100% - 50px);

            @include media-breakpoint-up(md) {
                height: building-units(5);
            }
        }
    }

    &__input-container {
        position: relative;
        right: 30px;
        top: 6px;
    }

    &__input-submit {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 50;
        background: transparent;
        border: 0 none;

        &:focus {
            outline-style: none;
            box-shadow: none;
        }
    }

    &__search-button {
        cursor: pointer;
        right: 5px;
        top: 0;
        z-index: 50;
        background: transparent;
        border: 0 none;
    }


    &__close-button {
        position: absolute;
        right: -49px;
        top: 1px;
        align-self: center;
        margin-left: 0;
        color: $black;
        font-size: 15px;

        &:hover {
            color: $black;
        }

        .vacomicon {
            bottom: 1px;

            @include media-breakpoint-up(md) {
                bottom: 3px;
            }
        }
    }
}

.navbar-vc-mobile-menu {
    position: absolute;
    z-index: 999;
    top: 56px;
    width: 100%;
    overflow-y: auto;
    background-color: $white;
    padding: 0;

    @include media-breakpoint-down(md) {
        left: 0;
        top: 80px;
    }

    .navbar-vc__container {
        justify-content: flex-end;
    }

    &__navbar-title {
        font-weight: 600;
        color: $blue;
    }
}

body.mobile-menu--open {
    overflow: hidden;
}
