//-------------------------------------------------------------
// Input Label
//-------------------------------------------------------------

// Variables 
$label-padding:     building-units(.25);

// Label Styling
label {
    @extend %text-small;

    display:        inline-block;
    padding-bottom: $label-padding;
    color:          $label-color;

    .mandatory {
      color: $label-mandatory-color;
    }
}
