//-------------------------------------------------------------
// Table
//-------------------------------------------------------------

// Variables
$table-checkbox-size: building-units(2);
$table-controls-size: building-units(2);
$table-sorting-height: 18px;
$table-sorting-icon-font-size: 16px;

// Table container for scrolling horizontal
.table-container {
  padding-bottom: building-units(1);
  overflow-x: auto;
  overflow-y: hidden;
  display: block;

  @media all and (max-width: $screen-md) {
    margin: 0;
  }
}

// General table styles
.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  &.small {
    font-size: building-units(1.25);
  }

  > thead th,
  > tbody td,
  > tbody th {
    text-align: left;

    &.table-column-checkbox {
      padding: 11px 0 building-units(1);
      width: $table-checkbox-size;
      max-width: $table-checkbox-size;

      + td,
      + th {
        text-align: left;
      }

      .form-checkbox-set {
        margin: 0;

        .form-checkbox {
          + label {
            padding: 0;
          }
        }
      }
    }

    &.table-column-controls {
      padding: 11px 0 building-units(1);
      width: $table-controls-size;
      max-width: $table-controls-size;
    }
  }

  > thead {
    th {
      border-bottom: 1px solid $table-border-color;
      padding: building-units(1) building-units(1);
      vertical-align: bottom;
    }
  }

  > tbody {
    td,
    th {
      border-bottom: 1px solid $table-border-color;
      padding: 13px building-units(1) 11px;
      vertical-align: top;
    }
  }

  &--no-lines {
    > thead th,
    > tbody td,
    > tbody th {
      padding: building-units(0.5) building-units(1);
      border: none;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.table-filtering {
  margin-bottom: building-units(2);
  position: relative;

  .action-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
  }

  .form-input-set {
    margin: 0;
  }

  .link {
    line-height: building-units(3);

    @media all and (max-width: $screen-md) {
      margin-top: building-units(1);
      text-align: center;
      width: 100%;
    }
  }
}

.is-sortable {
  cursor: pointer;
  white-space: nowrap;

  .thead-entry {
    display: inline-block;
  }

  &.sorting-asc {
    .teleicon {
      &.icon-navigation-up {
        color: $table-sorting-active-icon-color;
        top: 0;
      }

      &.icon-navigation-down {
        display: none;
      }
    }
  }

  &.sorting-desc {
    .teleicon {
      &.icon-navigation-up {
        display: none;
      }

      &.icon-navigation-down {
        color: $table-sorting-active-icon-color;
        bottom: 0;
      }
    }
  }
}

.sorting {
  display: inline-block;
  padding-left: building-units(0.5);
  vertical-align: middle;
  height: $table-sorting-height;
  position: relative;

  .teleicon {
    font-size: $table-sorting-icon-font-size;
    position: absolute;

    &.icon-navigation-up {
      color: $table-sorting-icon-color;
      top: -6px;
    }

    &.icon-navigation-down {
      color: $table-sorting-icon-color;
      bottom: -2px;
    }
  }
}

//@import "table-accordion";
//@import "table-user";
//@import "table-small";
