//-------------------------------------------------------------
// Button oval filled
//-------------------------------------------------------------

$button-border-color-oval-filled: $purple;
$button-bg-color-oval-filled: $purple;
$button-icon-color-oval-filled: $white;

$button-text-color-oval-filled-hover: rgba($purple, .8);
$button-bg-color-oval-filled-hover: rgba($purple, .8);

$button-text-color-oval-filled-active: $white;
$button-bg-color-oval-filled-active: rgba($purple, .8);
$button-icon-color-oval-filled-active: $white;

$button-text-color-oval-filled-disabled: $gray-light;
$button-bg-color-oval-filled-disabled: $gray-light;
$button-icon-color-oval-filled-disabled: $gray-medium-2;

// dark theme
$button-border-color-dark-theme-oval-filled: $white;
$button-bg-color-dark-theme-oval-filled: $white;
$button-icon-color-dark-theme-oval-filled: $purple;

$button-text-color-dark-theme-oval-filled-hover: rgba($white, .8);
$button-bg-color-dark-theme-oval-filled-hover: rgba($white, .8);

$button-icon-color-dark-theme-oval-filled-active: $white;
$button-text-color-dark-theme-oval-filled-active: $purple;
$button-bg-color-dark-theme-oval-filled-active: rgba($white, .8);
$button-icon-color-dark-theme-oval-filled-active: $purple;

$button-text-color-dark-theme-oval-filled-disabled: $gray-light;
$button-bg-color-dark-theme-oval-filled-disabled: $gray-light;
$button-icon-color-dark-theme-oval-filled-disabled: $gray-medium-2;

.btn--oval-filled {

  border-radius: building-units(3);
  border: 1px solid $button-border-color-oval-filled;
  background-color: $button-bg-color-oval-filled;
  padding: 0 building-units(2);

  >.vacomicon {
    margin: 0 !important;
  }

  &.btn--primary .vacomicon {
    color: $button-icon-color-oval-filled;
  }

  &:focus-within,
  &:focus {
    border: 1px solid $button-text-color-oval-filled-hover;
    background-color: $button-bg-color-oval-filled-hover;
  }

  //separate because of IE
  &:hover {
    border: 1px solid $button-text-color-oval-filled-hover;
    background-color: $button-bg-color-oval-filled-hover;

    .vacomicon {
      color: $button-icon-color-oval-filled-active;
    }
  }

  &:active {
    color: $button-text-color-oval-filled-active;
    background-color: $button-bg-color-oval-filled-active;
    border-color: $button-bg-color-oval-filled-active;

    .vacomicon {
      color: $button-icon-color-oval-filled-active;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    border: 1px solid $button-text-color-oval-filled-disabled;
    background-color: $button-bg-color-oval-filled-disabled;

    &:hover,
    &:active,
    &:focus {
      cursor: not-allowed;
      border: 1px solid $button-text-color-oval-filled-disabled;
      background-color: $button-bg-color-oval-filled-disabled;
    }

    span.vacomicon {
      color: $button-icon-color-oval-filled-disabled;
    }
  }

  // dark theme
  &.btn--theme-dark {

    border: 1px solid $button-border-color-dark-theme-oval-filled;
    background-color: $button-bg-color-dark-theme-oval-filled;

    &.btn--primary .vacomicon {
      color: $button-icon-color-dark-theme-oval-filled;
    }

    &:focus-within,
    &:focus {
      border: 1px solid $button-text-color-dark-theme-oval-filled-hover;
      background-color: $button-bg-color-dark-theme-oval-filled-hover;
    }

    //separate because of IE
    &:hover {
      border: 1px solid $button-text-color-dark-theme-oval-filled-hover;
      background-color: $button-bg-color-dark-theme-oval-filled-hover;

      .vacomicon {
        color: $button-icon-color-dark-theme-oval-filled-active;
      }
    }

    &:active {
      color: $button-text-color-dark-theme-oval-filled-active;
      background-color: $button-bg-color-dark-theme-oval-filled-active;
      border-color: $button-bg-color-dark-theme-oval-filled-active;

      .vacomicon {
        color: $button-icon-color-dark-theme-oval-filled-active;
      }
    }

    &[disabled] {
      cursor: not-allowed;
      border: 1px solid $button-text-color-dark-theme-oval-filled-disabled;
      background-color: $button-bg-color-dark-theme-oval-filled-disabled;

      &:hover,
      &:active,
      &:focus {
        cursor: not-allowed;
        border: 1px solid $button-text-color-dark-theme-oval-filled-disabled;
        background-color: $button-bg-color-dark-theme-oval-filled-disabled;
      }

      span.vacomicon {
        color: $button-icon-color-dark-theme-oval-filled-disabled;
      }
    }
  }
}

//-------------------------------------------------------------
// Button oval
//-------------------------------------------------------------

$button-icon-color-oval: $purple;
$button-bg-color-oval: transparent;

$button-text-color-oval-hover: rgba($purple, .8);
$button-bg-color-oval-hover: transparent;

$button-text-color-oval-active: $white;
$button-bg-color-oval-active: $purple;
$button-icon-color-oval-active: $white;

$button-text-color-oval-disabled: $gray-medium-1;
$button-icon-color-oval-disabled: $gray-medium-2;
$button-bg-color-oval-disabled: transparent;

// dark theme
$button-icon-color-dark-theme-oval: $white;
$button-bg-color-dark-theme-oval: transparent;

$button-text-color-dark-theme-oval-hover: rgba($white, .8);
$button-bg-color-dark-theme-oval-hover: transparent;

$button-text-color-dark-theme-oval-active: $purple;
$button-bg-color-dark-theme-oval-active: $white;
$button-icon-color-dark-theme-oval-active: $purple;

$button-text-color-dark-theme-oval-disabled: $gray-medium-2;
$button-bg-color-dark-theme-oval-disabled: transparent;

.btn--oval {

  border-radius: building-units(3);
  border: 1px solid $button-icon-color-oval;
  background-color: $button-bg-color-oval;
  padding: 0 building-units(2);

  >.vacomicon {
    margin: 0 !important;
  }

  &.btn--primary .vacomicon {
    color: $button-icon-color-oval;
  }

  &:focus-within,
  &:focus {
    border-color: $button-text-color-oval-hover;
    background-color: $button-bg-color-oval-hover;
  }

  //separate because of IE
  &:hover {
    border-color: $button-text-color-oval-hover;
    background-color: $button-bg-color-oval-hover;

    .vacomicon {
      color: $button-text-color-oval-hover;
    }
  }

  &:active {
    color: $button-text-color-oval-active;
    background-color: $button-bg-color-oval-active;
    border-color: $button-bg-color-oval-active;

    .vacomicon {
      color: $button-icon-color-oval-active;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    border: 1px solid $button-text-color-oval-disabled;
    background-color: $button-bg-color-oval-disabled;

    &:hover,
    &:active,
    &:focus {
      cursor: not-allowed;
      border: 1px solid $button-text-color-oval-disabled;
      background-color: $button-bg-color-oval-disabled;
    }

    span.vacomicon {
      color: $button-icon-color-oval-disabled;
    }
  }

  // dark theme
  &.btn--theme-dark {

    border: 1px solid $button-icon-color-dark-theme-oval;
    background-color: $button-bg-color-dark-theme-oval;

    &.btn--primary .vacomicon {
      color: $button-icon-color-dark-theme-oval;
    }

    &:focus-within,
    &:focus {
      border-color: $button-text-color-dark-theme-oval-hover;
      background-color: $button-bg-color-dark-theme-oval-hover;
    }

    //separate because of IE
    &:hover {
      border-color: $button-text-color-dark-theme-oval-hover;
      background-color: $button-bg-color-dark-theme-oval-hover;

      .vacomicon {
        color: $button-text-color-dark-theme-oval-hover;
      }
    }

    &:active {
      color: $button-text-color-dark-theme-oval-active;
      background-color: $button-bg-color-dark-theme-oval-active;
      border-color: $button-bg-color-dark-theme-oval-active;

      .vacomicon {
        color: $button-icon-color-dark-theme-oval-active;
      }
    }

    &[disabled] {
      cursor: not-allowed;
      border: 1px solid $button-text-color-dark-theme-oval-disabled;
      background-color: $button-bg-color-dark-theme-oval-disabled;

      &:hover,
      &:active,
      &:focus {
        cursor: not-allowed;
        border: 1px solid $button-text-color-dark-theme-oval-disabled;
        background-color: $button-bg-color-dark-theme-oval-disabled;
      }

      span.vacomicon {
        color: $button-text-color-dark-theme-oval-disabled;
      }
    }
  }
}
