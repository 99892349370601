//-------------------------------------------------------------
// Body Copy Text
//-------------------------------------------------------------

// Mixins and Extend Classes

%text-default {
    color: $black;
    font-size: rem($font-size-default);
    line-height: $line-height-default;

    margin-bottom: building-units(2);

    @include media-breakpoint-up(md) {
      font-size:   rem($font-size-default-l);
      line-height: $line-height-default-l;
    }
}

%text-large {
    font-size:   rem($font-size-text-large);
    // font-weight: $font-weight-medium;
    line-height: $line-height-text-large;
}

%text-small {
    font-size:   $font-size-text-small;
    line-height: $line-height-text-small;
    font-weight: normal;

    margin-bottom: building-units(1.5);
}

%text-x-small {
    font-size:   rem($font-size-text-x-small);
    line-height: $line-height-text-x-small;
}

%text-center {
    text-align: center;
}

%text-right {
    text-align: right;
}

%text-bold {
    font-weight: $font-weight-bold;
}

%text-italic {
    font-style: italic;
}

// Styles

.text-default {
    @extend %text-default;
}

.text-large {
    @extend %text-large;
}

.text-small {
    @extend %text-small;
}

.text-x-small {
    @extend %text-x-small;
}

.text-center {
    @extend %text-center;
}

.text-right {
    @extend %text-right;
}

.text-bold {
    @extend %text-bold;
}

.text-italic {
    @extend %text-italic;
}


