//-------------------------------------------------------------
//blockquote
//-------------------------------------------------------------

// Variables
// Variables

// General
.quote {
    position: relative;
    width: 100%;

    &__image {
        height: building-units(21);
        margin-bottom: building-units(2);
        @include make-col(4, 12);
        display: table-cell;
        vertical-align: middle;

        @include media-breakpoint-only(md) {
            @include make-col(6, 12);
        }

        @include media-breakpoint-only(sm) {
            @include make-col(6, 12);
        }

        @include media-breakpoint-only(xs) {
            @include make-col(12, 12);
            display: block;
        }
    }

    &__img {
        display: inline-block;
        border-radius: 0;
        height: auto;
        width: auto;
        max-width: building-units(21);
        max-height: building-units(21);
        margin: 0 auto;
        box-shadow: building-units(1) building-units(1) 0 0 $purple;
    }

    &__content {
        position: relative;
        display: table;
        margin: 0 building-units(5);

        &:before {
            display: none;
        }

        p {
            @include sans-regular-18();
            min-height: building-units(21);
            //max-height: building-units(42);
            @include make-col(8, 12);
            margin-bottom: building-units(1);
            padding: 0 building-units(3);
            text-align: left;
            overflow: hidden;
            display: table-cell;
            vertical-align: middle;

            &:before {
                display: none;
            }

            .section_cf--bg-primary & {
                color: $white;
            }

            @include media-breakpoint-between(sm, xs) {
                @include pt-sans-regular-16();
            }

            @include media-breakpoint-only(md) {
                @include make-col(6, 12);
            }

            @include media-breakpoint-only(sm) {
                @include make-col(6, 12);
            }

            @include media-breakpoint-only(xs) {
                @include make-col(12, 12);
                display: block;
                text-align: center;
                padding: 0 building-units(1);
            }
        }
    }

    &__source {
        position: relative;
        width: 100%;
        @include pt-sans-regular-14();
        font-style: normal;
        display: block;
        margin: building-units(1) auto;
        text-align: left;
        color: $gray-dark;
    }

    a {
        color: $purple;
        text-align: center;
        margin-left: 5px;
        text-decoration: none;

        &.link--intern:after {
            content: "\4f";
            width: 25px;
            margin-left: 5px;
        }

        .section_cf--bg-primary & {
            color: $white;

            &:hover {
                color: $purple;
            }
        }

        .section_cf--bg-secondary & {
            color: $purple;

            &:hover {
                color: $purple;
            }
        }
    }
}

.card_cf--bg-secondary .quote:before {
    color: #EBEFF2;
}


.quote-testimonial {
    .quote__content {
        justify-content: center;
        align-items: center;
        display: block;
    }

    .quote__img {
        margin: 0 auto;
        display: block;
    }

    .quote__source {
        margin-bottom: building-units(1);

        @include media-breakpoint-only(xs) {
            text-align: center;
        }
    }

    a.link {
        text-decoration: underline;
        display: block;
    }
}

// Adjust the width of the swiper quote-container without offset-huge for lg
.swiper.swiper-testimonial {
    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: 40%;
        border-radius: building-units(5);
        width: building-units(5);
        height: building-units(5);
        margin-top: 0;
        cursor: pointer;
        border: 1px solid $purple;

        &:after {
            font-size: building-units(3);
            top: 1px;
            left: 13px;
            color: $purple;
        }

        &:focus {
            outline: none;
        }

        &:hover {

            &:after {

                color: rgba($purple, .8);
            }
        }
    }

    .quote__content {

        margin: 0;

        @include media-breakpoint-up(md) {

            margin: 0 50px;
        }
    }

    .quote__source {

        margin: 10px auto 0 auto;
    }

    .swiper-button-next {

        &:after {
            left: 17px;
        }
    }

    .quote-testimonial {
        .quote__content {
            @include media-breakpoint-up(lg) {
                margin: 0 building-units(5);
            }
        }
    }
}

// Adjust the width of the swiper quote-container at offset-huge for lg and md, sm
.section_cf--offset-huge .swiper-testimonial {
    .quote-testimonial {
        .quote__content {
            @include media-breakpoint-up(lg) {
                //max-width: 80%;
            }
            @include media-breakpoint-between(sm, md) {
                //max-width: 90%;
            }
        }
    }
}

/**
 * override styles of swiper for subcomponent
 */
.swiper:not(.stage-slider) {
    .swiper-button-next,
    .swiper-button-prev {
        &:after {
            font-size: 36px;
            text-shadow: none !important;
        }

        &:hover {
            background: transparent;
        }

        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }

        &.swiper-button-disabled {
            &:hover, &:hover:after {
                color: $gray-medium-2;
                cursor: not-allowed;
            }
        }
    }

    .swiper-button-next {
        right: building-units(-1);
    }

    .swiper-slide__content {
        position: inherit;
        max-height: inherit;
        border-color: transparent;
        left: 0;
        bottom: inherit;
        width: 100%;
        max-width: 100%;
        padding-top: 0;
        margin-top: 0;
    }

    .swiper-pagination-bullets {
        bottom: building-units(-1.5);

        @include media-breakpoint-up(md) {
            bottom: building-units(-1);
        }
    }

    .swiper-pagination-bullet {
        background-color: $white;
        border: 1px solid $gray-medium-2;
        width: building-units(1.5);
        height: building-units(1.5);

        margin-right: building-units(2);

        &:hover {
            border-color: $black;
        }

        &:focus {
            outline: none;
        }
    }

    .swiper-pagination-bullet-active {
        background-color: $purple;
        border-color: $purple;

        &:hover {
            border-color: $purple;
        }
    }

    .swiper-container {
        height: 100%;
        overflow-y: hidden;

        .swiper-wrapper {
            .swiper-slide {
                display: table;

                &__content {
                    display: table-cell;
                    @include media-breakpoint-up(md) {
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
