//-------------------------------------------------------------
// research_module
//-------------------------------------------------------------
// Variables

.research_module {

    &-search {
        padding: building-units(2) 0 0 0;
        margin-bottom: building-units(2);

        span.vacomicon.icon-filter {
            margin-top: 8px;
        }
    }

    .form-checkbox-set {
        padding: 0;
    }

    &-filter {
        .dropdown__item label {
            @include pt-sans-regular-16();

            @include media-breakpoint-up(lg) {
                @include pt-sans-regular-18();
            }

            color: $optionlist-color-primary;
            text-decoration: none;
            padding: 0 building-units(1.5);
            height: building-units(5);
            margin-bottom: 0;
            width: 100%;

            display: flex;
            align-items: center;
            word-break: break-all;

            @include reset-hover();

            &:focus,
            &:focus-within {
                background: $optionlist-bg-hover;
                color: $purple;
            }

            &:hover {
                background: $optionlist-bg-hover;
                color: $purple;
            }

            &:active {
                background: $optionlist-bg-hover;
                color: $purple;
            }

            &:after,
            &:before {
                opacity: 0;
                padding: 0;
                margin: 0;
                width: 0;
                height: 0;
            }
        }

        .dropdown__item label:before {
            font-size: 1rem;
            height: building-units(1);
            width: building-units(1);
            left: 10px;
            top: 9px;
        }
    }

    &-chips > .row > .col {
        flex-flow: row;
    }

    &-chips-container {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &-page {
        & > div {
            display: none;
        }

        &.table-container [data-filter] {
            display: table-row !important;

            &.research_module-teaser--hide {
                display: none !important;
            }
        }
    }

    &-results .row a p {
        color: $link-color !important;
    }

    &-results table tr {
        display: table-row !important;
    }

    &-teaser--hide {
        display: none !important;
    }

    &-teaser--show {
        display: flex !important;
    }

    &-teaser--hide {
        display: none !important;
    }

    &-results--show-more {
        .research_module-pagination {
            display: flex;
        }
    }
}

.research_module-search .input-addon {
    max-width: 105px;
    min-width: auto;
}

.research_module2 {
    &-page {
        .swiper {
            .swiper-slide__content > .research_module-teaser--show {
                height: calc(100% - 30px);
            }
        }
    }
}
