//-------------------------------------------------------------
// Breadcrumb
//-------------------------------------------------------------

// General

.breadcrumbs {
    margin: building-units(5) 0 0 0;

    @include media-breakpoint-down(sm) {
        margin: building-units(2) 0 0 0;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;

        &--ellipsis-open {
            .breadcrumbs__item--ellipsis {
                display: none;
            }

            .breadcrumbs__item--hidden {
                display: block;
            }
        }
    }

    &__item {
        @extend %text-small;
        padding-left: 0;

        &::after {
            @extend %vacomicon;

            color: $breadcrumb-link-color;
            content: '\e900';
            font-size: building-units(0.6);
            transform: rotate(90deg);
        }

        &--current {
            border-bottom: none;

            &::after {
                display: none;
            }
        }
    }

    &__link {
        color: $breadcrumb-link-color;

        &:hover {
            text-decoration: none;
        }

        margin: 0;
        text-decoration: none;
        font-weight: normal;

        &:hover {
            text-decoration: underline;
        }
    }

    /**
   * hide items, on click they will appear and the ellipsis will be hidden
   */
    &__item--hidden {
        display: none;
    }

    &__link {
        @extend %text-small;

        margin: 0;
        color: $breadcrumb-link-color;
        text-decoration: none;
        font-weight: $font-weight-normal;

        &:hover {
            color: $breadcrumb-link-color !important;
            text-decoration: underline;
        }

        &--current {
            @extend %text-small;

            border-bottom: none;
            font-weight: bold;
            color: $breadcrumb-current-link-color;

            &:hover {
                color: $breadcrumb-current-link-color;
                text-decoration: none;
            }
        }
    }
}

body.has-stage .breadcrumbs {
    margin-top: 20px;
}
